export const translations = {
  pageTitle: '',
  documentTypePage: {
    header:
      'Salve, stiamo per effettuare una registrazione per verificare la sua identità',
    description: 'Scegliere il documento con il quale ci si vuole identificare',
    proceed: 'Continua',
    selectCountry: 'Selezionare il paese che ha emesso il documento',
    chooseDocument: 'Scegliere il tipo di documento',
    ResidentPermit: 'Permesso di soggiorno',
    EUResidentPermit: 'Permesso di soggiorno UE',
    TravelTitle: 'Titolo del viaggio',
    DriverLicense: 'Patente di guida',
    Passport: 'Passaporto',
    IdentityCard: "Carta d'identità",
    inputPlaceholder: 'Selezionare',
  },
  generalConditionsPage: {
    header: 'Condizioni generali',
    termsAndConditions:
      'Accetto i termini e le condizioni di utilizzo del servizio*.',
    privacyPolicy: "Accetto l'informativa sulla privacy.*",
    required: '* condizioni richieste',
    proceed: 'Continua',
    conditionTermsHeading: "Termini e condizioni d'uso",
    conditionPrivacyHeading: 'Informativa sulla privacy',
  },
  signDocumentPage: {
    header: 'Firma il documento',
    checkboxLabel:
      'Dichiaro di aver letto e compreso i termini del presente accordo come sopra specificato.',
    proceed: 'Firma il documento',
  },
  videoOnboardingPage: {
    header: 'Di cosa avete bisogno?',
    wellLitPlace: 'Un luogo ben illuminato.',
    originalDocument: 'Il vostro documento originale, valido e ben visibile.',
    wifi: 'Connessione Wi-Fi o copertura 4G massima.',
    start: 'Continua',
  },
  documentType: {
    selectCountry: 'Selezionare il paese che ha emesso il documento',
    ResidentPermit: 'Permesso di soggiorno',
    EUResidentPermit: 'Permesso di soggiorno UE',
    TravelTitle: 'Titolo del viaggio',
    IdentityCard: "Carta d'identità",
  },
  otpPage: {
    verify: 'Verifica',
    header: 'Inserire il codice di verifica inviato al telefono',
    headerVerifying: 'Attendere la verifica',
  },
  userDataPage: {
    header: 'Fornire informazioni di contatto',
    proceed: 'Confermare',
    email: 'E-mail:',
    phone: 'Telefono:',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: "Verifica dell'identità",
    firstParagraph:
      "Benvenuti nel processo di verifica dell'identità di Signicat. Signicat, con la sua consociata ElectronicID, è un fornitore di servizi di fiducia certificato dall'UE [https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42] e si occuperà dell'identificazione e della firma elettronica per il vostro onboarding con",
    firstParagraphPartOne:
      "Benvenuti nel processo di verifica dell'identità di Signicat. Signicat, con la sua filiale ElectronicID, è una società di fiducia certificata dall'UE ",
    serviceProvider: 'fornitore di servizi',
    firstParagraphPartTwo:
      " ed effettuerà l'identificazione e la firma elettronica per il vostro onboarding.",
    secondParagraph:
      'Il processo di identificazione sarà eseguito in modo completamente digitale e senza la necessità di parlare con un agente. Per completare il processo, avremo bisogno di:',
    thirdParagraph:
      "Accesso all'online banking? Sì, esatto. Come ulteriore misura di sicurezza, siamo obbligati dall'autorità di regolamentazione ad avviare un bonifico di 0,01 EUR dal vostro conto bancario e a verificare se il nome sul conto corrisponde alla vostra identità.\\n",
    fourthParagraph:
      'Pronti a partire? Bene, accettate la nostra informativa sulla privacy per iniziare.',
    consent:
      "Acconsento all'utilizzo dei miei dati personali per l'identificazione con i servizi di Open Banking Verification di Signicat*.",
    required: '* condizioni richieste',
    proceed: 'Confermare',
    whatYouNeed: 'Di cosa avete bisogno?',
    wellLitPlace: 'Buona illuminazione',
    originalDocument: "Carta d'identità nazionale o passaporto",
    stableConnection: 'Connessioni internet stabili',
    onlineBanking: "Accesso all'online banking",
    cameraAccess: 'Accesso alla fotocamera del dispositivo',
  },
  progressPage: {
    heading: "Verifica dell'identità",
    wait: 'Ora stiamo verificando la vostra identità. Di solito ci vogliono meno di 3 minuti.',
    waitMore:
      'Grazie per la vostra pazienza. È quasi finito. Non chiudere il browser.',
  },
  jitPayWelcomePage: {
    heading: "Benvenuti nel Processo di Verifica dell'Identità Signicat",
    descriptionPartOne:
      "Signicat, con la sua consociata ElectronicID, è una società di fiducia certificata dall'UE ",
    serviceProvider: 'fornitore di servizi',
    descriptionPartTwo:
      " ed eseguirà l'identificazione e la firma elettronica per il vostro onboarding.",
    idData:
      "Acconsento all'utilizzo dei miei dati personali per l'identificazione con i servizi di Open Banking Verification di Signicat.",
    termsAndConditions:
      'Accetto i termini e le condizioni di utilizzo del servizio.',
    privacyPolicy: "Accetto l'informativa sulla privacy.",
    proceed: 'Confermare',
    stepOne: 'Firma',
    stepTwo: "Verifica dell'identità",
    stepThree: 'Trasferimento di 1 centesimo',
    stepThreeDesc: "Richiesto dall'ente regolatore",
  },
  jitPayOnboardingPage: {
    heading: 'Per completare la procedura, seguire i tre passaggi seguenti:',
    signingHeader: '1. Firma',
    signingContent: 'Esaminare e firmare il contratto.',
    idVerifyHeader: "2. Verifica dell'identità",
    idVerifyContent:
      'Il processo di identificazione avverrà in modo completamente digitale e senza la necessità di parlare con un agente.',
    micropaymentHeader: '3. Micropagamento',
    micropaymentContent:
      '0,01 EUR di bonifico dal vostro conto bancario personale.',
    proceed: 'Confermare',
  },
  jitPayVideoOnboardingPage: {
    header: 'Cosa vi serve Per il processo VideoID:',
    lightHeading: 'Buona illuminazione',
    lightContent: 'Assicuratevi di essere in un ambiente ben illuminato.',
    netHeading: 'Connessione internet stabile',
    netContent: 'Assicuratevi di disporre di una connessione Internet forte.',
    deviceHeading: 'Accesso al dispositivo di ripresa',
    deviceContent: "Autorizzare l'accesso al dispositivo con fotocamera.",
    oneCameraHeading: 'Utilizzare una sola telecamera',
    oneCameraContent:
      'Assicurarsi che al dispositivo desktop sia collegato un solo dispositivo di ripresa. Se si utilizza un computer portatile, la fotocamera integrata è quella corretta da utilizzare.',
    proceed: 'Continua',
  },
  micropaymentInfoJitpayPage: {
    header: 'Micropagamento dal vostro conto bancario personale',
    content:
      "Come ulteriore misura di sicurezza, siamo obbligati dall'autorità di regolamentazione ad avviare un bonifico di 0,01 EUR dal vostro conto bancario personale e a verificare se il nome del conto corrisponde alla vostra identità.",
    warning: 'Utilizzate il vostro conto corrente personale',
    proceed: 'Confermare',
    idData:
      "Acconsento all'utilizzo dei miei dati personali per l'identificazione con i servizi di Open Banking Verification di Signicat.",
  },
  micropaymentInfoIkanoPage: {
    content:
      "Come ulteriore misura di sicurezza, siamo obbligati dall'autorità di regolamentazione ad avviare un bonifico di 0,01 EUR dal vostro conto bancario personale e a verificare se il nome del conto corrisponde alla vostra identità.",
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    terms:
      'I accept the terms and conditions of the video-identification process and the issuance of short- term certificates',
  },
  ikanoWelcomePage: {
    heading: "Benvenuti nel Processo di Verifica dell'Identità Signicat",
    descriptionPartOne:
      "Signicat, con la sua consociata ElectronicID, è una società di fiducia certificata dall'UE ",
    serviceProvider: 'fornitore di servizi',
    descriptionPartTwo:
      " ed eseguirà l'identificazione e la firma elettronica per il vostro onboarding.",
    idData:
      "Acconsento all'utilizzo dei miei dati personali per l'identificazione con i servizi di Open Banking Verification di Signicat.",
    termsAndConditions:
      'Accetto i termini e le condizioni di utilizzo del servizio.',
    privacyPolicy: "Accetto l'informativa sulla privacy.",
    proceed: 'Confermare',
    stepOne: 'Firma',
    stepTwo: "Verifica dell'identità",
    stepThree: 'Trasferimento di 1 centesimo',
    stepThreeDesc: "Richiesto dall'ente regolatore",
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign a document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign a document',
  },
  introKontomatikPage: {
    header: "Verifica dell'indirizzo",
    intro:
      "Nella fase successiva, verificheremo il vostro indirizzo. Vi chiederemo di selezionare la vostra banca dall'elenco e di accedere al vostro conto personale.",
    accountInformation:
      'Verranno recuperate le seguenti informazioni sul conto:',
    names: 'Nome e cognome - del/i titolare/i del conto',
    address: 'Indirizzo - del/i titolare/i del conto',
    ownership: 'Proprietà del conto - proprietario, comproprietario, ecc.',
    verificationPurposes:
      'Dai dati sopra riportati utilizzeremo <b>solo il vostro indirizzo</b> a scopo di verifica.',
    proceed: 'Continua',
  },
  addressUploadPageKontomatik: {
    verifying: 'Verifica...',
    header: "Caricare l'indirizzo",
    description:
      "Non siamo riusciti a ricavare l'indirizzo dalle informazioni sul conto bancario.",
    confirmation:
      'Per confermare il vostro indirizzo vi chiederemo di caricare gli estratti conto bancari che contengono il vostro indirizzo.',
    bankStatementContains:
      "Assicuratevi che l'estratto conto bancario contenga il vostro indirizzo",
    yourAddress: ' il vostro indirizzo',
    proceed: 'Continua',
    dragAndDrop: 'Trascinare e rilasciare o ',
    chooseFile: 'scegliere il file',
    pko: {
      header: 'PKO BP Estratto conto bancario',
      confirmation:
        'Per confermare il vostro indirizzo vi chiederemo di caricare gli estratti conto bancari che contengono il vostro indirizzo.',
      whereToFindStatements:
        'Dove trovare gli estratti conto bancari in PKO BP?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
