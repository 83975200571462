import React from 'react'
import { Container, Wrap } from './Loading.styles'

interface LoadingProps {
  width: string
  bgColor: string
  primary: string
  padding?: string
  thickness?: number
  bgIframe?: string
  height?: string
}

export const Loading = (props: LoadingProps) => {
  return (
    <Wrap padding={props.padding} height={props.height}>
      <Container
        id="loading"
        width={props.width}
        bgColor={props.bgColor}
        primary={props.primary}
        thickness={props.thickness}
        bgIframe={props.bgIframe}
      ></Container>
    </Wrap>
  )
}
