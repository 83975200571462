import styled from 'styled-components'
import { tablet } from '../../constants/breakpoints'

export const Container = styled.div<{ open?: boolean; display: string }>`
  box-sizing: border-box;
  position: absolute;
  top: 70px;
  right: ${(props) => (props.open ? '0' : '-290px')};
  width: 290px;
  /* height: 100vh; */
  height: calc(100vh - 70px);
  display: ${(props) => props.display};
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(180deg, #eff1f5 0%, #fff 100%);
  border-right: 1px solid #dfe2ec;
  padding: 30px 24px 24px 24px;

  transition: right 0.3s ease-in-out;

  @media (min-width: calc(${tablet} + 1px)) {
    display: none;
  }
`

export const UlContainer = styled.ul`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  font-family: 'Inter';

  list-style-type: none;

  li {
    width: 100%;

    & > a {
      width: 100%;
      display: flex;
      height: 40px;
      justify-content: flex-start;
      text-align: left;
      color: #000;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 25.5px;
      text-decoration: none;

      &:hover,
      &:active,
      &:focus,
      &:visited,
      &:link {
        color: #000;
        text-decoration: none;
      }
    }
  }
`

export const LogoutButton = styled.button`
  border: none;
  outline: none;
  padding: 0;
  background: none;
  cursor: pointer;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 15px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;

  &:hover {
    color: #87a1df;
  }
`

export const UserWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  margin: 0 0 20px 10px;

  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 16px;
`

export const UserInitials = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f4f6f8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
`

export const UserName = styled.span`
  color: #f4f6f8;
  align-self: center;
`

export const BurgerButton = styled.button`
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  background: none;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (min-width: calc(${tablet} + 1px)) {
    display: none;
  }
`

export const FloatingMenu = styled.div`
  @media (min-width: calc(${tablet} + 1px)) {
    display: none;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  box-sizing: border-box;
  background-color: #ffffff;
`

export const MenuLogo = styled.div`
  color: #000;
  font-family: 'Yeseva One';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  span {
    color: #0e83ef;
    font-family: 'Yeseva One';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`
