export const translations = {
  pageTitle: '',
  documentTypePage: {
    header: 'Wähle Dein Ausweisdokument',
    selectCountry: 'Wählen Sie Ihr Ausweisdokument',
    proceed: 'Weiter',
    chooseDocument: 'Wähle Dein Ausweisdokument',
    ResidentPermit: 'Aufenthaltserlaubnis',
    EUResidentPermit: 'EU-Aufenthaltsgenehmigung',
    TravelTitle: 'Reisetitel',
    DriverLicense: 'Driving license',
    Passport: 'Reisepass',
    IdentityCard: 'Personalausweis',
    inputPlaceholder: 'Bitte ausw\u00e4hlen',
  },
  generalConditionsPage: {
    header: 'Allgemeine Gesch\u00e4ftsbedingungen',
    privacyPolicy:
      'Ich habe die Datenschutzbestimmungen gelesen und stimme der Verarbeitung und Speicherung meiner biometrischen Daten und der vollständigen Aufzeichnung des Videos zu. Andernfalls haben Sie andere Alternativen, die in den Allgemeinen Geschäftsbedingungen aufgeführt sind.',
    termsAndConditions:
      'Ich akzeptiere die Allgemeinen Geschäftsbedingungen für das Video-Identifizierungsverfahren und die Ausstellung von Kurzzeitzertifikaten.',
    required: 'erforderliche Bedingungen',
    proceed: 'Akzeptieren',
    conditionTermsHeading: 'Allgemeine Gesch\u00e4ftsbedingungen',
    conditionPrivacyHeading: 'Datenschutzbestimmungen',
  },
  otpPage: {
    header: 'Den Verifizierungscode eingeben, der an Ihr Telefon gesendet wird',
    headerVerifying: 'Bitte warten Sie auf Ihre Best\u00e4tigung.',
    verify: '\u00dcberpr\u00fcfen',
  },
  signDocumentPage: {
    header: 'Dokument unterzeichnen',
    checkboxLabel:
      'Ich best\u00e4tige, dass ich die Bestimmungen dieser Vereinbarung, wie oben beschrieben, gelesen und verstanden habe.',
    proceed: 'Dokument unterzeichnen',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: 'Datenschutz-Bestimmungen',
    firstParagraph:
      'Willkommen zum Signicat-Identitätsprüfungsprozess. Signicat, mit unserer Tochtergesellschaft ElectronicID, ist ein von der EU zertifizierter Vertrauensdienstleister und wird Ihre Identifizierung und elektronische Unterschrift für Ihr Onboarding durchführen.',
    firstParagraphPartOne: `Willkommen zum Signicat-Identitätsprüfungsprozess. Signicat, mit unserer Tochtergesellschaft ElectronicID, ist ein von der EU zertifizierter `,
    serviceProvider: 'Vertrauensdienstleister',
    firstParagraphPartTwo:
      ' und wird Ihre Identifizierung und elektronische Unterschrift für Ihr Onboarding mit durchführen',
    secondParagraph:
      'Ihr Identifizierungsprozess wird vollständig digital und ohne die Notwendigkeit, mit einem Vertreter zu sprechen, durchgeführt. \n' +
      'Um den Prozess abzuschließen, benötigen wir:',
    thirdParagraph:
      'Zugang zu Ihrem Online-Banking? Ja, richtig. Als zusätzliche Sicherheitsmaßnahme sind wir von der Aufsichtsbehörde verpflichtet, eine Überweisung von 0,01 EUR von Ihrem Bankkonto zu veranlassen und zu prüfen, ob der Name auf dem Konto mit Ihrer Identität übereinstimmt.',
    fourthParagraph:
      'Sind Sie bereit? Gut, dann akzeptieren Sie bitte unsere Datenschutzbestimmungen, damit wir beginnen können.',
    consent:
      'Ich bin damit einverstanden, dass meine personenbezogenen Daten zur Identifizierung bei den Signicat Open Banking Verification-Diensten verwendet werden.',
    proceed: 'Best\u00e4tigen',
    required: 'erforderliche Bedingung',
    whatYouNeed: 'Was ben\u00f6tigen Sie?',
    wellLitPlace: 'Gute Beleuchtung',
    originalDocument: 'Ihren Personalausweis oder Reisepass',
    stableConnection: 'Stabile Internetverbindungen',
    onlineBanking: 'Zugang zu Ihrem Onlinebanking',
    cameraAccess: 'Zugang zur Kamera Ihres Geräts',
  },
  userDataPage: {
    header: 'Bitte geben Sie Ihre Kontaktinformationen an',
    email: 'E-mail:',
    phone: 'Telefon:',
    proceed: 'Best\u00e4tigen',
  },
  videoOnboardingPage: {
    header: 'Was ben\u00f6tigen Sie?',
    wellLitPlace: 'Ein gut beleuchteter Ort.',
    originalDocument: 'Ihr Originaldokument, g\u00fcltig und ohne Einband.',
    wifi: 'Wi-Fi-Verbindung oder maximaler 4G-Empfang',
    start: 'Weiter',
  },
  documentType: {
    selectCountry: 'Wählen Sie das Land, das das Dokument ausgestellt hat',
    ResidentPermit: 'Aufenthaltserlaubnis',
    EUResidentPermit: 'EU-Aufenthaltsgenehmigung',
    IdentityCard: 'Personalausweis',
  },
  progressPage: {
    heading: 'Identitätsüberprüfung',
    wait: 'Wir überprüfen jetzt Ihre Identität. Normalerweise dauert dies weniger als 3 Minuten.',
    waitMore: 'Vielen Dank für Ihre Geduld, wir ist fast fertig.',
  },
  jitPayWelcomePage: {
    heading: 'Willkommen beim Signicat Identitätsüberprüfungsprozess',
    descriptionPartOne:
      'Signicat ist mit seiner Tochtergesellschaft ElectronicID ein EU-zertifizierter vertrauenswürdiger ',
    serviceProvider: 'Dienstanbieter',
    descriptionPartTwo:
      ' und wird Ihre Identifikation und die elektronische Unterschrift für Ihr Onboarding mit durchführen.',
    idData:
      'Ich bin damit einverstanden, dass meine persönlichen Daten zur Identifizierung meiner Person mit den Signicat Open Banking Verification Services verwendet werden.',
    privacyPolicy:
      'Ich habe die Datenschutzbestimmungen gelesen und stimme der Verarbeitung und Speicherung meiner biometrischen Daten und der vollständigen Aufzeichnung des Videos zu. Andernfalls haben Sie andere Alternativen, die in den Allgemeinen Geschäftsbedingungen aufgeführt sind.',
    termsAndConditions:
      'Ich akzeptiere die Allgemeinen Geschäftsbedingungen für das Video-Identifizierungsverfahren und die Ausstellung von Kurzzeitzertifikaten.',
    proceed: 'Bestätigen',
    stepOne: 'Unterzeichnung',
    stepTwo: 'Identitätsüberprüfung',
    stepThree: '1 Cent Überweisung',
    stepThreeDesc: 'Von der Regulierungsbehörde gefordert',
  },
  jitPayOnboardingPage: {
    heading:
      'Führen Sie die folgenden drei Schritte aus, um den Vorgang abzuschließen:',
    signingHeader: '1. Unterzeichnung',
    signingContent: 'Überprüfung und Unterzeichnung des Vertrags.',
    idVerifyHeader: '2. Identitätsüberprüfung',
    idVerifyContent:
      'Ihr Identifizierungsprozess erfolgt vollständig digital und ohne die Notwendigkeit, mit einem Agenten zu sprechen.',
    micropaymentHeader: '3. Mikrozahlung',
    micropaymentContent:
      '0,01 EUR Überweisung von Ihrem persönlichen Bankkonto.',
    proceed: 'Bestätigen',
  },
  jitPayVideoOnboardingPage: {
    header: 'Was Sie für den VideoID-Prozess benötigen:',
    lightHeading: 'Gute Beleuchtung',
    lightContent:
      'Achten Sie darauf, dass Sie sich in einer gut beleuchteten Umgebung befinden.',
    netHeading: 'Stabile Internetverbindung',
    netContent:
      'Stellen Sie sicher, dass Sie eine gute Internetverbindung haben.',
    deviceHeading: 'Zugriff auf Ihr Kameragerät',
    deviceContent: 'Autorisieren Sie den Zugriff auf Ihr Kameragerät.',
    oneCameraHeading: 'Nur eine Kamera verwenden',
    oneCameraContent:
      'Bitte stellen Sie sicher, dass nur ein Kameragerät an Ihr Desktop-Gerät angeschlossen ist. Wenn Sie einen Laptop verwenden, ist die eingebaute Kamera die richtige Kamera für Sie.',
    proceed: 'Weiter',
  },
  micropaymentInfoJitpayPage: {
    header: 'Micropayment von Ihrem persönlichen Bankkonto',
    content:
      'Als zusätzliche Sicherheitsmaßnahme sind wir von der Aufsichtsbehörde verpflichtet, eine Überweisung von 0,01 EUR von Ihrem persönlichen Bankkonto zu veranlassen und zu prüfen, ob der Name auf dem Konto mit Ihrer Identität übereinstimmt.',
    warning: 'Bitte verwenden Sie Ihr persönliches Bankkonto',
    proceed: 'Bestätigen',
    idData:
      'Ich bin damit einverstanden, dass meine persönlichen Daten zur Identifizierung meiner Person mit den Signicat Open Banking Verification Services verwendet werden.',
  },
  micropaymentInfoIkanoPage: {
    content: `Wir gleichen im nächsten Schritt Deinen Namen auf Deinem Konto mit Deiner Identität ab.
    Die Aufsichtsbehörde der Banken verpflichtet uns dies mittels einer Transaktion über 0,01 EUR von Deinem Konto zu initiieren.`,
    tink: 'Signicat wird diese Transaktion für die Ikano Bank mit seinem lizenzierten Partner – Tink GmbH – durchführen.',
    proceed: 'Bestätigen',
  },
  ikanoPolicies: {
    privacy: 'Ich akzeptiere die Datenschutzbestimmungen.',
    terms:
      'Ich akzeptiere die Allgemeinen Geschäftsbedingungen für die Nutzung des Angebots.',
  },
  ikanoWelcomePage: {
    heading: 'Välkommen beim Online Ident der Ikano Bank.',
    descriptionPartOne:
      'Signicat wird für die Ikano Bank Deine Identifizierung und elektronische Unterschrift für Deinen Antrag durchführen. Signicat, mit ihrer Tochtergesellschaft ElectronicID, ist ein von der EU zertifizierter ',
    serviceProvider: 'Trusted Service Provider.',
    descriptionPartTwo:
      ' und wird Ihre Identifikation und die elektronische Unterschrift für Ihr Onboarding mit durchführen.',
    idData:
      'Ich bin damit einverstanden, dass meine persönlichen Daten zur Identifizierung meiner Person mit den Signicat Open Banking Verification Services verwendet werden.',
    privacyPolicy: 'Ich akzeptiere die Datenschutzbestimmungen.',
    termsAndConditions:
      'Ich akzeptiere die Allgemeinen Geschäftsbedingungen für die Nutzung des Angebots.',
    proceed: 'Akzeptieren',
    stepOne: 'Unterzeichnung',
    stepTwo: 'Identitätsüberprüfung',
    stepThree: '1 Cent Überweisung',
    stepThreeDesc: 'Von der Regulierungsbehörde gefordert',
  },
  ikanoCompletePage: {
    submit: 'Weiter',
    message: 'Die Überweisung war erfolgreich.',
    message1_1: 'Klicke auf "Weiter" und schließe',
    message1_2: 'nicht',
    message1_3: 'das nächste Fenster.',
    message2: 'Wir machen eine finale Prüfung Deiner Angaben.',
    message3: 'Das kann mehrere Minuten dauern.',
  },
  signDocumentIkanoPage: {
    header: 'Vertragsunterlagen unterschreiben',
    checkboxLabel:
      'Ich bestätige, dass ich die Bestimmungen dieser Vereinbarung, wie oben beschrieben, gelesen und verstanden habe.',
    proceed: 'Unterschreiben',
  },
  introKontomatikPage: {
    header: 'Überprüfung der Adresse',
    intro:
      'Im nächsten Schritt verifizieren wir Ihre Adresse. Wir werden Sie bitten, Ihre Bank aus der Liste auszuwählen und sich in Ihr persönliches Konto einzuloggen.',
    accountInformation: 'Wir werden die folgenden Kontoinformationen abrufen:',
    names: 'Vor- und Nachname - des/der Kontoinhaber(s)',
    address: 'Anschrift - des/der Kontoinhaber(s)',
    ownership: 'Kontoinhaberschaft - Eigentümer, Miteigentümer, etc.',
    verificationPurposes:
      'Von den oben genannten Daten werden wir <b>nur Ihre Adresse</b> zu Überprüfungszwecken verwenden.',
    proceed: 'Weiter',
  },
  addressUploadPageKontomatik: {
    verifying: 'Überprüfen...',
    header: 'Adresse hochladen',
    description: 'Wir konnten die Adresse nicht aus Ihren Kontodaten abrufen.',
    confirmation:
      'Um Ihre Adresse zu bestätigen, bitten wir Sie, die Kontoauszüge hochzuladen, die Ihre Adresse enthalten.',
    bankStatementContains:
      'Vergewissern Sie sich, dass Ihr Kontoauszug Ihre Adresse enthält.',
    yourAddress: ' Ihre Adresse',
    proceed: 'Weiter',
    dragAndDrop: 'Ziehen und Ablegen oder ',
    chooseFile: 'Datei auswählen',
    pko: {
      header: 'PKO BP Kontoauszug',
      confirmation:
        'Um Ihre Adresse zu bestätigen, bitten wir Sie, die Kontoauszüge hochzuladen, die Ihre Adresse enthalten.',
      whereToFindStatements: 'Wo finde ich Kontoauszüge in der PKO BP?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Überprüfung läuft...',
    header: 'Adressnachweis',
    description:
      'Lade bitte eines der folgenden Dokumente als Adressnachweis hoch:',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Weiter',
    dragAndDrop: 'Bitte die Datei hierhin ziehen oder ',
    dropHere: 'Hier abstellen',
    chooseFile: 'Datei auswählen',
    filetypes: ' (gültige Dateiformate: PDF, JPG oder PNG)',
    maxFilesize: 'Max. Dateigröße: ',
    maxFilesizeValue: '5MB',
    fileErrorSize:
      'Die Datei ist zu groß. Bitte lade nur Dateien bis zu 5 MB hoch.',
    fileErrorType: 'Bitte lade ein Dokument im Format PDF, JPG oder PNG hoch.',
    complete: 'Adressnachweis erfolgreich hochgeladen',
  },
  addressVerificationIkano: {
    header: 'Adressnachweis',
    description:
      'Lade bitte eines der folgenden Dokumente als Adressnachweis hoch:',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Weiter',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Adressnachweis',
    descriptionPartOne:
      'Um Deine Adresse zu überprüfen, wird ein Foto von Deinem unbegrenzten Aufenthaltstitel bzw. Deiner Daueraufenthaltskarte benötigt.',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, wenn auf der Rückseite der Karte der Ausdruck "Daueraufenthalt" angegeben ist',
      'Alle anderen Aufenthaltstitel, die einen der Ausdrücke "Daueraufenthalt" oder "unbefristet" enthalten',
    ],
    descriptionPartTwo:
      'Erfasse zunächst die Vorderseite und anschließend die Rückseite und bestätige, dass die Aufnahme gut lesbar ist.',
    proceed: 'Weiter',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Überprüfung läuft...',
    header: 'Vorderseite des Dokuments',
    description: 'Mach bitte ein Foto von der Vorderseite Deines Dokuments.',
    descriptionConfirm: 'Ist die Vorderseite gut lesbar?',
    takePhoto: 'Foto machen',
    retakePhoto: 'Wiederholen',
    proceed: 'Bestätigen',
    complete: 'Adressprüfung abgeschlossen',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Überprüfung läuft...',
    header: 'Rückseite des Dokuments',
    description: 'Mach bitte ein Foto von der Rückseite Deines Dokuments.',
    descriptionConfirm: 'Ist die Rückseite gut lesbar?',
    takePhoto: 'Foto machen',
    retakePhoto: 'Wiederholen',
    proceed: 'Bestätigen',
    complete: 'Adressprüfung abgeschlossen',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
