import styled from 'styled-components'
import BackgroundLeft from '../../assets/login_background_left.jpg'
import BackgroundRight from '../../assets/login_background_right.png'

const mobile = 540
const tablet = 1024

const heightBreakpoint = 800

export const Page = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
`

export const FullScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;

  /* @media (min-width: ${mobile + 1}px) {
    min-height: 900px;
  } */
`

export const LeftColumn = styled.div`
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-end;
  background-image: url('${BackgroundLeft}');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px;

  @media (max-width: ${tablet}px) {
    display: none;
  }
`

export const RightColumn = styled.div`
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  background-image: url('${BackgroundRight}');
  background-repeat: repeat;

  @media (max-width: ${tablet}px) {
    width: 100%;
  }
`

export const Logo = styled.div`
  width: 96px;
  height: 96px;
  position: absolute;
  top: 40px;
  left: 40px;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 784px) {
    display: none;
  }
`
export const TextWrapper = styled.div``

export const Title = styled.h1`
  color: #fff;
  font-family: Inter;
  font-size: 60px;
  font-weight: 400;
  line-height: 125%;
  margin: 10px 0;
`

export const SubTitle = styled.h2`
  color: #fff;
  font-family: Inter;
  font-size: 25px;
  font-weight: 500;
  line-height: 150%;
  margin: 10px 0;
`

export const Slogan = styled.img`
  height: 45px;
  margin-top: 20px;
`

export const Form = styled.form`
  box-sizing: border-box;
  box-shadow: 0px 0px 9.5px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 430px;
  background-color: #fff;
  display: flex;
  padding: 60px 40px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-block-end: 0;

  @media (max-width: ${mobile}px) {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    gap: 25px;
    padding: 40px 20px;
  }

  @media (min-width: ${mobile + 1}px) and (max-height: ${heightBreakpoint}px) {
    padding: 36px 36px;
    gap: 25px;
  }
`

export const FormTitle = styled.h3`
  color: #3e4043;
  text-align: center;
  font-family: Inter;
  font-size: 22px;
  font-weight: 400;
  line-height: 25px;
  margin: 0;
`

export const InputWrapper = styled.div`
  width: 100%;
`

export const InputLabel = styled.label`
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  text-align: left;
`

export const Input = styled.input`
  border-radius: 5px;
  border: 1px solid #8e9092;
  background: #f9fbff;
  height: 50px;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const LoginButton = styled.button<{
  bgColor?: string
}>`
  /* button css reset */
  border: none;
  outline: none;
  padding: 0;
  background: none;
  cursor: pointer;
  /* button css reset */

  border-radius: 4px;
  background: ${(props) => (props.bgColor ? props.bgColor : '#0071d7')};
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 170%;
`

export const ForgotLink = styled.a`
  color: #0071d7;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400 !important;
  text-align: left;
  width: 100%;
  height: 20px;
`

export const SeparatorLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #c6d4e1;
`
