import styled from 'styled-components'
import { tablet } from '../../constants/breakpoints'

export const Page = styled.div`
  width: 100%;
  //max-width: 1440px;
  margin: 72px auto 0 auto;

  /* @media (min-width: calc(${tablet} + 1px)) {
    min-height: calc(100vh - 72px);
  } */

  @media (min-width: calc(${tablet} + 1px)) {
    background-color: #ccd0dd;
  }
`

export const HeadingBannerMobile = styled.div`
  background: #131332;
  width: 100%;
  padding: 30px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  @media (min-width: calc(${tablet} + 1px)) {
    display: none;
  }

  & > h1 {
    color: var(--white, #fff);
    font-family: 'Yeseva One';
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
    margin: 0;

    /* @media (min-width: calc(${tablet} + 1px)) {
      margin-bottom: 10px;
    } */

    & > span {
      color: var(--white, #0e83ef);
      font-family: 'Yeseva One';
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
    }
  }

  & > div {
    color: var(--white, #fff);
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.5px; /* 170% */
    opacity: 0.64;
  }

  & > button {
    cursor: pointer;
    height: 55px;
    width: 100%;
    max-width: 350px;
    color: #131332;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 285.714% */
    letter-spacing: 0.91px;
    background: #0e83ef;
    border: none;
  }
`

export const ImageBannerMobile = styled.div`
  background: #ccd0dd;
  width: 100%;
  max-width: 100vw;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  gap: 20px;

  @media (min-width: calc(${tablet} + 1px)) {
    display: none;
  }

  h3 {
    color: #000;
    text-align: center;
    font-family: 'Yeseva One';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.5px; /* 170% */
    margin: 0;
  }

  & > img {
    width: 100%;
    height: 328px;
    object-fit: cover;
  }
`

export const HeadingBannerDesktop = styled.div<{ url: string }>`
  @media (max-width: calc(${tablet})) {
    display: none;
  }

  width: 100%;
  height: 585px;
  padding: 66px 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: linear-gradient(
      to right,
      rgba(19, 19, 50, 1) calc(100% - 874px),
      rgba(19, 19, 50, 0.5) calc(60%),
      rgba(0, 0, 0, 0) 85%
    ),
    url(${(props) => props.url});
  background-position: center, right;
  background-size: cover, contain;
  background-repeat: no-repeat;
  gap: 40px;

  @media (min-width: 1920px) {
    background-image: linear-gradient(
        to right,
        rgba(19, 19, 50, 1) calc(100% - 874px),
        rgba(19, 19, 50, 0.5) calc(70%),
        rgba(0, 0, 0, 0) 85%
      ),
      url(${(props) => props.url});
  }

  & > h1 {
    color: #fff;
    font-family: 'Yeseva One';
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 80px;
    margin: 0;

    & > span {
      color: #0e83ef;
      font-family: 'Yeseva One';
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: 80px;
    }
  }

  & > div {
    color: rgba(255, 255, 255, 0.64);
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & > button {
    cursor: pointer;
    height: 55px;
    width: 100%;
    max-width: 350px;
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 1.17px;
    background: #0e83ef;
    border: none;

    &:hover {
      filter: brightness(1.2);
    }
  }
`

export const DesktopBodyWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: calc(${tablet})) {
    display: none;
  }
`

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: 50px 0;
  overflow-x: hidden;

  h2 {
    margin: 0;
    color: #000;
    text-align: center;
    font-family: Yeseva One;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.5px;
  }

  @media (max-width: calc(${tablet})) {
    display: none;
  }
`

export const CardsWrapper = styled.div`
  width: calc(100% + 140px);
  display: flex;
  justify-content: space-between;

  gap: 20px;

  padding: 0 140px;
`

export const Card = styled.div`
  background-color: #131332;
  max-width: 382px;
  width: 100%;
  aspect-ratio: 1 / 1;

  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: 62px;
    color: #fff;
    text-align: center;
    font-family: 'Yeseva One';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.5px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
