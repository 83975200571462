export const translations = {
  pageTitle: 'pageTitle',
  documentTypePage: {
    header:
      'Witaj, zamierzamy rozpocząć nagrywanie, aby zweryfikować Twoją tożsamość',
    description:
      'Wybierz dokument, za pomocą którego będziesz się identyfikować',
    proceed: 'Kontynuuj',
    selectCountry: 'Wybierz kraj, w którym wydano dokument',
    chooseDocument: 'Wybierz typ dokumentu',
    ResidentPermit: 'Pozwolenie na pobyt stały',
    EUResidentPermit: 'Pozwolenie na pobyt stały w UE',
    TravelTitle: 'Dokument podróży',
    DriverLicense: 'Prawo jazdy',
    Passport: 'Paszport',
    IdentityCard: 'Dowód osobisty',
    inputPlaceholder: 'Wybierz',
  },
  generalConditionsPage: {
    header: 'Ogólne warunki',
    termsAndConditions: 'Akceptuję zasady i warunki korzystania z usługi.*',
    privacyPolicy: 'Akceptuję politykę prywatności.*',
    required: '* wymagane warunki',
    proceed: 'Akceptuj',
    conditionTermsHeading: 'Zasady i warunki korzystania z usługi',
    conditionPrivacyHeading: 'Polityka prywatności',
  },
  signDocumentPage: {
    header: 'Podpisz dokument',
    checkboxLabel:
      'Potwierdzam, że przeczytałem i zrozumiałem warunki niniejszej umowy, jak wskazano powyżej.',
    proceed: 'Podpisz dokument',
  },
  videoOnboardingPage: {
    header: 'Co jest potrzebne?',
    wellLitPlace: 'Dobrze oświetlone miejsce.',
    originalDocument: 'Oryginalny i ważny dokument bez żadnych okładek.',
    wifi: 'Połączenie Wi-Fi lub maksymalny sygnał 4G.',
    start: 'Kontynuuj',
  },
  documentType: {
    selectCountry: 'Wybierz kraj, w którym wydano dokument',
    ResidentPermit: 'Pozwolenie na pobyt stały',
    EUResidentPermit: 'Pozwolenie na pobyt stały w UE',
    IdentityCard: 'Dowód osobisty',
  },
  otpPage: {
    verify: 'Sprawdź',
    header: 'Wprowadź kod weryfikacyjny wysłany na Twój telefon',
    headerVerifying: 'Prosimy o oczekiwanie na weryfikację.',
  },
  userDataPage: {
    header: 'Prosimy o podanie danych kontaktowych',
    proceed: 'Potwierdź',
    email: 'E-mail:',
    phone: 'Telefon:',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: 'Weryfikacja tożsamości',
    firstParagraph:
      'Witamy w procesie weryfikacji użytkownika Signicat. Signicat, wraz ze spółką zależną ElectronicID, jest zaufanym dostawcą usług certyfikowanym przez UE [https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42] i będzie przeprowadzać proces identyfikacji i składania podpisu elektronicznego w celu rejestracji użytkownika w',
    firstParagraphPartOne:
      'Witamy w procesie weryfikacji użytkownika Signicat. Signicat, wraz ze spółką zależną ElectronicID, jest zaufanym dostawcą usług certyfikowanym przez UE ',
    serviceProvider: 'dostawcą usług',
    firstParagraphPartTwo:
      ' i będzie przeprowadzać proces identyfikacji i składania podpisu elektronicznego w celu rejestracji użytkownika.',
    secondParagraph:
      'Proces identyfikacji zostanie przeprowadzony całkowicie elektronicznie i bez konieczności rozmowy z konsultantem. Aby zakończyć proces, będziemy potrzebowali:',
    thirdParagraph:
      'Dostęp do bankowości internetowej? Tak, zgadza się. Jako dodatkowy środek bezpieczeństwa jesteśmy zobligowani przez organ nadzoru do wykonania przelewu w wysokości 0,01 EUR z Twojego konta bankowego i sprawdzenia, czy dane na koncie są zgodne z Twoją tożsamością.',
    fourthParagraph:
      'Gotowy do rozpoczęcia? Świetnie, zaakceptuj naszą politykę prywatności, abyśmy mogli zacząć.',
    consent:
      'Wyrażam zgodę na wykorzystanie moich danych osobowych w celu weryfikacji mojej tożsamości w usługach Signicat Open Banking Verification.*',
    required: '* wymagane warunki',
    proceed: 'Potwierdź',
    whatYouNeed: 'Czego potrzebujesz?',
    wellLitPlace: 'Dobre oświetlenie',
    originalDocument: 'Dowód osobisty lub paszport',
    stableConnection: 'Stabilne połączenia internetowe',
    onlineBanking: 'Dostęp do bankowości elektronicznej',
    cameraAccess: 'Dostęp do kamery w urządzeniu',
  },
  progressPage: {
    heading: 'Weryfikacja tożsamości',
    wait: 'Aktualnie weryfikujemy Twoją tożsamość. Zwykle zajmuje to poniżej 3 minut.',
    waitMore:
      'Dziękujemy za cierpliwość. Już prawie gotowe. Prosimy o niezamykanie przeglądarki.',
  },
  jitPayWelcomePage: {
    heading: 'Witamy w procesie weryfikacji użytkownika Signicat',
    descriptionPartOne:
      'Witamy w procesie weryfikacji użytkownika Signicat. Signicat, wraz ze spółką zależną ElectronicID, jest zaufanym dostawcą usług certyfikowanym przez UE ',
    serviceProvider: 'dostawca usług',
    descriptionPartTwo:
      ' i będzie przeprowadzać proces identyfikacji i składania podpisu elektronicznego w celu rejestracji użytkownika.',
    idData:
      'Wyrażam zgodę na wykorzystanie moich danych osobowych w celu weryfikacji mojej tożsamości w usługach Signicat Open Banking Verification.',
    termsAndConditions: 'Akceptuję zasady i warunki korzystania z usługi.',
    privacyPolicy: 'Akceptuję politykę prywatności.',
    proceed: 'Potwierdź',
    stepOne: 'Podpis',
    stepTwo: 'Weryfikacja tożsamości',
    stepThree: 'Przelew o wartości 1 centa',
    stepThreeDesc: 'Wymagane przez organ nadzorujący',
  },
  jitPayOnboardingPage: {
    heading: 'Aby zakończyć proces, wykonaj trzy poniższe kroki:',
    signingHeader: '1. Podpis',
    signingContent: 'Sprawdź i podpisz umowę.',
    idVerifyHeader: '2. Weryfikacja tożsamości',
    idVerifyContent:
      'Proces identyfikacji zostanie przeprowadzony całkowicie elektronicznie i bez konieczności rozmowy z konsultantem.',
    micropaymentHeader: '3. Mikropłatność',
    micropaymentContent:
      'Przelew o wartości 0,01 EUR z osobistego konta bankowego.',
    proceed: 'Potwierdź',
  },
  jitPayVideoOnboardingPage: {
    header: 'Czego będziesz potrzebować do procesu VideoID:',
    lightHeading: 'Dobre oświetlenie',
    lightContent:
      'Upewnij się, że znajdujesz się w dobrze oświetlonym miejscu.',
    netHeading: 'Stabilne połączenie internetowe',
    netContent: 'Upewnij się, że masz stabilne połączenie z Internetem.',
    deviceHeading: 'Dostęp do kamery urządzenia',
    deviceContent: 'Zezwól na dostęp do kamery.',
    oneCameraHeading: 'Używaj tylko jednej kamery',
    oneCameraContent:
      'Upewnij się, że do Twojego komputera stacjonarnego podłączona jest tylko jedna kamera. W przypadku korzystania z laptopa należy użyć wbudowanej kamery.',
    proceed: 'Kontynuuj',
  },
  micropaymentInfoJitpayPage: {
    header: 'Mikropłatność z osobistego rachunku bankowego',
    content:
      'Jako dodatkowy środek bezpieczeństwa jesteśmy zobligowani przez organ nadzoru do wykonania przelewu w wysokości 0,01 EUR z Twojego konta bankowego i sprawdzenia, czy dane na koncie są zgodne z Twoją tożsamością.',
    warning: 'Prosimy o skorzystanie z osobistego konta bankowego',
    proceed: 'Potwierdź',
    idData:
      'Wyrażam zgodę na wykorzystanie moich danych osobowych w celu weryfikacji mojej tożsamości w usługach Signicat Open Banking Verification.',
  },
  micropaymentInfoIkanoPage: {
    content:
      'Jako dodatkowy środek bezpieczeństwa jesteśmy zobligowani przez organ nadzoru do wykonania przelewu w wysokości 0,01 EUR z Twojego konta bankowego i sprawdzenia, czy dane na koncie są zgodne z Twoją tożsamością.',
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    terms:
      'I accept the terms and conditions of the video-identification process and the issuance of short- term certificates',
  },
  ikanoWelcomePage: {
    heading: 'Witamy w procesie weryfikacji użytkownika Signicat',
    descriptionPartOne:
      'Witamy w procesie weryfikacji użytkownika Signicat. Signicat, wraz ze spółką zależną ElectronicID, jest zaufanym dostawcą usług certyfikowanym przez UE ',
    serviceProvider: 'dostawca usług',
    descriptionPartTwo:
      ' i będzie przeprowadzać proces identyfikacji i składania podpisu elektronicznego w celu rejestracji użytkownika.',
    idData:
      'Wyrażam zgodę na wykorzystanie moich danych osobowych w celu weryfikacji mojej tożsamości w usługach Signicat Open Banking Verification.',
    termsAndConditions: 'Akceptuję zasady i warunki korzystania z usługi.',
    privacyPolicy: 'Akceptuję politykę prywatności.',
    proceed: 'Potwierdź',
    stepOne: 'Podpis',
    stepTwo: 'Weryfikacja tożsamości',
    stepThree: 'Przelew o wartości 1 centa',
    stepThreeDesc: 'Wymagane przez organ nadzorujący',
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign a document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign a document',
  },
  introKontomatikPage: {
    header: 'Weryfikacja adresu',
    intro:
      'W kolejnym kroku zweryfikujemy Twój adres. Poprosimy o wybranie banku z listy i zalogowanie się na konto osobiste.',
    accountInformation: 'Pobrane zostaną następujące informacje o koncie:',
    names: 'Imię i nazwisko - właściciela(-i) konta',
    address: 'Adres - właściciela(-i) konta',
    ownership: 'Właściciel konta - właściciel, współwłaściciel itp.',
    verificationPurposes:
      'Z powyższych danych wykorzystamy <b>tylko twój adres</b> do celów weryfikacji.',
    proceed: 'Kontynuuj',
  },
  addressUploadPageKontomatik: {
    verifying: 'Weryfikacja...',
    header: 'Przesyłanie adresu',
    description: 'Nie mogliśmy pobrać adresu z informacji o koncie bankowym.',
    confirmation:
      'W celu potwierdzenia adresu poprosimy Cię o przesłanie wyciągów bankowych zawierających Twój adres.',
    bankStatementContains: 'Upewnij się, że wyciąg bankowy zawiera Twój adres',
    yourAddress: ' twój adres',
    proceed: 'Kontynuuj',
    dragAndDrop: 'Przeciągnij i upuść lub ',
    chooseFile: 'wybierz plik',
    pko: {
      header: 'Wyciąg bankowy PKO BP',
      confirmation:
        'W celu potwierdzenia adresu poprosimy Cię o przesłanie wyciągów bankowych zawierających Twój adres.',
      whereToFindStatements: 'Gdzie można znaleźć wyciągi bankowe w PKO BP?',
      selectPDF: 'Dodaj wyciąg bankowy w formacie PDF',
      tutorial: {
        desktop: {
          version: 'Wersja desktopowa',
          step1: '1. Zaloguj się na swoje konto osobiste PKO BP www.pkobp.pl',
          step2: '2. W górnym menu wybierz “Moje produkty”',
          step3:
            '3. Kliknij przycisk “Szczegóły” znajdujący się pod zestawieniem konta osobistego',
          step4:
            '4. Przewiń w dół i znajdź sekcję Korespondencja z banku i kliknij w “Pobierz wyciąg”',
          step5:
            '5. W nowo otwartym oknie kliknij przycisk “Pobierz” znajdujący się przy ostatnim wyciągu',
        },
        mobile: {
          version: 'Wersja mobilna',
          step1:
            '1. Zaloguj się na swoje konto osobiste PKO BP www.pkobp.pl. Kliknij ikonę kłódki w górnym menu',
          step2: '2. Wybierz serwis iPKO klikjąc w link “Zaloguj się”',
          step3:
            '3. Wpisz swój numer klienta lub login i kliknij przycisk “Dalej”',
          step4: '4. W dolnym menu wybierz “Moje produkty”',
          step5:
            '5. Kliknij przycisk “Szczegóły” znajdujący się pod zestawieniem konta osobistego',
          step6:
            '6. Przewiń w dół i znajdź sekcję Korespondencja z banku i kliknij w “Pobierz wyciąg”',
          step7:
            '7. W nowo otwartym oknie kliknij przycisk “Pobierz” znajdujący się przy ostatnim wyciągu',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
