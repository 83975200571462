export const translations = {
  pageTitle: '',
  documentTypePage: {
    header: 'Olá, vamos fazer uma gravação para verificar a sua identidade',
    description: 'Escolha o documento com o qual se vai identificar',
    proceed: 'Continuar',
    selectCountry: 'Selecionar o país que emitiu o documento',
    chooseDocument: 'Escolha o seu tipo de documento',
    ResidentPermit: 'Autorização de residência',
    EUResidentPermit: 'Autorização de residência na UE',
    TravelTitle: 'Título da viagem',
    DriverLicense: 'Carta de condução',
    Passport: 'Passaporte',
    IdentityCard: 'Bilhete de identidade',
    inputPlaceholder: 'Selecionar',
  },
  generalConditionsPage: {
    header: 'Condições gerais',
    termsAndConditions:
      'Aceito os termos e condições de utilização do serviço.',
    privacyPolicy: 'Aceito a política de privacidade.',
    required: '* condições necessárias',
    proceed: 'Aceitar',
    conditionTermsHeading: 'Termos e condições de utilização',
    conditionPrivacyHeading: 'Política de privacidade',
  },
  signDocumentPage: {
    header: 'Assinar um documento',
    checkboxLabel:
      'Confirmo que li e compreendi os termos do presente acordo, tal como acima descritos.',
    proceed: 'Assinar um documento',
  },
  videoOnboardingPage: {
    header: 'O que é que precisa?',
    wellLitPlace: 'Um local bem iluminado.',
    originalDocument: 'O seu documento original, válido e sem capas.',
    wifi: 'Ligação Wi-Fi ou cobertura 4G máxima.',
    start: 'Continuar',
  },
  documentType: {
    selectCountry: 'Selecionar o país que emitiu o documento',
    ResidentPermit: 'Autorização de residência',
    EUResidentPermit: 'Autorização de residência na UE',
    TravelTitle: 'Título da viagem',
    IdentityCard: 'Bilhete de identidade',
  },
  otpPage: {
    verify: 'Verificar',
    header: 'Introduza o código de verificação enviado para o seu telemóvel',
    headerVerifying: 'Aguarde a sua verificação.',
  },
  userDataPage: {
    header: 'Fornecer informações de contacto',
    proceed: 'Confirmar',
    email: 'E-mail:',
    phone: 'Telefone:',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: 'Verificação de identidade',
    firstParagraph:
      'Bem-vindo ao processo de verificação de identidade da Signicat. A Signicat, com a sua subsidiária ElectronicID, é um prestador de serviços de confiança certificado pela UE [https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42] e irá realizar a sua identificação e assinatura eletrónica para a sua integração com',
    firstParagraphPartOne:
      'Bem-vindo ao processo de verificação de identidade da Signicat. A Signicat, com a sua filial ElectronicID, é uma empresa de confiança certificada pela UE ',
    serviceProvider: 'prestador de serviços',
    firstParagraphPartTwo:
      ' e realizará a sua identificação e assinatura eletrónica para a sua integração',
    secondParagraph:
      'O seu processo de identificação será efectuado de forma totalmente digital e sem necessidade de falar com um agente. Para completar o processo, precisamos de:',
    thirdParagraph:
      'Acesso ao seu banco online? Sim, correto. Como medida de segurança adicional, somos obrigados pelo regulador a iniciar uma transferência de 0,01 EUR da sua conta bancária e a verificar se o nome na conta corresponde à sua identidade.',
    fourthParagraph:
      'Pronto para começar? Ótimo, aceite a nossa política de privacidade para podermos começar.',
    consent:
      'Autorizo a utilização dos meus dados pessoais para me identificar com os serviços de Verificação do Open Banking da Signicat*.',
    required: '* condição necessária',
    proceed: 'Confirmar',
    whatYouNeed: 'O que é que precisa?',
    wellLitPlace: 'Boa iluminação',
    originalDocument: 'O seu bilhete de identidade nacional ou passaporte',
    stableConnection: 'Ligações estáveis à Internet',
    onlineBanking: 'Acesso ao seu banco online',
    cameraAccess: 'Acesso à câmara do seu dispositivo',
  },
  progressPage: {
    heading: 'Verificação da identidade',
    wait: 'Agora estamos a verificar a sua identidade. Normalmente, demora menos de 3 minutos.',
    waitMore:
      'Obrigado pela vossa paciência. Está quase pronto. Por favor, não feche o browser.',
  },
  jitPayWelcomePage: {
    heading: 'Bem-vindo ao processo de verificação de identidade da Signicat',
    descriptionPartOne:
      'A Signicat, com a sua filial ElectronicID, é uma empresa de confiança certificada pela UE ',
    serviceProvider: 'prestador de serviços',
    descriptionPartTwo:
      ' e efectuará a sua identificação e assinatura eletrónica para a sua integração.',
    idData:
      'Autorizo a utilização dos meus dados pessoais para me identificar com os serviços de Verificação do Open Banking da Signicat.',
    termsAndConditions:
      'Aceito os termos e condições de utilização do serviço.',
    privacyPolicy: 'Aceito a política de privacidade.',
    proceed: 'Confirmar',
    stepOne: 'Assinatura',
    stepTwo: 'Verificação de identidade',
    stepThree: 'Transferência de 1 cêntimo',
    stepThreeDesc: 'Exigido pelo regulador',
  },
  jitPayOnboardingPage: {
    heading: 'Para concluir o processo, siga estes três passos:',
    signingHeader: '1. Assinatura',
    signingContent: 'Rever e assinar o contrato.',
    idVerifyHeader: '2. Verificação de identidade',
    idVerifyContent:
      'O seu processo de identificação será efectuado de forma totalmente digital e sem necessidade de falar com um agente.',
    micropaymentHeader: '3. Micropagamento',
    micropaymentContent:
      'Transferência de 0,01 EUR da sua conta bancária pessoal.',
    proceed: 'Confirmar',
  },
  jitPayVideoOnboardingPage: {
    header: 'O que é necessário para o processo de VideoID:',
    lightHeading: 'Boa iluminação',
    lightContent:
      'Certifique-se de que se encontra num ambiente bem iluminado.',
    netHeading: 'Ligação estável à Internet',
    netContent: 'Certifique-se de que dispõe de uma boa ligação à Internet.',
    deviceHeading: 'Acesso ao seu dispositivo de câmara',
    deviceContent: 'Autorizar o acesso ao dispositivo da câmara.',
    oneCameraHeading: 'Utilizar apenas uma câmara',
    oneCameraContent:
      'Certifique-se de que tem apenas um dispositivo de câmara ligado ao seu computador de secretária. Se estiver a utilizar um computador portátil, a câmara incorporada é a câmara correcta a utilizar.',
    proceed: 'Continuar',
  },
  micropaymentInfoJitpayPage: {
    header: 'Micropagamento a partir da sua conta bancária pessoal',
    content:
      'Como medida de segurança adicional, somos obrigados pelo regulador a iniciar uma transferência de 0,01 EUR a partir da sua conta bancária pessoal e a verificar se o nome na conta corresponde à sua identidade.',
    warning: 'Utilize a sua conta bancária pessoal',
    proceed: 'Confirmar',
    idData:
      'Autorizo a utilização dos meus dados pessoais para me identificar com os serviços de Verificação do Open Banking da Signicat.',
  },
  micropaymentInfoIkanoPage: {
    content:
      'Como medida de segurança adicional, somos obrigados pelo regulador a iniciar uma transferência de 0,01 EUR a partir da sua conta bancária pessoal e a verificar se o nome na conta corresponde à sua identidade.',
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    terms:
      'I accept the terms and conditions of the video-identification process and the issuance of short- term certificates',
  },
  ikanoWelcomePage: {
    heading: 'Bem-vindo ao processo de verificação de identidade da Signicat',
    descriptionPartOne:
      'A Signicat, com a sua filial ElectronicID, é uma empresa de confiança certificada pela UE ',
    serviceProvider: 'prestador de serviços',
    descriptionPartTwo:
      ' e efectuará a sua identificação e assinatura eletrónica para a sua integração.',
    idData:
      'Autorizo a utilização dos meus dados pessoais para me identificar com os serviços de Verificação do Open Banking da Signicat.',
    termsAndConditions:
      'Aceito os termos e condições de utilização do serviço.',
    privacyPolicy: 'Aceito a política de privacidade.',
    proceed: 'Confirmar',
    stepOne: 'Assinatura',
    stepTwo: 'Verificação de identidade',
    stepThree: 'Transferência de 1 cêntimo',
    stepThreeDesc: 'Exigido pelo regulador',
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign a document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign a document',
  },
  introKontomatikPage: {
    header: 'Verificação de endereço',
    intro:
      'Na etapa seguinte, verificaremos o seu endereço. Pedir-lhe-emos que seleccione o seu banco na lista e que inicie sessão na sua conta pessoal.',
    accountInformation: 'Iremos obter as seguintes informações sobre a conta:',
    names: 'Nome próprio e apelido - do(s) titular(es) da conta',
    address: 'Endereço - do(s) titular(es) da conta',
    ownership: 'Propriedade da conta - proprietário, coproprietário, etc.',
    verificationPurposes:
      'A partir dos dados acima, vamos utilizar <b>apenas o seu endereço</b> para efeitos de verificação.',
    proceed: 'Continuar',
  },
  addressUploadPageKontomatik: {
    verifying: 'Verificar...',
    header: 'Carregamento de endereços',
    description:
      'Não foi possível obter o endereço a partir das informações da sua conta bancária.',
    confirmation:
      'Para confirmar o seu endereço, pedimos-lhe que carregue os extractos bancários que contêm o seu endereço.',
    bankStatementContains:
      'Certifique-se de que o seu extrato bancário contém o seu endereço',
    yourAddress: ' o seu endereço',
    proceed: 'Continuar',
    dragAndDrop: 'Arrastar e largar ou ',
    chooseFile: 'escolher ficheiro',
    pko: {
      header: 'PKO BP Extrato bancário',
      confirmation:
        'Para confirmar o seu endereço, pedimos-lhe que carregue os extractos bancários que contêm o seu endereço.',
      whereToFindStatements: 'Onde encontrar extractos bancários em PKO BP?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
