import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import AmexSlogan from '../../assets/amex_slogan.jpg'
import { StoreContext } from '../../components/App'
import { AmexLogo } from '../../components/Icons/AmexLogo'
import { NBankIDLogo } from '../../components/Icons/NBankIDLogo'
import { SBankIDLogo } from '../../components/Icons/SBankIDLogo'
import { Loading } from '../../components/Loading/Loading'

import axios from 'axios'
import {
  ForgotLink,
  Form,
  FormTitle,
  FullScreen,
  Input,
  InputLabel,
  InputWrapper,
  LeftColumn,
  LoginButton,
  Logo,
  Page,
  RightColumn,
  SeparatorLine,
  Slogan,
  SubTitle,
  TextWrapper,
  Title,
} from './LoginPage.styles'

export const LoginPage = observer(() => {
  const path = window.location.pathname.substring(1)
  // const [path, setPath] = React.useState(window.location.pathname.substring(1))

  const store = useContext(StoreContext)
  const { theme } = store.AppState

  const [nbidLoading, setNbidLoading] = React.useState(false)
  const [sbidLoading, setSbidLoading] = React.useState(false)

  const handleLogin = async (method) => {
    if (method === 'nbid') {
      setNbidLoading(true)
    } else {
      setSbidLoading(true)
    }

    try {
      const res = await axios.post(
        `https://onboarding-amex-proxy-89e69358ff2a.herokuapp.com/create-transaction`,
        {
          method: method,
          redirectUrl: `${window.origin}/membership-benefits`,
        },
        {
          withCredentials: true,
        }
      )

      window.location.href = res.data.redirectUrl
      console.log(res)

      setTimeout(() => {
        setNbidLoading(false)
        setSbidLoading(false)
      }, 5000)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    // const storedCountry = localStorage.getItem('country')

    // store country in localstorage
    if (path === 'no' || path === 'sv') {
      localStorage.setItem('country', path)
    }

    // else if (storedCountry === 'no' || storedCountry === 'sv') {
    //   setPath(storedCountry)
    // }
  }, [])

  return (
    <Page>
      <FullScreen>
        <Logo>
          <AmexLogo />
        </Logo>
        <LeftColumn>
          <TextWrapper>
            <Title>
              Go Platinum. <br /> Book Your Dream Destination.{' '}
            </Title>
            <SubTitle>
              Earn 5X points on flights you book directly with airlines or
              amextravel.com.
            </SubTitle>
            <Slogan src={AmexSlogan} />
          </TextWrapper>
        </LeftColumn>
        <RightColumn>
          <Form>
            <FormTitle>Log in via email</FormTitle>

            <InputWrapper>
              <InputLabel>Email address</InputLabel>
              <Input type="text" name="email" />
              <InputLabel>Password</InputLabel>
              <Input type="password" name="password" />
            </InputWrapper>

            <LoginButton>Log in</LoginButton>

            <ForgotLink>Forgot User ID or Password?</ForgotLink>

            <SeparatorLine />

            <FormTitle>Log in via BankID</FormTitle>

            {path !== 'sv' && (
              <LoginButton
                bgColor="#3E104F"
                onClick={(e) => {
                  if (nbidLoading || sbidLoading) return
                  e.preventDefault()
                  handleLogin('nbid')
                }}
              >
                {nbidLoading ? (
                  <Loading
                    bgColor="#3E104F"
                    primary="#eeeeee"
                    width="25px"
                    padding="0 0"
                    thickness={3}
                  />
                ) : (
                  <NBankIDLogo color="#eeeeee" />
                )}
              </LoginButton>
            )}

            {path !== 'no' && (
              <LoginButton
                bgColor="#043F51"
                onClick={(e) => {
                  if (nbidLoading || sbidLoading) return
                  e.preventDefault()
                  handleLogin('sbid')
                }}
              >
                {sbidLoading ? (
                  <Loading
                    bgColor="#043F51"
                    primary="#eeeeee"
                    width="25px"
                    padding="0 0"
                    thickness={3}
                  />
                ) : (
                  <SBankIDLogo color="#eeeeee" />
                )}
              </LoginButton>
            )}
          </Form>
        </RightColumn>
      </FullScreen>
    </Page>
  )
})
