import styled from 'styled-components'
import { mobile } from '../constants/breakpoints'

export const ContentWrap = styled.div`
  @media (max-width: 980px) {
    width: 100%;
    min-width: auto;
    margin: 0;
    padding: 0;

    > div {
      height: calc(100vh - 120px);
      position: relative;
      border-bottom: none;
      border: none;
      box-shadow: none;
    }
  }

  button {
    transition: all 0.2s ease-in-out;
  }

  a {
    font-weight: 700;
  }

  * {
    box-sizing: border-box;
  }
`

export const InnerAppLayout = styled.div`
  display: flex;
  height: calc(100vh - 70px);
`

interface InnerAppContentProps {
  padding?: string
  mobilePadding?: string
}

export const InnerAppContent = styled.div<InnerAppContentProps>`
  width: 100%;
  box-sizing: border-box;
  padding: ${(props) => (props.padding ? props.padding : '10px 40px 40px')};
  overflow: hidden;
  overflow-x: scroll;
  overflow-y: scroll;
  background-color: #eff1f5;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  ::-webkit-scrollbar {
    width: 11px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 0;
  }

  ::-webkit-scrollbar-thumb {
    background: #0071d7;
    border-radius: 0;
  }

  @media (max-width: ${mobile}) {
    padding: ${(props) =>
      props.mobilePadding ? props.mobilePadding : '10px 20px 160px 20px'};
  }
`

export const DevTools = styled.div`
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 1px solid gray;
  box-sizing: border-box;
  padding: 20px;
`

interface MarginerProps {
  margin?: string
}

export const Marginer = styled.div<MarginerProps>`
  margin: ${(props) => (props.margin ? props.margin : '0')};
`

export const SubpageWrapper = styled.div`
  width: 100%;
`

export const SubpageContentWrapper = styled.div`
  max-width: 1056px;
  width: 100%;
`
