import React from 'react'
import styled from 'styled-components'

interface ContainerProps {
  width?: string
  padding?: string
}

export const Container = styled.div<ContainerProps>`
  width: ${(props) => (props.width ? props.width : '')};
  padding: ${(props) => (props.padding ? props.padding : '')};

  svg {
    width: 100%;
    height: auto;
  }
`

interface PhantomKeyLogoProps {
  width?: string
  padding?: string
  color1?: string
  color2?: string
}

export const PhantomKeyLogo = ({
  width,
  padding,
  color1,
  color2,
}: PhantomKeyLogoProps) => {
  const color1Value = color1 ? color1 : '#110F19'
  const color2Value = color2 ? color2 : '#1FBFB7'
  return (
    <Container width={width ? width : '80px'} padding={padding ? padding : '0'}>
      <svg
        width="99"
        height="42"
        viewBox="0 0 99 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.5405 11.3956V17.9195H22.6006V2.35449H24.8301C27.1291 2.35449 28.7496 2.72939 29.6918 3.47917C30.634 4.22896 31.1061 5.35563 31.1061 6.8552C31.1061 8.35478 30.6003 9.53896 29.5887 10.2828C28.5771 11.0266 27.2659 11.3976 25.6553 11.3976H24.5405V11.3956ZM24.5405 4.08813V9.66194H25.1594C25.7227 9.66194 26.2226 9.62822 26.655 9.5588C27.0894 9.48937 27.5178 9.36243 27.9443 9.17796C28.3708 8.9915 28.702 8.69992 28.9361 8.30122C29.1702 7.90252 29.2872 7.40663 29.2872 6.81553C29.2872 6.22443 29.1642 5.75631 28.9163 5.36951C28.6683 4.9847 28.3172 4.70502 27.863 4.53245C27.4087 4.35988 26.9684 4.24285 26.5419 4.18136C26.1155 4.11986 25.6196 4.08813 25.0562 4.08813H24.5405Z"
          fill={color1Value}
        />
        <path
          d="M33.1274 0H34.986V9.5588H35.0277C35.3034 9.04902 35.7061 8.65826 36.2357 8.38255C36.7653 8.10683 37.3326 7.96996 37.9396 7.96996C39.2051 7.96996 40.0937 8.34089 40.6035 9.08473C41.1133 9.82856 41.3672 10.8541 41.3672 12.1612V17.9215H39.5086V12.3893C39.5086 11.4392 39.3737 10.741 39.1059 10.2947C38.8381 9.8484 38.2629 9.62426 37.3822 9.62426C36.9974 9.62426 36.6602 9.67186 36.3706 9.76906C36.081 9.86625 35.8548 10.0249 35.6902 10.2431C35.5256 10.4633 35.3907 10.6736 35.2875 10.8719C35.1844 11.0723 35.113 11.3539 35.0713 11.7189C35.0297 12.0839 35.0059 12.3695 34.9999 12.5758C34.992 12.7821 34.99 13.0856 34.99 13.4843V17.9235H33.1314V0H33.1274Z"
          fill={color1Value}
        />
        <path
          d="M53.5045 8.25762V17.9196H51.6459V16.4121H51.6043C50.7791 17.6379 49.6842 18.2488 48.3215 18.2488C46.9588 18.2488 45.7785 17.743 44.9454 16.7314C44.1123 15.7198 43.6958 14.484 43.6958 13.0261C43.6958 11.6495 44.1163 10.4633 44.9554 9.46561C45.7944 8.46788 46.8953 7.96802 48.258 7.96802C49.6207 7.96802 50.7632 8.60078 51.6023 9.86827H51.6439V8.25762H53.5025H53.5045ZM48.6111 16.5985C49.589 16.5985 50.3546 16.2613 50.912 15.5869C51.4694 14.9125 51.7471 14.0794 51.7471 13.0896C51.7471 12.0998 51.4654 11.2905 50.9001 10.622C50.3348 9.95555 49.5711 9.62033 48.6091 9.62033C47.6471 9.62033 46.9469 9.9615 46.3895 10.6419C45.8321 11.3222 45.5544 12.1395 45.5544 13.0876C45.5544 14.0357 45.8301 14.8768 46.3796 15.5651C46.929 16.2534 47.6728 16.5965 48.6091 16.5965L48.6111 16.5985Z"
          fill={color1Value}
        />
        <path
          d="M58.2931 8.25759V9.55881H58.3348C58.6105 9.04903 59.0131 8.65827 59.5428 8.38255C60.0724 8.10684 60.6397 7.96997 61.2466 7.96997C62.5122 7.96997 63.4008 8.3409 63.9106 9.08473C64.4203 9.82857 64.6742 10.8541 64.6742 12.1612V17.9215H62.8156V12.3894C62.8156 11.4392 62.6808 10.741 62.413 10.2947C62.1452 9.84841 61.57 9.62426 60.6893 9.62426C60.3044 9.62426 59.9672 9.67187 59.6776 9.76906C59.388 9.86626 59.1619 10.0249 58.9973 10.2431C58.8326 10.4633 58.6978 10.6736 58.5946 10.8719C58.4915 11.0723 58.4201 11.3539 58.3784 11.7189C58.3367 12.0839 58.3129 12.3695 58.307 12.5758C58.2991 12.7821 58.2971 13.0856 58.2971 13.4843V17.9235H56.4385V8.26156H58.2971L58.2931 8.25759Z"
          fill={color1Value}
        />
        <path
          d="M69.8746 9.99127V17.9196H68.016V9.99127H67.0044V8.25764H68.016V4.56226H69.8746V8.25764H71.6916V9.99127H69.8746Z"
          fill={color1Value}
        />
        <path
          d="M73.8482 9.45566C74.8459 8.46388 76.0678 7.96997 77.5118 7.96997C78.9559 7.96997 80.1777 8.46586 81.1755 9.45566C82.1732 10.4474 82.6731 11.6654 82.6731 13.1094C82.6731 14.5534 82.1732 15.7733 81.1755 16.7631C80.1777 17.7549 78.9559 18.2488 77.5118 18.2488C76.0678 18.2488 74.8459 17.7529 73.8482 16.7631C72.8504 15.7713 72.3506 14.5534 72.3506 13.1094C72.3506 11.6654 72.8485 10.4455 73.8482 9.45566ZM79.8445 10.7351C79.1979 10.0745 78.4203 9.74328 77.5118 9.74328C76.6033 9.74328 75.8258 10.0745 75.1792 10.7351C74.5325 11.3956 74.2092 12.187 74.2092 13.1094C74.2092 14.0317 74.5325 14.8053 75.1792 15.4738C75.8258 16.1423 76.6033 16.4755 77.5118 16.4755C78.4203 16.4755 79.1979 16.1423 79.8445 15.4738C80.4911 14.8073 80.8145 14.0179 80.8145 13.1094C80.8145 12.2009 80.4911 11.3956 79.8445 10.7351Z"
          fill={color1Value}
        />
        <path
          d="M86.6798 8.25759V9.4755H86.7215C86.9972 9.03515 87.3642 8.67414 87.8263 8.39247C88.2865 8.1108 88.7725 7.96997 89.2823 7.96997C90.4803 7.96997 91.4086 8.54124 92.0692 9.68377C92.3727 9.16011 92.7912 8.74356 93.3287 8.43412C93.8663 8.12469 94.4356 7.96997 95.0425 7.96997C97.1749 7.96997 98.242 9.36045 98.242 12.1394V17.9195H96.3834V12.4489C96.3834 12.0779 96.3656 11.7506 96.3319 11.469C96.2981 11.1873 96.2208 10.8918 96.1057 10.5823C95.9887 10.2729 95.8022 10.0349 95.5483 9.87023C95.2944 9.70559 94.9731 9.62228 94.5883 9.62228C94.2035 9.62228 93.8762 9.70162 93.6084 9.86031C93.3406 10.019 93.1343 10.2114 92.9895 10.4375C92.8447 10.6637 92.7317 10.9493 92.6484 11.2944C92.5651 11.6376 92.5135 11.949 92.4937 12.2227C92.4738 12.4984 92.4619 12.8138 92.4619 13.1729V17.9215H90.6033V12.8019C90.6033 12.4707 90.5954 12.1969 90.5835 11.9768C90.5696 11.7566 90.5319 11.4809 90.4704 11.1516C90.4089 10.8204 90.3197 10.5605 90.2026 10.3661C90.0856 10.1737 89.9091 10.0011 89.677 9.85039C89.4429 9.69964 89.1613 9.62426 88.83 9.62426C88.3619 9.62426 87.9731 9.73138 87.6637 9.94362C87.3542 10.1578 87.1341 10.4593 87.0031 10.8521C86.8722 11.2448 86.7869 11.6118 86.7453 11.9569C86.7036 12.3021 86.6838 12.7067 86.6838 13.1748V17.9235H84.8252V8.26156H86.6838L86.6798 8.25759Z"
          fill={color1Value}
        />
        <path
          d="M25.701 27.5894L30.5112 21.0238H34.2482L28.3431 28.2083L34.5775 36.5888H30.6758L25.701 29.5908H25.6594V36.5888H22.6245V21.0238H25.6594V27.5894H25.701Z"
          fill={color2Value}
        />
        <path
          d="M44.8582 31.9869H38.1895C38.1895 32.7308 38.3501 33.3695 38.6754 33.907C38.9987 34.4446 39.5046 34.7123 40.1929 34.7123C41.0319 34.7123 41.6785 34.2522 42.1328 33.3298L44.7967 33.7642C44.4516 34.7699 43.8684 35.5474 43.0413 36.0969C42.2141 36.6463 41.266 36.922 40.1929 36.922C38.6516 36.922 37.4238 36.4301 36.5074 35.4463C35.591 34.4624 35.1348 33.185 35.1348 31.616C35.1348 30.047 35.5573 28.6883 36.4042 27.6628C37.2512 26.6372 38.4652 26.1255 40.0481 26.1255C41.6309 26.1255 42.827 26.6372 43.6403 27.6628C44.4516 28.6883 44.8582 30.0133 44.8582 31.6378V31.9889V31.9869ZM38.2728 30.1918H42.1328C42.0495 29.6285 41.8412 29.1465 41.504 28.7458C41.1668 28.3471 40.7364 28.1467 40.2147 28.1467C39.6632 28.1467 39.2229 28.3431 38.8936 28.7359C38.5643 29.1286 38.3561 29.6126 38.2747 30.1918H38.2728Z"
          fill={color2Value}
        />
        <path
          d="M49.6268 35.0437L45.3125 26.4548H48.5953L51.0113 32.2151L53.6117 26.4548H56.833L49.4621 42H46.2825L49.6268 35.0437Z"
          fill={color2Value}
        />
        <path
          d="M8.78349 23.7981C11.9205 23.7981 14.4734 26.35 14.4734 29.488C14.4734 32.6261 11.9215 35.1779 8.78349 35.1779C5.64544 35.1779 3.09357 32.6261 3.09357 29.488C3.09357 26.35 5.64544 23.7981 8.78349 23.7981ZM8.78349 21.9584C4.62511 21.9584 1.25488 25.3296 1.25488 29.487C1.25488 33.6443 4.62615 37.0156 8.78349 37.0156C12.9408 37.0156 16.3121 33.6443 16.3121 29.487C16.3121 25.3296 12.9408 21.9584 8.78349 21.9584Z"
          fill={color1Value}
        />
        <path
          d="M10.0384 2.50952H8.15625V20.0763H10.0384V2.50952Z"
          fill={color1Value}
        />
        <path
          d="M1.88184 7.52855L1.88184 10.0381H5.64614V7.52855H1.88184Z"
          fill={color1Value}
        />
        <path
          d="M0 2.50957L0 4.39172H5.64645V2.50957L0 2.50957Z"
          fill={color2Value}
        />
      </svg>
    </Container>
  )
}
