export const translations = {
  pageTitle: '',
  completedPage: {
    heading: 'Terminé',
    description: "Le processus d'identification a été réalisé",
  },
  documentTypePage: {
    header:
      'Bonjour, nous allons effectuer un enregistrement pour vérifier votre identité',
    description:
      'Choisissez le document avec lequel vous allez vous identifier',
    proceed: 'Continuer',
    selectCountry: 'Sélectionnez le pays qui a délivré le document',
    chooseDocument: 'Choisissez votre type de document',
    ResidentPermit: 'Permis de séjour',
    EUResidentPermit: "Permis de séjour de l'UE",
    TravelTitle: 'Titre de voyage',
    DriverLicense: 'Permis de conduire',
    Passport: 'Passeport',
    IdentityCard: "Carte d'identité",
    inputPlaceholder: 'Sélectionner',
  },
  generalConditionsPage: {
    header: 'Conditions générales',
    termsAndConditions: "J'accepte les conditions d'utilisation du service.",
    privacyPolicy: "J'accepte la politique de confidentialité.",
    required: '* conditions requises',
    proceed: 'Accepter',
    conditionTermsHeading: "Conditions d'utilisation",
    conditionPrivacyHeading: 'Politique de confidentialité',
  },
  signDocumentPage: {
    header: 'Signer un document',
    checkboxLabel:
      "Je reconnais avoir lu et compris les termes de cet accord tels qu'ils sont détaillés ci-dessus.",
    proceed: 'Signer un document',
  },
  videoOnboardingPage: {
    header: 'De quoi avez-vous besoin ?',
    wellLitPlace: 'Un endroit bien éclairé.',
    originalDocument: 'Votre document original, valide et sans couverture.',
    wifi: 'Connexion Wi-Fi ou couverture 4G maximale.',
    start: 'Continuer',
  },
  documentType: {
    selectCountry: 'Sélectionnez le pays qui a délivré le document',
    ResidentPermit: 'Permis de séjour',
    EUResidentPermit: "Permis de séjour de l'UE",
    TravelTitle: 'Titre de voyage',
    IdentityCard: "Carte d'identité",
  },
  otpPage: {
    verify: 'Vérifier',
    header: 'Entrez le code de vérification envoyé à votre téléphone',
    headerVerifying: 'Veuillez attendre votre vérification.',
  },
  userDataPage: {
    header: 'Veuillez fournir des informations de contact',
    proceed: 'Confirmer',
    email: 'E-mail :',
    phone: 'Téléphone :',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: "Vérification de l'identité",
    firstParagraph:
      "Bienvenue dans le processus de vérification d'identité de Signicat. Signicat, avec sa filiale ElectronicID, est un prestataire de services de confiance certifié par l'Union européenne [https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42] et procédera à votre identification et à votre signature électronique dans le cadre de votre inscription auprès de",
    firstParagraphPartOne:
      "Bienvenue dans le processus de vérification d'identité de Signicat. Signicat, avec sa filiale ElectronicID, est un organisme de confiance certifié par l'UE ",
    serviceProvider: 'fournisseur de services',
    firstParagraphPartTwo:
      ' et procédera à votre identification et à votre signature électronique pour votre onboarding.',
    secondParagraph:
      "Votre processus d'identification sera entièrement numérique et ne nécessitera pas l'intervention d'un agent. Pour mener à bien le processus, nous aurons besoin de",
    thirdParagraph:
      "Accès à votre banque en ligne ? Oui, c'est exact. Comme mesure de sécurité supplémentaire, nous sommes obligés par le régulateur d'initier un transfert de 0,01 EUR à partir de votre compte bancaire et de vérifier si le nom sur le compte correspond à votre identité.",
    fourthParagraph:
      'Prêt à partir ? Très bien, veuillez accepter notre politique de confidentialité pour que nous puissions commencer.',
    consent:
      "Je consens à l'utilisation de mes données personnelles pour m'identifier avec les services de vérification de Signicat Open Banking*.",
    required: '* condition requise',
    proceed: 'Confirmer',
    whatYouNeed: 'De quoi avez-vous besoin ?',
    wellLitPlace: 'Bon éclairage',
    originalDocument: "Votre carte d'identité nationale ou votre passeport",
    stableConnection: 'Connexions internet stables',
    onlineBanking: 'Accès à vos services bancaires en ligne',
    cameraAccess: 'Accès à la caméra de votre appareil',
  },
  progressPage: {
    heading: "Vérification de l'identité",
    wait: 'Nous vérifions maintenant votre identité. En général, cela prend moins de 3 minutes.',
    waitMore:
      "Merci de votre patience. C'est presque terminé. Ne fermez pas le navigateur.",
  },
  jitPayWelcomePage: {
    heading:
      "Bienvenue dans le processus de vérification de l'identité de Signicat",
    descriptionPartOne:
      "Signicat, avec sa filiale ElectronicID, est une société de confiance certifiée par l'UE ",
    serviceProvider: 'fournisseur de services',
    descriptionPartTwo:
      ' et procédera à votre identification et à votre signature électronique dans le cadre de votre intégration.',
    idData:
      "Je consens à l'utilisation de mes données personnelles pour m'identifier avec les services de vérification de Signicat Open Banking.",
    termsAndConditions: "J'accepte les conditions d'utilisation du service.",
    privacyPolicy: "J'accepte la politique de confidentialité.",
    proceed: 'Confirmer',
    stepOne: 'Signature',
    stepTwo: "Vérification de l'identité",
    stepThree: 'Transfert de 1 cent',
    stepThreeDesc: "Exigée par l'autorité de régulation",
  },
  jitPayOnboardingPage: {
    heading: 'Pour terminer la procédure, suivez les trois étapes suivantes :',
    signingHeader: '1. Signature',
    signingContent: 'Examiner et signer le contrat.',
    idVerifyHeader: "2. Vérification de l'identité",
    idVerifyContent:
      "Votre processus d'identification s'effectuera de manière entièrement numérique et sans qu'il soit nécessaire de parler à un agent.",
    micropaymentHeader: '3. Micropaiement',
    micropaymentContent:
      '0,01 EUR par virement de votre compte bancaire personnel.',
    proceed: 'Confirmer',
  },
  jitPayVideoOnboardingPage: {
    header: 'Ce dont vous aurez besoin pour le processus VideoID :',
    lightHeading: 'Bon éclairage',
    lightContent: "Assurez-vous d'être dans un environnement bien éclairé.",
    netHeading: 'Connexion internet stable',
    netContent:
      "Assurez-vous que vous disposez d'une connexion internet solide.",
    deviceHeading: 'Accès à votre appareil photo',
    deviceContent: "Autoriser l'accès à votre appareil photo.",
    oneCameraHeading: 'Utiliser une seule caméra',
    oneCameraContent:
      "Veillez à ce qu'un seul appareil photo soit branché sur votre ordinateur de bureau. Si vous utilisez un ordinateur portable, la caméra intégrée est la caméra à utiliser.",
    proceed: 'Continuer',
  },
  micropaymentInfoJitpayPage: {
    header: 'Micropaiement à partir de votre compte bancaire personnel',
    content:
      "Comme mesure de sécurité supplémentaire, nous sommes obligés par le régulateur d'initier un transfert de 0,01 EUR à partir de votre compte bancaire personnel et de vérifier si le nom sur le compte correspond à votre identité.",
    warning: 'Veuillez utiliser votre compte bancaire personnel',
    proceed: 'Confirmer',
    idData:
      "Je consens à l'utilisation de mes données personnelles pour m'identifier avec les services de vérification de Signicat Open Banking.",
  },
  micropaymentInfoIkanoPage: {
    content:
      "Comme mesure de sécurité supplémentaire, nous sommes obligés par le régulateur d'initier un transfert de 0,01 EUR à partir de votre compte bancaire personnel et de vérifier si le nom sur le compte correspond à votre identité.",
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    terms:
      'I accept the terms and conditions of the video-identification process and the issuance of short- term certificates',
  },
  ikanoWelcomePage: {
    heading:
      "Bienvenue dans le processus de vérification de l'identité de Signicat",
    descriptionPartOne:
      "Signicat, avec sa filiale ElectronicID, est une société de confiance certifiée par l'UE ",
    serviceProvider: 'fournisseur de services',
    descriptionPartTwo:
      ' et procédera à votre identification et à votre signature électronique dans le cadre de votre intégration.',
    idData:
      "Je consens à l'utilisation de mes données personnelles pour m'identifier avec les services de vérification de Signicat Open Banking.",
    termsAndConditions: "J'accepte les conditions d'utilisation du service.",
    privacyPolicy: "J'accepte la politique de confidentialité.",
    proceed: 'Confirmer',
    stepOne: 'Signature',
    stepTwo: "Vérification de l'identité",
    stepThree: 'Transfert de 1 cent',
    stepThreeDesc: "Exigée par l'autorité de régulation",
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign a document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign a document',
  },
  introKontomatikPage: {
    header: "Vérification de l'adresse",
    intro:
      "L'étape suivante consiste à vérifier votre adresse. Nous vous demanderons de sélectionner votre banque dans la liste et de vous connecter à votre compte personnel.",
    accountInformation:
      'Nous récupérerons les informations suivantes sur le compte :',
    names: 'Prénom et nom - du (des) titulaire(s) du compte',
    address: 'Adresse - du (des) titulaire(s) du compte',
    ownership: 'Propriété du compte - propriétaire, copropriétaire, etc.',
    verificationPurposes:
      'A partir des données ci-dessus, nous allons utiliser <b>uniquement votre adresse</b> à des fins de vérification.',
    proceed: 'Continuer',
  },
  addressUploadPageKontomatik: {
    verifying: 'Vérification...',
    header: "Téléchargement de l'adresse",
    description:
      "Nous n'avons pas pu obtenir l'adresse de votre compte bancaire.",
    confirmation:
      'Pour confirmer votre adresse, nous vous demanderons de télécharger les relevés bancaires où figure votre adresse.',
    bankStatementContains:
      'Assurez-vous que votre relevé bancaire contient votre adresse',
    yourAddress: ' votre adresse',
    proceed: 'Continuer',
    dragAndDrop: 'Glisser-déposer ou ',
    chooseFile: 'choisir le fichier',
    pko: {
      header: 'PKO BP Relevé bancaire',
      confirmation:
        'Pour confirmer votre adresse, nous vous demanderons de télécharger les relevés bancaires où figure votre adresse.',
      whereToFindStatements:
        'Où trouver les relevés bancaires dans la PKO BP ?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
