export const translations = {
  pageTitle: '',
  documentTypePage: {
    header: 'Hallo, we gaan een opname maken om uw identiteit te verifiëren',
    description: 'Kies het document waarmee je jezelf gaat identificeren',
    proceed: 'Ga verder',
    selectCountry: 'Selecteer het land dat het document heeft uitgegeven',
    chooseDocument: 'Kies je documenttype',
    ResidentPermit: 'Verblijfsvergunning',
    EUResidentPermit: 'EU-verblijfsvergunning',
    TravelTitle: 'Reisetitel',
    DriverLicense: 'Rijbewijs',
    Passport: 'Paspoort',
    IdentityCard: 'Identiteitskaart',
    inputPlaceholder: 'Selecteer',
  },
  generalConditionsPage: {
    header: 'Algemene voorwaarden',
    termsAndConditions:
      'Ik accepteer de voorwaarden voor het gebruik van de service.*',
    privacyPolicy: 'Ik accepteer het privacybeleid.*',
    required: '* vereiste voorwaarden',
    proceed: 'Accepteer',
    conditionTermsHeading: 'Gebruiksvoorwaarden',
    conditionPrivacyHeading: 'Privacybeleid',
  },
  signDocumentPage: {
    header: 'Een document ondertekenen',
    checkboxLabel:
      'Ik bevestig dat ik de voorwaarden van deze overeenkomst zoals hierboven beschreven heb gelezen en begrepen.',
    proceed: 'Een document ondertekenen',
  },
  videoOnboardingPage: {
    header: 'Wat heb je nodig?',
    wellLitPlace: 'Een goed verlichte plek.',
    originalDocument: 'Je originele document, geldig en zonder omslagen.',
    wifi: 'Wi-Fi-verbinding of maximale 4G-dekking.',
    start: 'Ga verder',
  },
  documentType: {
    selectCountry: 'Selecteer het land dat het document heeft uitgegeven',
    ResidentPermit: 'Verblijfsvergunning',
    EUResidentPermit: 'EU-verblijfsvergunning',
    TravelTitle: 'Reisetitel',
    IdentityCard: 'Identiteitskaart',
  },
  otpPage: {
    verify: 'Controleer',
    header: 'Voer de verificatiecode in die naar uw telefoon is verzonden',
    headerVerifying: 'Wacht op uw verificatie.',
  },
  userDataPage: {
    header: 'Geef contactgegevens',
    proceed: 'Bevestig',
    email: 'E-mail:',
    phone: 'Telefoon:',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: 'Identiteitscontrole',
    firstParagraph:
      'Welkom bij het Signicat Identiteitsverificatieproces. Signicat, met onze dochteronderneming ElectronicID, is een door de EU gecertificeerde vertrouwde dienstverlener [https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42] en zal uw identificatie en elektronische ondertekening uitvoeren voor uw onboarding bij',
    firstParagraphPartOne:
      'Welkom bij het Signicat Identiteitsverificatieproces. Signicat, met onze dochteronderneming ElectronicID, is een door de EU gecertificeerd betrouwbaar ',
    serviceProvider: 'serviceprovider',
    firstParagraphPartTwo:
      ' en zal uw identificatie en elektronische handtekening uitvoeren voor uw onboarding.',
    secondParagraph:
      'Je identificatieproces verloopt volledig digitaal en zonder dat je met een agent hoeft te praten. Om het proces te voltooien, hebben we het volgende nodig:',
    thirdParagraph:
      'Toegang tot je online bankieren? Ja, correct. Als extra veiligheidsmaatregel zijn we door de toezichthouder verplicht om een overboeking van 0,01 EUR van je bankrekening te initiëren en te controleren of de naam op de rekening overeenkomt met je identiteit.',
    fourthParagraph:
      'Klaar om te beginnen? Geweldig, accepteer ons privacybeleid zodat we kunnen beginnen.',
    consent:
      'Ik geef toestemming voor het gebruik van mijn persoonlijke gegevens voor identificatie met Signicat Open Banking Verificatiediensten.*',
    required: '* vereiste voorwaarde',
    proceed: 'Bevestig',
    whatYouNeed: 'Wat heb je nodig?',
    wellLitPlace: 'Goede verlichting',
    originalDocument: 'Je nationale identiteitskaart of paspoort',
    stableConnection: 'Stabiele internetverbindingen',
    onlineBanking: 'Toegang tot uw online bankieren',
    cameraAccess: 'Toegang tot de camera van je apparaat',
  },
  progressPage: {
    heading: 'Identiteitscontrole',
    wait: 'Nu verifiëren we je identiteit. Meestal duurt dit minder dan 3 minuten.',
    waitMore:
      'Bedankt voor jullie geduld. Het is bijna klaar. Sluit de browser niet af.',
  },
  jitPayWelcomePage: {
    heading: 'Welkom bij het Signicat Identiteitsverificatieproces',
    descriptionPartOne:
      'Signicat, met onze dochteronderneming ElectronicID, is een door de EU gecertificeerde vertrouwde ',
    serviceProvider: 'serviceprovider',
    descriptionPartTwo:
      ' en zal uw identificatie en elektronische handtekening uitvoeren voor uw onboarding.',
    idData:
      'Ik geef toestemming voor het gebruik van mijn persoonlijke gegevens om mezelf te identificeren met de Signicat Open Banking Verificatiediensten.',
    termsAndConditions:
      'Ik accepteer de voorwaarden voor het gebruik van de service.',
    privacyPolicy: 'Ik accepteer het privacybeleid.',
    proceed: 'Bevestig',
    stepOne: 'Ondertekening',
    stepTwo: 'Identiteitscontrole',
    stepThree: '1 cent overschrijving',
    stepThreeDesc: 'Vereist door toezichthouder',
  },
  jitPayOnboardingPage: {
    heading: 'Volg deze drie stappen om het proces te voltooien:',
    signingHeader: '1. Ondertekening',
    signingContent: 'Bekijk en onderteken het contract.',
    idVerifyHeader: '2. Identiteitscontrole',
    idVerifyContent:
      'Je identificatieproces verloopt volledig digitaal en zonder dat je met een agent hoeft te praten.',
    micropaymentHeader: '3. Micropayment',
    micropaymentContent:
      '0,01 EUR overschrijving van je persoonlijke bankrekening.',
    proceed: 'Bevestig',
  },
  jitPayVideoOnboardingPage: {
    header: 'Wat je nodig hebt voor het VideoID-proces:',
    lightHeading: 'Goede verlichting',
    lightContent: 'Zorg ervoor dat je in een goed verlichte omgeving bent.',
    netHeading: 'Stabiele internetverbinding',
    netContent: 'Zorg voor een sterke internetverbinding.',
    deviceHeading: 'Toegang tot je camera-apparaat',
    deviceContent: 'Autoriseer de toegang tot uw camera-apparaat.',
    oneCameraHeading: 'Gebruik slechts één camera',
    oneCameraContent:
      'Zorg ervoor dat er slechts één camera op je desktop is aangesloten. Als je een laptop gebruikt, is de ingebouwde camera de juiste camera.',
    proceed: 'Ga verder',
  },
  micropaymentInfoJitpayPage: {
    header: 'Microbetaling vanaf je persoonlijke bankrekening',
    content:
      'Als extra veiligheidsmaatregel zijn we door de toezichthouder verplicht om een overschrijving van 0,01 EUR van je persoonlijke bankrekening te initiëren en te controleren of de naam op de rekening overeenkomt met je identiteit.',
    warning: 'Gebruik uw persoonlijke bankrekening',
    proceed: 'Bevestig',
    idData:
      'Ik geef toestemming voor het gebruik van mijn persoonlijke gegevens om mezelf te identificeren met de Signicat Open Banking Verificatiediensten.',
  },
  micropaymentInfoIkanoPage: {
    content:
      'Als extra veiligheidsmaatregel zijn we door de toezichthouder verplicht om een overschrijving van 0,01 EUR van je persoonlijke bankrekening te initiëren en te controleren of de naam op de rekening overeenkomt met je identiteit.',
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    terms:
      'I accept the terms and conditions of the video-identification process and the issuance of short- term certificates',
  },
  ikanoWelcomePage: {
    heading: 'Welkom bij het Signicat Identiteitsverificatieproces',
    descriptionPartOne:
      'Signicat, met onze dochteronderneming ElectronicID, is een door de EU gecertificeerde vertrouwde ',
    serviceProvider: 'serviceprovider',
    descriptionPartTwo:
      ' en zal uw identificatie en elektronische handtekening uitvoeren voor uw onboarding.',
    idData:
      'Ik geef toestemming voor het gebruik van mijn persoonlijke gegevens om mezelf te identificeren met de Signicat Open Banking Verificatiediensten.',
    termsAndConditions:
      'Ik accepteer de voorwaarden voor het gebruik van de service.',
    privacyPolicy: 'Ik accepteer het privacybeleid.',
    proceed: 'Bevestig',
    stepOne: 'Ondertekening',
    stepTwo: 'Identiteitscontrole',
    stepThree: '1 cent overschrijving',
    stepThreeDesc: 'Vereist door toezichthouder',
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign a document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign a document',
  },
  introKontomatikPage: {
    header: 'Adres Verificatie',
    intro:
      'In de volgende stap controleren we je adres. We vragen je om je bank te selecteren uit de lijst en in te loggen op je persoonlijke account.',
    accountInformation: 'We halen de volgende accountgegevens op:',
    names: 'Voor- en achternaam - van rekeninghouder(s)',
    address: 'Adres - van rekeninghouder(s)',
    ownership: 'Accounteigendom - eigenaar, mede-eigenaar, enz.',
    verificationPurposes:
      'Van bovenstaande gegevens gebruiken we <b>alleen je adres</b> voor verificatie.',
    proceed: 'Ga verder',
  },
  addressUploadPageKontomatik: {
    verifying: 'Controleren...',
    header: 'Adres uploaden',
    description:
      'We konden het adres niet afleiden uit je bankrekeninggegevens.',
    confirmation:
      'Om je adres te bevestigen, vragen we je om de bankafschriften met je adres te uploaden.',
    bankStatementContains: 'Zorg ervoor dat je adres op je bankafschrift staat',
    yourAddress: ' je adres',
    proceed: 'Ga verder',
    dragAndDrop: 'Slepen en neerzetten of ',
    chooseFile: 'kies bestand',
    pko: {
      header: 'PKO BP Bankafschrift',
      confirmation:
        'Om je adres te bevestigen, vragen we je om de bankafschriften met je adres te uploaden.',
      whereToFindStatements: 'Waar vind ik bankafschriften in PKO BP?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
