import { observer } from 'mobx-react'
import React, { useContext, useState } from 'react'
import { StoreContext } from '../../components/App'

import {
  Card,
  CardsContainer,
  CardsWrapper,
  DesktopBodyWrapper,
  HeadingBannerDesktop,
  HeadingBannerMobile,
  ImageBannerMobile,
  Page,
} from './LandingPage.styles'
import { Header } from '../../components/Header/Header'

import imgBanner from '../../assets/banner-image.png'
import imgBannerDesktop from '../../assets/banner-image-desktop.png'
import card1 from '../../assets/cards/card1.png'
import card2 from '../../assets/cards/card2.png'
import card3 from '../../assets/cards/card3.png'

import { Popup } from '../../components/Popup/Popup'
import { PhantomKeyLogo } from '../../components/Icons/PhantomKeyLogo'
import { PhantomKeyPopup } from '../../components/PhantomKeyPopup/PhantomKeyPopup'

export const LandingPage = observer(() => {
  const store = useContext(StoreContext)

  const [isPopupVisible, setIsPopupVisible] = useState(false)

  return (
    <Page>
      <Header />

      {/* Mobile */}
      <HeadingBannerMobile>
        <h1>
          Open an account and unlock <span>endless</span> possibilities!
        </h1>
        <div>Discover features that empower your financial journey</div>
        <button
          onClick={() => {
            setIsPopupVisible(true)
          }}
        >
          Sign up
        </button>
      </HeadingBannerMobile>

      <ImageBannerMobile>
        <h3>Explore what awaits you!</h3>
        <img src={imgBanner} alt="placeholder" />
        <h3>Endless possibilities!</h3>
      </ImageBannerMobile>

      {/* Desktop */}

      <HeadingBannerDesktop url={imgBannerDesktop}>
        <h1>
          Open an account and
          <br /> unlock <span>endless</span>
          <br />
          possibilities!
        </h1>

        <div>Discover features that empower your financial journey</div>

        <button
          onClick={() => {
            setIsPopupVisible(true)
          }}
        >
          Sign up
        </button>
      </HeadingBannerDesktop>

      <DesktopBodyWrapper>
        <CardsContainer>
          <h2>Explore what awaits you!</h2>
          <CardsWrapper>
            <Card>
              <h3>Personal account</h3>
              <img src={card2} alt="card1" />
            </Card>
            <Card>
              <h3>Corporate account</h3>
              <img src={card1} alt="card2" />
            </Card>
            <Card>
              <h3>Business account</h3>
              <img src={card3} alt="card3" />
            </Card>
          </CardsWrapper>
        </CardsContainer>
      </DesktopBodyWrapper>

      {/* Popup for both desktop and mobile */}

      <Popup visibility={isPopupVisible} handleVisibility={setIsPopupVisible}>
        <PhantomKeyPopup />
      </Popup>
    </Page>
  )
})
