import { action, makeAutoObservable } from 'mobx'
import { signicatTheme } from '../constants/signicat.theme'

interface StepInterface {
  order: number
  completed: boolean
}

export class AppStateStore {
  rootStore
  isAuthorized = false
  initialAuthCheck = false
  theme: any = signicatTheme
  pageWidth: number = 0
  pageHeight: number = 0
  userFullName: string = 'Igor Kaliciński'
  userFirstName: string = 'Igor'
  userLastName: string = 'K'
  activeCard: string = ''

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore
  }

  @action.bound setIsAuthorized(auth: boolean): void {
    this.isAuthorized = auth
  }

  @action.bound setTheme(theme): void {
    this.theme = theme
  }

  @action.bound setPageWidth(width: number): void {
    this.pageWidth = width
  }

  @action.bound setPageHeight(height: number): void {
    this.pageHeight = height
  }

  @action.bound setActiveCard(id: string): void {
    this.activeCard = id
  }
}
