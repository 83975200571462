export const translations = {
  pageTitle: '',
  documentTypePage: {
    header: 'Hei, teemme nauhoituksen henkilöllisyytesi tarkistamiseksi',
    description: 'Valitse asiakirja, johon aiot tunnistautua.',
    proceed: 'Jatka',
    selectCountry: 'Valitse asiakirjan myöntänyt maa',
    chooseDocument: 'Valitse asiakirjatyyppi',
    ResidentPermit: 'Asukaslupa',
    EUResidentPermit: 'EU:n oleskelulupa',
    TravelTitle: 'Matkustusnimike',
    DriverLicense: 'Ajokortti',
    Passport: 'Passi',
    IdentityCard: 'Henkilötodistus',
    inputPlaceholder: 'Valitse',
  },
  generalConditionsPage: {
    header: 'Yleiset ehdot',
    termsAndConditions: 'Hyväksyn palvelun käyttöehdot.*',
    privacyPolicy: 'Hyväksyn tietosuojakäytännön.*',
    required: '* vaaditut edellytykset',
    proceed: 'Hyväksy',
    conditionTermsHeading: 'Käyttöehdot ja -ehdot',
    conditionPrivacyHeading: 'Tietosuojakäytäntö',
  },
  signDocumentPage: {
    header: 'Allekirjoita asiakirja',
    checkboxLabel:
      'Vahvistan, että olen lukenut ja ymmärrän tämän sopimuksen ehdot edellä esitetyllä tavalla.',
    proceed: 'Allekirjoita asiakirja',
  },
  videoOnboardingPage: {
    header: 'Mitä tarvitset?',
    wellLitPlace: 'Hyvin valaistu paikka.',
    originalDocument:
      'Alkuperäinen asiakirjasi, joka on voimassa ja jossa ei ole suojuksia.',
    wifi: 'Wi-Fi-yhteys tai mahdollisimman hyvä 4G-peitto.',
    start: 'Jatka',
  },
  documentType: {
    selectCountry: 'Valitse asiakirjan myöntänyt maa',
    ResidentPermit: 'Asukaslupa',
    EUResidentPermit: 'EU:n oleskelulupa',
    TravelTitle: 'Matkustusnimike',
    IdentityCard: 'Henkilötodistus',
  },
  otpPage: {
    verify: 'Tarkista',
    header: 'Syötä puhelimeesi lähetetty vahvistuskoodi',
    headerVerifying: 'Odota vahvistusta.',
  },
  userDataPage: {
    header: 'Anna yhteystiedot',
    proceed: 'Vahvista',
    email: 'Sähköposti:',
    phone: 'Puhelin:',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: 'Henkilöllisyyden todentaminen',
    firstParagraph:
      'Tervetuloa Signicatin henkilöllisyyden todentamisprosessiin. Signicat on tytäryhtiönsä ElectronicID:n kanssa EU:n sertifioima luotettava palveluntarjoaja [https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42], ja suorittaa tunnistautumisenne ja sähköisen allekirjoittamisenne, jotta voitte ottaa palvelukseenne',
    firstParagraphPartOne:
      'Tervetuloa Signicatin henkilöllisyyden todentamisprosessiin. Signicat on tytäryhtiönsä ElectronicID:n kanssa EU:n sertifioima luotettava henkilöllisyyspalvelu.',
    serviceProvider: 'palveluntarjoaja',
    firstParagraphPartTwo:
      ' ja suorittaa tunnistautumisenne ja sähköisen allekirjoittamisenne palveluun ottamista varten.',
    secondParagraph:
      'Tunnistamisprosessi suoritetaan täysin digitaalisesti ja ilman, että sinun tarvitsee puhua asiamiehen kanssa. Prosessin suorittamiseksi tarvitsemme:',
    thirdParagraph:
      'Pääsetkö verkkopankkiin? Kyllä, oikein. Lisäturvatoimenpiteenä sääntelyviranomainen on velvoittanut meidät käynnistämään 0,01 euron siirron pankkitililtäsi ja tarkistamaan, että tilillä oleva nimi vastaa henkilöllisyyttäsi.\\n',
    fourthParagraph:
      'Oletko valmis lähtemään? Hienoa, hyväksy tietosuojakäytäntömme, niin voimme aloittaa.',
    consent:
      'Annan suostumukseni siihen, että henkilötietojani käytetään itseni tunnistamiseen Signicat Open Banking Verification -palveluissa.*',
    required: '* vaadittu ehto',
    proceed: 'Vahvista',
    whatYouNeed: 'Mitä tarvitset?',
    wellLitPlace: 'Hyvä valaistus',
    originalDocument: 'Kansallinen henkilötodistus tai passi',
    stableConnection: 'Vakaat internetyhteydet',
    onlineBanking: 'Pääsy verkkopankkiin',
    cameraAccess: 'Pääsy laitteen kameraan',
  },
  progressPage: {
    heading: 'Henkilöllisyyden todentaminen',
    wait: 'Nyt tarkistamme henkilöllisyytesi. Yleensä se kestää alle 3 minuuttia.',
    waitMore:
      'Kiitos kärsivällisyydestänne. Se on melkein valmis. Älä sulje selainta.',
  },
  jitPayWelcomePage: {
    heading: 'Tervetuloa Signicatin henkilöllisyyden todentamisprosessiin',
    descriptionPartOne:
      'Signicat on tytäryhtiönsä ElectronicID:n kanssa EU:n sertifioima luotettava ',
    serviceProvider: 'palveluntarjoaja',
    descriptionPartTwo:
      ' ja suorittaa tunnistautumisenne ja sähköisen allekirjoittamisenne palveluun ottamista varten.',
    idData:
      'Annan suostumukseni henkilötietojeni käyttöön itseni tunnistamiseksi Signicat Open Banking Verification -palveluissa.',
    termsAndConditions: 'Hyväksyn palvelun käyttöehdot.',
    privacyPolicy: 'Hyväksyn tietosuojakäytännön.',
    proceed: 'Vahvista',
    stepOne: 'Allekirjoitus',
    stepTwo: 'Henkilöllisyyden todentaminen',
    stepThree: '1 sentin siirto',
    stepThreeDesc: 'Sääntelyviranomaisen edellyttämä',
  },
  jitPayOnboardingPage: {
    heading:
      'Suorita prosessi loppuun noudattamalla seuraavia kolmea vaihetta:',
    signingHeader: '1. Allekirjoitus',
    signingContent: 'Tarkista ja allekirjoita sopimus.',
    idVerifyHeader: '2. Henkilöllisyyden todentaminen',
    idVerifyContent:
      'Tunnistamisprosessi suoritetaan täysin digitaalisesti ja ilman, että sinun tarvitsee puhua asiamiehen kanssa.',
    micropaymentHeader: '3. Mikromaksut',
    micropaymentContent: '0,01 EUR siirto henkilökohtaiselta pankkitililtäsi.',
    proceed: 'Vahvista',
  },
  jitPayVideoOnboardingPage: {
    header: 'Mitä tarvitset VideoID-prosessia varten:',
    lightHeading: 'Hyvä valaistus',
    lightContent: 'Varmista, että olet hyvin valaistussa ympäristössä.',
    netHeading: 'Vakaa internetyhteys',
    netContent: 'Varmista, että sinulla on vahva internetyhteys.',
    deviceHeading: 'Pääsy kameralaitteeseen',
    deviceContent: 'Hyväksy kameralaitteen käyttöoikeus.',
    oneCameraHeading: 'Käytä vain yhtä kameraa',
    oneCameraContent:
      'Varmista, että työpöytälaitteeseen on kytketty vain yksi kameralaite. Jos käytät kannettavaa tietokonetta, oikea kamera on sisäänrakennettu kamera.',
    proceed: 'Jatka',
  },
  micropaymentInfoJitpayPage: {
    header: 'Mikromaksu henkilökohtaiselta pankkitililtäsi',
    content:
      'Lisäturvatoimenpiteenä sääntelyviranomainen on velvoittanut meidät käynnistämään 0,01 euron siirron henkilökohtaiselta pankkitililtäsi ja tarkistamaan, että tilillä oleva nimi vastaa henkilöllisyyttäsi.',
    warning: 'Käytä henkilökohtaista pankkitiliäsi',
    proceed: 'Vahvista',
    idData:
      'Annan suostumukseni henkilötietojeni käyttöön itseni tunnistamiseksi Signicat Open Banking Verification -palveluissa.',
  },
  micropaymentInfoIkanoPage: {
    content:
      'Lisäturvatoimenpiteenä sääntelyviranomainen on velvoittanut meidät käynnistämään 0,01 euron siirron henkilökohtaiselta pankkitililtäsi ja tarkistamaan, että tilillä oleva nimi vastaa henkilöllisyyttäsi.',
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    terms:
      'I accept the terms and conditions of the video-identification process and the issuance of short- term certificates',
  },
  ikanoWelcomePage: {
    heading: 'Tervetuloa Signicatin henkilöllisyyden todentamisprosessiin',
    descriptionPartOne:
      'Signicat on tytäryhtiönsä ElectronicID:n kanssa EU:n sertifioima luotettava ',
    serviceProvider: 'palveluntarjoaja',
    descriptionPartTwo:
      ' ja suorittaa tunnistautumisenne ja sähköisen allekirjoittamisenne palveluun ottamista varten.',
    idData:
      'Annan suostumukseni henkilötietojeni käyttöön itseni tunnistamiseksi Signicat Open Banking Verification -palveluissa.',
    termsAndConditions: 'Hyväksyn palvelun käyttöehdot.',
    privacyPolicy: 'Hyväksyn tietosuojakäytännön.',
    proceed: 'Vahvista',
    stepOne: 'Allekirjoitus',
    stepTwo: 'Henkilöllisyyden todentaminen',
    stepThree: '1 sentin siirto',
    stepThreeDesc: 'Sääntelyviranomaisen edellyttämä',
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign a document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign a document',
  },
  introKontomatikPage: {
    header: 'Osoitteen tarkistaminen',
    intro:
      'Seuraavassa vaiheessa tarkistamme osoitteesi. Pyydämme sinua valitsemaan pankkisi luettelosta ja kirjautumaan henkilökohtaiselle tilillesi.',
    accountInformation: 'Haemme seuraavat tilitiedot:',
    names: 'Tilin omistajan (omistajien) etu- ja sukunimi.',
    address: 'Osoite - tilin omistaja(t)',
    ownership: 'Tilin omistus - omistaja, osaomistaja jne.',
    verificationPurposes:
      'Yllä olevista tiedoista käytämme <b>vain osoitettasi</b> todentamiseen.',
    proceed: 'Jatka',
  },
  addressUploadPageKontomatik: {
    verifying: 'Tarkistaminen...',
    header: 'Osoitteen lataaminen',
    description: 'Emme pystyneet hakemaan osoitetta pankkitilitiedoistasi.',
    confirmation:
      'Osoitteesi vahvistamiseksi pyydämme sinua lataamaan tiliotteet, jotka sisältävät osoitteesi.',
    bankStatementContains:
      'Varmista, että pankkitiliotteesi sisältää osoitteesi',
    yourAddress: ' osoitteesi',
    proceed: 'Jatka',
    dragAndDrop: 'Vedä & pudota tai ',
    chooseFile: 'valitse tiedosto',
    pko: {
      header: 'PKO BP Pankin tiliote',
      confirmation:
        'Osoitteesi vahvistamiseksi pyydämme sinua lataamaan tiliotteet, jotka sisältävät osoitteesi.',
      whereToFindStatements: 'Mistä löydän tiliotteet PKO BP:ssä?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
