export const translations = {
  pageTitle: '',
  documentTypePage: {
    header:
      'Γεια σας, θα κάνουμε μια ηχογράφηση για να επαληθεύσουμε την ταυτότητά σας.',
    description: 'Επιλέξτε το έγγραφο με το οποίο θα ταυτιστείτε',
    proceed: 'Συνεχίστε',
    selectCountry: 'Επιλέξτε τη χώρα που εξέδωσε το έγγραφο',
    chooseDocument: 'Επιλέξτε τον τύπο του εγγράφου σας',
    ResidentPermit: 'Άδεια κατοίκου',
    EUResidentPermit: 'Άδεια διαμονής στην ΕΕ',
    TravelTitle: 'Τίτλος ταξιδιού',
    DriverLicense: 'Άδεια οδήγησης',
    Passport: 'Διαβατήριο',
    IdentityCard: 'Δελτίο ταυτότητας',
    inputPlaceholder: 'Επιλέξτε',
  },
  generalConditionsPage: {
    header: 'Γενικοί όροι',
    termsAndConditions:
      'Αποδέχομαι τους όρους και τις προϋποθέσεις χρήσης της υπηρεσίας.*',
    privacyPolicy: 'Αποδέχομαι την πολιτική απορρήτου.*',
    required: '* απαιτούμενες προϋποθέσεις',
    proceed: 'Αποδοχή',
    conditionTermsHeading: 'Όροι και προϋποθέσεις χρήσης',
    conditionPrivacyHeading: 'Πολιτική απορρήτου',
  },
  signDocumentPage: {
    header: 'Υπογράψτε ένα έγγραφο',
    checkboxLabel:
      'Αναγνωρίζω ότι έχω διαβάσει και κατανοήσει τους όρους της παρούσας συμφωνίας όπως περιγράφονται λεπτομερώς παραπάνω.',
    proceed: 'Υπογράψτε ένα έγγραφο',
  },
  videoOnboardingPage: {
    header: 'Τι χρειάζεστε;',
    wellLitPlace: 'Ένα καλά φωτισμένο μέρος.',
    originalDocument: 'Το πρωτότυπο έγγραφό σας, έγκυρο και χωρίς καλύμματα.',
    wifi: 'Σύνδεση Wi-Fi ή μέγιστη κάλυψη 4G.',
    start: 'Συνεχίστε',
  },
  documentType: {
    selectCountry: 'Επιλέξτε τη χώρα που εξέδωσε το έγγραφο',
    ResidentPermit: 'Άδεια κατοίκου',
    EUResidentPermit: 'Άδεια διαμονής στην ΕΕ',
    TravelTitle: 'Τίτλος ταξιδιού',
    IdentityCard: 'Δελτίο ταυτότητας',
  },
  otpPage: {
    verify: 'Επαλήθευση',
    header: 'Εισάγετε τον κωδικό επαλήθευσης που αποστέλλεται στο τηλέφωνό σας',
    headerVerifying: 'Παρακαλούμε περιμένετε για την επαλήθευση.',
  },
  userDataPage: {
    header: 'Παρακαλείστε να δώσετε στοιχεία επικοινωνίας',
    proceed: 'Επιβεβαίωση',
    email: 'E-mail:',
    phone: 'Τηλέφωνο:',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: 'Επαλήθευση ταυτότητας',
    firstParagraph:
      'Καλώς ήρθατε στη διαδικασία επαλήθευσης ταυτότητας της Signicat. Η Signicat, με τη θυγατρική της ElectronicID, είναι πιστοποιημένος από την ΕΕ πάροχος αξιόπιστων υπηρεσιών [https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42] και θα εκτελεί την ταυτοποίηση και την ηλεκτρονική υπογραφή σας για την ένταξή σας στην υπηρεσία',
    firstParagraphPartOne:
      'Καλώς ήρθατε στη διαδικασία επαλήθευσης ταυτότητας της Signicat. Η Signicat, με τη θυγατρική μας ElectronicID, είναι πιστοποιημένη από την ΕΕ αξιόπιστη',
    serviceProvider: 'πάροχος υπηρεσιών',
    firstParagraphPartTwo:
      ' και θα εκτελεί την ταυτοποίησή σας και την ηλεκτρονική υπογραφή για την ένταξή σας.',
    secondParagraph:
      'Η διαδικασία ταυτοποίησής σας θα γίνεται εντελώς ψηφιακά και χωρίς να χρειάζεται να μιλήσετε με κάποιον πράκτορα. Για την ολοκλήρωση της διαδικασίας, θα χρειαστούμε:',
    thirdParagraph:
      'Πρόσβαση στην ηλεκτρονική σας τραπεζική; Ναι, σωστά. Ως πρόσθετο μέτρο ασφαλείας, είμαστε υποχρεωμένοι από τη ρυθμιστική αρχή να ξεκινήσουμε μια μεταφορά 0,01 ευρώ από τον τραπεζικό σας λογαριασμό και να ελέγξουμε αν το όνομα στον λογαριασμό ταιριάζει με την ταυτότητά σας.\\n',
    fourthParagraph:
      'Έτοιμοι να φύγετε; Υπέροχα, παρακαλούμε αποδεχτείτε την πολιτική απορρήτου μας για να ξεκινήσουμε.',
    consent:
      'Συναινώ στη χρήση των προσωπικών μου δεδομένων για την ταυτοποίησή μου με τις υπηρεσίες επαλήθευσης Signicat Open Banking*.',
    required: '* απαιτούμενη συνθήκη',
    proceed: 'Επιβεβαίωση',
    whatYouNeed: 'Τι χρειάζεστε;',
    wellLitPlace: 'Καλός φωτισμός',
    originalDocument: 'Την εθνική σας ταυτότητα ή το διαβατήριό σας',
    stableConnection: 'Σταθερές συνδέσεις στο διαδίκτυο',
    onlineBanking: 'Πρόσβαση στην ηλεκτρονική σας τραπεζική',
    cameraAccess: 'Πρόσβαση στην κάμερα της συσκευής σας',
  },
  progressPage: {
    heading: 'Επαλήθευση ταυτότητας',
    wait: 'Τώρα επαληθεύουμε την ταυτότητά σας. Συνήθως, διαρκεί λιγότερο από 3 λεπτά.',
    waitMore:
      'Ευχαριστώ για την υπομονή σας. Σχεδόν τελείωσε. Παρακαλώ μην κλείσετε το πρόγραμμα περιήγησης.',
  },
  jitPayWelcomePage: {
    heading: 'Καλώς ήρθατε στη Διαδικασία Επαλήθευσης Ταυτότητας της Signicat',
    descriptionPartOne:
      'Η Signicat, με τη θυγατρική μας ElectronicID, είναι πιστοποιημένο από την ΕΕ αξιόπιστο ',
    serviceProvider: 'πάροχος υπηρεσιών',
    descriptionPartTwo:
      ' και θα εκτελεί την ταυτοποίησή σας και την ηλεκτρονική υπογραφή για την ένταξή σας.',
    idData:
      'Συναινώ στη χρήση των προσωπικών μου δεδομένων για την ταυτοποίησή μου με τις υπηρεσίες επαλήθευσης Signicat Open Banking.',
    termsAndConditions:
      'Αποδέχομαι τους όρους και τις προϋποθέσεις χρήσης της υπηρεσίας.*',
    privacyPolicy: 'Αποδέχομαι την πολιτική απορρήτου.*',
    proceed: 'Επιβεβαίωση',
    stepOne: 'Υπογραφή',
    stepTwo: 'Επαλήθευση ταυτότητας',
    stepThree: 'Μεταφορά 1 σεντ',
    stepThreeDesc: 'Απαιτείται από τη ρυθμιστική αρχή',
  },
  jitPayOnboardingPage: {
    heading:
      'Για να ολοκληρώσετε τη διαδικασία, ακολουθήστε τα εξής τρία βήματα:',
    signingHeader: '1. Υπογραφή',
    signingContent: 'Ελέγξτε και υπογράψτε τη σύμβαση.',
    idVerifyHeader: '2. Επαλήθευση ταυτότητας',
    idVerifyContent:
      'Η διαδικασία ταυτοποίησής σας θα γίνεται εντελώς ψηφιακά και χωρίς να χρειάζεται να μιλήσετε με κάποιον πράκτορα.',
    micropaymentHeader: '3. Μικροπληρωμή',
    micropaymentContent: '0,01 EUR από τον προσωπικό σας τραπεζικό λογαριασμό.',
    proceed: 'Επιβεβαίωση',
  },
  jitPayVideoOnboardingPage: {
    header: 'Τι θα χρειαστείτε Για τη διαδικασία VideoID:',
    lightHeading: 'Καλός φωτισμός',
    lightContent: 'Βεβαιωθείτε ότι βρίσκεστε σε καλά φωτισμένο περιβάλλον.',
    netHeading: 'Σταθερή σύνδεση στο διαδίκτυο',
    netContent: 'Βεβαιωθείτε ότι έχετε ισχυρή σύνδεση στο διαδίκτυο.',
    deviceHeading: 'Πρόσβαση στη συσκευή κάμεράς σας',
    deviceContent: 'Εξουσιοδοτήστε την πρόσβαση στη συσκευή της κάμεράς σας.',
    oneCameraHeading: 'Χρησιμοποιήστε μόνο μία κάμερα',
    oneCameraContent:
      'Βεβαιωθείτε ότι έχετε συνδέσει μόνο μία συσκευή κάμερας στην επιτραπέζια συσκευή σας. Εάν χρησιμοποιείτε φορητό υπολογιστή, η ενσωματωμένη κάμερα είναι η σωστή κάμερα που πρέπει να χρησιμοποιήσετε.',
    proceed: 'Συνεχίστε',
  },
  micropaymentInfoJitpayPage: {
    header: 'Μικροπληρωμή από τον προσωπικό σας τραπεζικό λογαριασμό',
    content:
      'Ως πρόσθετο μέτρο ασφαλείας, είμαστε υποχρεωμένοι από τη ρυθμιστική αρχή να ξεκινήσουμε μια μεταφορά 0,01 ευρώ από τον προσωπικό σας τραπεζικό λογαριασμό και να ελέγξουμε αν το όνομα στο λογαριασμό ταιριάζει με την ταυτότητά σας.',
    warning: 'Χρησιμοποιήστε τον προσωπικό σας τραπεζικό λογαριασμό',
    proceed: 'Επιβεβαίωση',
    idData:
      'Συναινώ στη χρήση των προσωπικών μου δεδομένων για την ταυτοποίησή μου με τις υπηρεσίες επαλήθευσης Signicat Open Banking.',
  },
  micropaymentInfoIkanoPage: {
    content:
      'Ως πρόσθετο μέτρο ασφαλείας, είμαστε υποχρεωμένοι από τη ρυθμιστική αρχή να ξεκινήσουμε μια μεταφορά 0,01 ευρώ από τον προσωπικό σας τραπεζικό λογαριασμό και να ελέγξουμε αν το όνομα στο λογαριασμό ταιριάζει με την ταυτότητά σας.',
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    terms:
      'I accept the terms and conditions of the video-identification process and the issuance of short- term certificates',
  },
  ikanoWelcomePage: {
    heading: 'Καλώς ήρθατε στη Διαδικασία Επαλήθευσης Ταυτότητας της Signicat',
    descriptionPartOne:
      'Η Signicat, με τη θυγατρική μας ElectronicID, είναι πιστοποιημένο από την ΕΕ αξιόπιστο ',
    serviceProvider: 'πάροχος υπηρεσιών',
    descriptionPartTwo:
      ' και θα εκτελεί την ταυτοποίησή σας και την ηλεκτρονική υπογραφή για την ένταξή σας.',
    idData:
      'Συναινώ στη χρήση των προσωπικών μου δεδομένων για την ταυτοποίησή μου με τις υπηρεσίες επαλήθευσης Signicat Open Banking.',
    termsAndConditions:
      'Αποδέχομαι τους όρους και τις προϋποθέσεις χρήσης της υπηρεσίας.*',
    privacyPolicy: 'Αποδέχομαι την πολιτική απορρήτου.*',
    proceed: 'Επιβεβαίωση',
    stepOne: 'Υπογραφή',
    stepTwo: 'Επαλήθευση ταυτότητας',
    stepThree: 'Μεταφορά 1 σεντ',
    stepThreeDesc: 'Απαιτείται από τη ρυθμιστική αρχή',
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign a document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign a document',
  },
  introKontomatikPage: {
    header: 'Επαλήθευση διεύθυνσης',
    intro:
      'Στο επόμενο βήμα, θα επαληθεύσουμε τη διεύθυνσή σας. Θα σας ζητήσουμε να επιλέξετε την τράπεζά σας από τη λίστα και να συνδεθείτε στον προσωπικό σας λογαριασμό.',
    accountInformation: 'Θα λάβουμε τις ακόλουθες πληροφορίες λογαριασμού:',
    names: 'Όνομα και επώνυμο - του/των ιδιοκτήτη(ων) του λογαριασμού',
    address: 'Διεύθυνση - του(των) ιδιοκτήτη(ων) του λογαριασμού',
    ownership: 'Ιδιοκτησία λογαριασμού - ιδιοκτήτης, συνιδιοκτήτης κ.λπ.',
    verificationPurposes:
      'Από τα παραπάνω δεδομένα θα χρησιμοποιήσουμε <b>μόνο τη διεύθυνσή σας</b> για σκοπούς επαλήθευσης.',
    proceed: 'Συνεχίστε',
  },
  addressUploadPageKontomatik: {
    verifying: 'Επαλήθευση...',
    header: 'Ανέβασμα διεύθυνσης',
    description:
      'Δεν μπορέσαμε να αντλήσουμε τη διεύθυνση από τα στοιχεία του τραπεζικού σας λογαριασμού.',
    confirmation:
      'Για να επιβεβαιώσουμε τη διεύθυνσή σας, θα σας ζητήσουμε να ανεβάσετε τις τραπεζικές βεβαιώσεις που περιέχουν τη διεύθυνσή σας.',
    bankStatementContains:
      'Βεβαιωθείτε ότι η τραπεζική σας δήλωση περιέχει τη διεύθυνσή σας',
    yourAddress: ' τη διεύθυνσή σας',
    proceed: 'Συνεχίστε',
    dragAndDrop: 'Drag & Drop ή ',
    chooseFile: 'επιλέξτε αρχείο',
    pko: {
      header: 'PKO BP Τραπεζική δήλωση',
      confirmation:
        'Για να επιβεβαιώσουμε τη διεύθυνσή σας, θα σας ζητήσουμε να ανεβάσετε τις τραπεζικές βεβαιώσεις που περιέχουν τη διεύθυνσή σας.',
      whereToFindStatements:
        'Πού μπορώ να βρω τις τραπεζικές καταστάσεις στην PKO BP?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
