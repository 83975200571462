import React from 'react'
import styled from 'styled-components'
import { tablet } from '../../constants/breakpoints'

const Container = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 0 30px;

  @media (max-width: calc(${tablet})) {
    display: none;
  }
`
const TextLogo = styled.div`
  color: #000;
  font-family: 'Yeseva One';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  span {
    color: #0e83ef;
    font-family: 'Yeseva One';
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

const LeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 56px;
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 40px;

  a {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.5px;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus,
    &:visited,
    &:link {
      color: #000;
      text-decoration: none;
    }
  }
`

const LoginButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;

  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.5px;
`

export const Header = () => {
  return (
    <Container>
      <LeftSide>
        <TextLogo>
          Nexus<span>Financial</span>
        </TextLogo>
        <Nav>
          <a href="#">Cards</a>
          <a href="#">Business account</a>
          <a href="#">Corporate account</a>
          <a href="#">Insurance</a>
          <a href="#">About us</a>
          <a href="#">Contact</a>
        </Nav>
      </LeftSide>

      <LoginButton>Log in</LoginButton>
    </Container>
  )
}
