import React, { useContext, useState } from 'react'
import { StoreContext } from '../../components/App'
import {
  BurgerButton,
  Container,
  FloatingMenu,
  LogoutButton,
  MenuLogo,
  UlContainer,
} from './MobileNavMenu.styles'

import { LogoutIcon } from '../Icons/LogoutIcon'

interface MobileNavMenuItem {
  name: string // needs to be unique
  label: string
  urls: string[]
  icon?: string
}

interface MobileNavMenuProps {
  items: MobileNavMenuItem[]
  margin?: string
}

export const MobileNavMenu = ({ items }: MobileNavMenuProps) => {
  const store = useContext(StoreContext)
  const { userFullName, userFirstName, userLastName } = store.AppState

  const [isOpen, setIsOpen] = useState(false)
  const [display, setDisplay] = useState('none')

  const toggleMenu = () => {
    if (isOpen) {
      setIsOpen(!isOpen)

      setTimeout(() => {
        setDisplay('none')
      }, 300)
    } else {
      setDisplay('flex')

      setTimeout(() => {
        setIsOpen(!isOpen)
      }, 10)
    }
  }

  const logout = () => {}

  return (
    <>
      <Container open={isOpen} display={display}>
        <UlContainer>
          <li>
            <a href="#">Cards</a>
          </li>
          <li>
            <a href="#">Business account</a>
          </li>
          <li>
            <a href="#">Corporate account</a>
          </li>
          <li>
            <a href="#">Insurance</a>
          </li>
          <li>
            <a href="#">About us</a>
          </li>
          <li>
            <a href="#">Contact</a>
          </li>
        </UlContainer>

        <LogoutButton onClick={() => logout()}>
          <LogoutIcon />
          Log out
        </LogoutButton>
      </Container>

      <FloatingMenu>
        <MenuLogo>
          Sample<span>Bank</span>
        </MenuLogo>
        <BurgerButton onClick={toggleMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
          >
            <rect width="40" height="40" fill="#CCD0DD" />
            <path
              d="M25.3141 13.6569C20.8958 13.6569 18.4187 13.6569 14.0004 13.6569"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              d="M25.3141 19.9703C20.8958 19.9703 18.4187 19.9703 14.0004 19.9703"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              d="M25.3141 26.2838C20.8958 26.2838 18.4187 26.2838 14.0004 26.2838"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
            />
          </svg>
        </BurgerButton>
      </FloatingMenu>
    </>
  )
}
