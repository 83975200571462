export const translations = {
  pageTitle: '',
  completedPage: {
    heading: 'Afsluttet',
    description: 'Identifikationsprocessen er blevet udført',
  },
  documentTypePage: {
    header: 'Hej, vi optager for at bekræfte din identitet.',
    description: 'Vælg det dokument, som du vil identificere dig med',
    proceed: 'Fortsæt',
    selectCountry: 'Vælg det land, der har udstedt dokumentet',
    chooseDocument: 'Vælg din dokumenttype',
    ResidentPermit: 'Opholdstilladelse',
    EUResidentPermit: 'EU-opholdstilladelse',
    TravelTitle: 'Titel på rejse',
    DriverLicense: 'Kørekort',
    Passport: 'Pas',
    IdentityCard: 'Identitetskort',
    inputPlaceholder: 'Vælg',
  },
  generalConditionsPage: {
    header: 'Generelle betingelser',
    termsAndConditions:
      'Jeg accepterer vilkår og betingelser for brug af tjenesten*.',
    privacyPolicy: 'Jeg accepterer privatlivspolitikken.',
    required: '* krævede betingelser',
    proceed: 'Accepter',
    conditionTermsHeading: 'Vilkår og betingelser for brug',
    conditionPrivacyHeading: 'Privatlivspolitik',
  },
  signDocumentPage: {
    header: 'Underskriv et dokument',
    checkboxLabel:
      'Jeg bekræfter, at jeg har læst og forstået vilkårene i denne aftale som beskrevet ovenfor.',
    proceed: 'Underskriv et dokument',
  },
  videoOnboardingPage: {
    header: 'Hvad har du brug for?',
    wellLitPlace: 'Et godt oplyst sted.',
    originalDocument: 'Dit originale dokument, gyldigt og uden omslag.',
    wifi: 'Wi-Fi-forbindelse eller maksimal 4G-dækning.',
    start: 'Fortsæt',
  },
  videoIdPage: {
    proceed: 'Fortsæt',
  },
  mobileCodePage: {
    heading: 'Indtast bekræftelseskode sendt til din telefon',
    headingVerifying: 'Vent venligst på din bekræftelse',
  },
  documentType: {
    selectCountry: 'Vælg det land, der har udstedt dokumentet',
    ResidentPermit: 'Opholdstilladelse',
    EUResidentPermit: 'EU-opholdstilladelse',
    TravelTitle: 'Titel på rejse',
    IdentityCard: 'Identitetskort',
  },
  otpPage: {
    verify: 'Bekræft',
    header: 'Indtast bekræftelseskode sendt til din telefon',
    headerVerifying: 'Vent venligst på din bekræftelse.',
  },
  userDataPage: {
    header: 'Angiv venligst kontaktoplysninger',
    proceed: 'Bekræft',
    email: 'E-mail:',
    phone: 'Telefon:',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: 'Identitetsbekræftelse',
    firstParagraph:
      'Velkommen til Signicat Identity Verification Process. Signicat, med vores datterselskab ElectronicID, er en EU-certificeret pålidelig tjenesteudbyder [https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42] og vil udføre din identifikation og elektroniske signering til din onboarding hos',
    firstParagraphPartOne:
      'Velkommen til Signicat Identity Verification Process. Signicat, med vores datterselskab ElectronicID, er en EU-certificeret, pålidelig ',
    serviceProvider: 'tjenesteudbyder',
    firstParagraphPartTwo:
      ' og vil udføre din identifikation og elektroniske underskrift til din onboarding.',
    secondParagraph:
      'Din identifikationsproces vil blive udført helt digitalt og uden behov for at tale med en agent. For at gennemføre processen skal vi bruge:',
    thirdParagraph:
      'Adgang til din netbank? Ja, det er korrekt. Som en ekstra sikkerhedsforanstaltning er vi forpligtet af myndighederne til at starte en overførsel på 0,01 EUR fra din bankkonto og kontrollere, om navnet på kontoen stemmer overens med din identitet.',
    fourthParagraph:
      'Er du klar til at gå i gang? Godt, accepter venligst vores privatlivspolitik, så vi kan komme i gang.',
    consent:
      'Jeg accepterer brugen af mine personlige data til at identificere mig selv med Signicat Open Banking Verification-tjenester.',
    required: '* påkrævet betingelse',
    proceed: 'Bekræft',
    whatYouNeed: 'Hvad har du brug for?',
    wellLitPlace: 'God belysning',
    originalDocument: 'Dit nationale ID eller pas',
    stableConnection: 'Stabile internetforbindelser',
    onlineBanking: 'Adgang til din netbank',
    cameraAccess: 'Adgang til din enheds kamera',
  },
  progressPage: {
    heading: 'Identitetsbekræftelse',
    wait: 'Nu verificerer vi din identitet. Det tager normalt mindre end 3 minutter.',
    waitMore:
      'Tak for jeres tålmodighed. Det er næsten færdigt. Luk venligst ikke browseren.',
  },
  jitPayWelcomePage: {
    heading: 'Velkommen til Signicats identitetsbekræftelsesproces',
    descriptionPartOne:
      'Signicat, med vores datterselskab ElectronicID, er en EU-certificeret trusted ',
    serviceProvider: 'tjenesteudbyder',
    descriptionPartTwo:
      ' og vil udføre din identifikation og elektroniske underskrift til din onboarding.',
    idData:
      'Jeg accepterer brugen af mine personlige data til at identificere mig selv med Signicat Open Banking Verification-tjenester.',
    termsAndConditions:
      'Jeg accepterer vilkår og betingelser for brug af tjenesten.',
    privacyPolicy: 'Jeg accepterer privatlivspolitikken.',
    proceed: 'Bekræft',
    stepOne: 'Signering',
    stepTwo: 'Identitetsbekræftelse',
    stepThree: '1 cent overførsel',
    stepThreeDesc: 'Påkrævet af tilsynsmyndigheden',
  },
  jitPayOnboardingPage: {
    heading: 'Følg disse tre trin for at afslutte processen:',
    signingHeader: '1. Signering',
    signingContent: 'Gennemgå og underskriv kontrakten.',
    idVerifyHeader: '2. Identitetsbekræftelse',
    idVerifyContent:
      'Din identifikationsproces vil foregå helt digitalt og uden behov for at tale med en agent.',
    micropaymentHeader: '3. Mikrobetaling',
    micropaymentContent: '0,01 EUR overførsel fra din personlige bankkonto.',
    proceed: 'Bekræft',
  },
  jitPayVideoOnboardingPage: {
    header: 'Det skal du bruge til VideoID-processen:',
    lightHeading: 'God belysning',
    lightContent: 'Sørg for, at du befinder dig i et godt oplyst miljø.',
    netHeading: 'Stabil internetforbindelse',
    netContent: 'Sørg for, at du har en stærk internetforbindelse.',
    deviceHeading: 'Adgang til din kameraenhed',
    deviceContent: 'Godkend adgang til din kameraenhed.',
    oneCameraHeading: 'Brug kun ét kamera',
    oneCameraContent:
      'Sørg for, at der kun er tilsluttet ét kamera til din stationære enhed. Hvis du bruger en bærbar computer, er det indbyggede kamera det rigtige kamera at bruge.',
    proceed: 'Fortsæt',
  },
  micropaymentInfoJitpayPage: {
    header: 'Mikrobetaling fra din personlige bankkonto',
    content:
      'Som en ekstra sikkerhedsforanstaltning er vi forpligtet af myndighederne til at starte en overførsel på 0,01 EUR fra din personlige bankkonto og kontrollere, om navnet på kontoen stemmer overens med din identitet.',
    warning: 'Brug venligst din personlige bankkonto',
    proceed: 'Bekræft',
    idData:
      'Jeg accepterer brugen af mine personlige data til at identificere mig selv med Signicat Open Banking Verification-tjenester.',
  },
  micropaymentInfoIkanoPage: {
    content:
      'Som en ekstra sikkerhedsforanstaltning er vi forpligtet af myndighederne til at starte en overførsel på 0,01 EUR fra din personlige bankkonto og kontrollere, om navnet på kontoen stemmer overens med din identitet.',
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    terms:
      'I accept the terms and conditions of the video-identification process and the issuance of short- term certificates',
  },
  ikanoWelcomePage: {
    heading: 'Velkommen til Signicats identitetsbekræftelsesproces',
    descriptionPartOne:
      'Signicat, med vores datterselskab ElectronicID, er en EU-certificeret trusted ',
    serviceProvider: 'tjenesteudbyder',
    descriptionPartTwo:
      ' og vil udføre din identifikation og elektroniske underskrift til din onboarding.',
    idData:
      'Jeg accepterer brugen af mine personlige data til at identificere mig selv med Signicat Open Banking Verification-tjenester.',
    termsAndConditions:
      'Jeg accepterer vilkår og betingelser for brug af tjenesten.',
    privacyPolicy: 'Jeg accepterer privatlivspolitikken.',
    proceed: 'Bekræft',
    stepOne: 'Signering',
    stepTwo: 'Identitetsbekræftelse',
    stepThree: '1 cent overførsel',
    stepThreeDesc: 'Påkrævet af tilsynsmyndigheden',
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign a document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign a document',
  },
  introKontomatikPage: {
    header: 'Bekræftelse af adresse',
    intro:
      'I det næste trin verificerer vi din adresse. Vi vil bede dig om at vælge din bank fra listen og logge ind på din personlige konto.',
    accountInformation: 'Vi henter følgende kontooplysninger:',
    names: 'For- og efternavn - på kontoejer(e)',
    address: 'Adresse - på kontoejer(e)',
    ownership: 'Kontoejerskab - ejer, medejer osv.',
    verificationPurposes:
      'Ud fra ovenstående data vil vi kun bruge <b>din adresse</b> til verificeringsformål.',
    proceed: 'Fortsæt',
  },
  addressUploadPageKontomatik: {
    verifying: 'Bekræfter...',
    header: 'Upload af adresse',
    description: 'Vi kunne ikke hente adressen fra dine bankkontooplysninger.',
    confirmation:
      'For at bekræfte din adresse vil vi bede dig om at uploade de kontoudtog, der indeholder din adresse.',
    bankStatementContains: 'Sørg for, at dit kontoudtog indeholder din adresse',
    yourAddress: ' din adresse',
    proceed: 'Fortsæt',
    dragAndDrop: 'Træk og slip eller ',
    chooseFile: 'Vælg fil',
    pko: {
      header: 'PKO BP Kontoudtog',
      confirmation:
        'For at bekræfte din adresse vil vi bede dig om at uploade de kontoudtog, der indeholder din adresse.',
      whereToFindStatements: 'Hvor finder man kontoudtog i PKO BP?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
