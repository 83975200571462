import React from 'react'
import styled from 'styled-components'

interface ContainerProps {
  width?: string
  padding?: string
}

export const Container = styled.div<ContainerProps>`
  width: ${(props) => (props.width ? props.width : '')};
  padding: ${(props) => (props.padding ? props.padding : '')};

  svg {
    width: 100%;
    height: auto;
  }
`

interface NBankIDLogoProps {
  color?: string
  width?: string
  padding?: string
}

export const NBankIDLogo = ({ color, width, padding }: NBankIDLogoProps) => {
  const svgColor = color ? color : '#39134C'

  return (
    <Container
      width={width ? width : '110px'}
      padding={padding ? padding : '0'}
    >
      <svg
        width="140"
        height="21"
        viewBox="0 0 140 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="BankID_Main_Logo 1" clipPath="url(#clip0_691_17062)">
          <g id="Group">
            <g id="Group_2">
              <path
                id="Vector"
                d="M7.5 3H1.5C0.7 3 0 2.3 0 1.5C0 0.7 0.7 0 1.5 0H7.5C8.3 0 9 0.7 9 1.5C9 2.3 8.3 3 7.5 3Z"
                fill={svgColor}
              />
              <path
                id="Vector_2"
                d="M7.5 15H1.5C0.7 15 0 14.3 0 13.5C0 12.7 0.7 12 1.5 12H7.5C8.3 12 9 12.7 9 13.5C9 14.3 8.3 15 7.5 15Z"
                fill={svgColor}
              />
              <path
                id="Vector_3"
                d="M7.5 21H1.5C0.7 21 0 20.3 0 19.5C0 18.7 0.7 18 1.5 18H7.5C8.3 18 9 18.7 9 19.5C9 20.3 8.3 21 7.5 21Z"
                fill={svgColor}
              />
              <path
                id="Vector_4"
                d="M19.5 9H13.5C12.7 9 12 8.3 12 7.5C12 6.7 12.7 6 13.5 6H19.5C20.3 6 21 6.7 21 7.5C21 8.3 20.3 9 19.5 9Z"
                fill={svgColor}
              />
              <path
                id="Vector_5"
                d="M19.5 15H13.5C12.7 15 12 14.3 12 13.5C12 12.7 12.7 12 13.5 12H19.5C20.3 12 21 12.7 21 13.5C21 14.3 20.3 15 19.5 15Z"
                fill={svgColor}
              />
              <path
                id="Vector_6"
                d="M31.5 3H25.5C24.7 3 24 2.3 24 1.5C24 0.7 24.7 0 25.5 0H31.5C32.3 0 33 0.7 33 1.5C33 2.3 32.3 3 31.5 3Z"
                fill={svgColor}
              />
              <path
                id="Vector_7"
                d="M31.5 9H25.5C24.7 9 24 8.3 24 7.5C24 6.7 24.7 6 25.5 6H31.5C32.3 6 33 6.7 33 7.5C33 8.3 32.3 9 31.5 9Z"
                fill={svgColor}
              />
              <path
                id="Vector_8"
                d="M31.5 21H25.5C24.7 21 24 20.3 24 19.5C24 18.7 24.7 18 25.5 18H31.5C32.3 18 33 18.7 33 19.5C33 20.3 32.3 21 31.5 21Z"
                fill={svgColor}
              />
            </g>
            <g id="Group_3">
              <path
                id="Vector_9"
                d="M45.8 13.1C45.8 16.3 47.6 18 49.7 18C51.6 18 53.7 16.4 53.7 13.1C53.7 9.8 51.7 8.3 49.8 8.3C47.7 8.3 45.8 9.8 45.8 13.1ZM45.8 0V7.8C46.7 6.4 48.3 5 51 5C54 5 57.6 7.5 57.6 13C57.6 18.7 54.2 21.5 50.7 21.5C48.7 21.5 47 20.6 45.9 18.8V21H42V0H45.8Z"
                fill={svgColor}
              />
              <path
                id="Vector_10"
                d="M63.3 13.2C63.3 16.5 65.3 18 67.2 18C69.3 18 71.1 16.6 71.1 13.2C71.1 9.89999 69.3 8.29999 67.2 8.29999C65.4 8.29999 63.3 9.79999 63.3 13.2ZM59.5 13C59.5 7.59999 63 4.89999 66.5 4.89999C68.4 4.89999 70 5.59999 71.2 7.29999V5.29999H75V21H71.2V18.8C70.1 20.3 68.5 21.4 66 21.4C63 21.4 59.5 18.8 59.5 13Z"
                fill={svgColor}
              />
              <path
                id="Vector_11"
                d="M82.5 12.1V21H78.7V5.4H82.5V7.7C83.6 5.7 85.6 5 87.3 5C90 5 92.6 6.4 92.6 10.6V21H88.8V12C88.8 9.4 87.5 8.4 85.8 8.4C83.9 8.5 82.5 9.6 82.5 12.1Z"
                fill={svgColor}
              />
              <path
                id="Vector_12"
                d="M96.2 0V21H100V17.2L102.1 14.6L106.4 21H110.6L104.4 11.9L109.8 5.4H105.5L100 12.6V0H96.2Z"
                fill={svgColor}
              />
              <path
                id="Vector_13"
                d="M118.2 0.700012H114.3V21H118.2V0.700012Z"
                fill={svgColor}
              />
              <path
                id="Vector_14"
                d="M129.5 0.700012H122.1V21H129.5C135 21 139.6 17.7 139.6 10.8C139.6 4.00001 135 0.700012 129.5 0.700012ZM129.5 17.4H126V4.30001H129.5C132.7 4.30001 135.4 6.40001 135.4 10.9C135.4 15.3 132.7 17.4 129.5 17.4Z"
                fill={svgColor}
              />
            </g>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_691_17062">
            <rect width="140" height="21" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Container>
  )
}
