export const translations = {
  pageTitle: '',
  documentTypePage: {
    header: 'Dobrý den, pořídíme nahrávku k ověření vaší totožnosti.',
    description: 'Vyberte si dokument, se kterým se budete identifikovat.',
    proceed: 'Pokračovat',
    selectCountry: 'Vyberte zemi, která doklad vydala',
    chooseDocument: 'Vyberte si typ dokumentu',
    ResidentPermit: 'Povolení pro rezidenty',
    EUResidentPermit: 'Povolení pro rezidenty EU',
    TravelTitle: 'Cestovní titul',
    DriverLicense: 'Řidičský průkaz',
    Passport: 'Pas',
    IdentityCard: 'Průkaz totožnosti',
    inputPlaceholder: 'Vyberte',
  },
  generalConditionsPage: {
    header: 'Obecné podmínky',
    termsAndConditions: 'Souhlasím s podmínkami používání služby.*',
    privacyPolicy: 'Souhlasím se zásadami ochrany osobních údajů.*',
    required: '* požadované podmínky',
    proceed: 'Přijmout',
    conditionTermsHeading: 'Podmínky používání',
    conditionPrivacyHeading: 'Zásady ochrany osobních údajů',
  },
  signDocumentPage: {
    header: 'Podepsat dokument',
    checkboxLabel:
      'Potvrzuji, že jsem si přečetl/a výše uvedené podmínky této smlouvy a rozumím jim.',
    proceed: 'Podepsat dokument',
  },
  videoOnboardingPage: {
    header: 'Co potřebujete?',
    wellLitPlace: 'Dobře osvětlené místo.',
    originalDocument: 'Váš originální doklad, platný a bez obálek.',
    wifi: 'Připojení Wi-Fi nebo maximální pokrytí 4G.',
    start: 'Pokračovat',
  },
  documentType: {
    selectCountry: 'Vyberte zemi, která doklad vydala',
    ResidentPermit: 'Povolení pro rezidenty',
    EUResidentPermit: 'Povolení pro rezidenty EU',
    TravelTitle: 'Cestovní titul',
    IdentityCard: 'Průkaz totožnosti',
  },
  otpPage: {
    verify: 'Ověřit',
    header: 'Zadejte ověřovací kód zaslaný na váš telefon',
    headerVerifying: 'Počkejte prosím na ověření.',
  },
  userDataPage: {
    header: 'Uveďte prosím kontaktní údaje',
    proceed: 'Potvrďte',
    email: 'E-mail:',
    phone: 'Telefon:',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: 'Ověřování totožnosti',
    firstParagraph:
      'Vítejte v procesu ověřování totožnosti Signicat. Společnost Signicat je spolu s naší dceřinou společností ElectronicID certifikovaným poskytovatelem důvěryhodných služeb EU [https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42] a bude provádět vaši identifikaci a elektronické podepisování pro váš onboarding u společnosti',
    firstParagraphPartOne:
      'Vítejte v procesu ověřování totožnosti Signicat. Společnost Signicat a její dceřiná společnost ElectronicID jsou důvěryhodnými subjekty s certifikací EU.',
    serviceProvider: 'poskytovatel služeb',
    firstParagraphPartTwo:
      ' a bude provádět vaši identifikaci a elektronické podepisování pro váš onboarding u společnosti',
    secondParagraph:
      'Váš identifikační proces bude probíhat zcela digitálně a bez nutnosti mluvit se zprostředkovatelem. K dokončení procesu budeme potřebovat:',
    thirdParagraph:
      'Přístup k online bankovnictví? Ano, správně. Jako dodatečné bezpečnostní opatření jsme povinni podle regulátora iniciovat převod 0,01 EUR z vašeho bankovního účtu a zkontrolovat, zda se jméno na účtu shoduje s vaší identitou.\\n',
    fourthParagraph:
      'Jste připraveni vyrazit? Skvěle, přijměte prosím naše zásady ochrany osobních údajů, abychom mohli začít.',
    consent:
      'Souhlasím s použitím svých osobních údajů pro identifikaci v rámci služeb Signicat Open Banking Verification.*',
    required: '* požadovaná podmínka',
    proceed: 'Potvrďte',
    whatYouNeed: 'Co potřebujete?',
    wellLitPlace: 'Dobré osvětlení',
    originalDocument: 'Občanský průkaz nebo cestovní pas',
    stableConnection: 'Stabilní připojení k internetu',
    onlineBanking: 'Přístup k vašemu online bankovnictví',
    cameraAccess: 'Přístup k fotoaparátu zařízení',
  },
  progressPage: {
    heading: 'Ověřování totožnosti',
    wait: 'Nyní ověřujeme vaši totožnost. Obvykle to trvá méně než 3 minuty.',
    waitMore:
      'Děkujeme za vaši trpělivost. Je to téměř hotové. Prosím, nezavírejte prohlížeč.',
  },
  jitPayWelcomePage: {
    heading: 'Vítejte v procesu ověřování totožnosti Signicat',
    descriptionPartOne:
      'Společnost Signicat a její dceřiná společnost ElectronicID je důvěryhodným certifikátem EU.',
    serviceProvider: 'poskytovatel služeb',
    descriptionPartTwo:
      ' a bude provádět vaši identifikaci a elektronické podepisování pro váš vstup do systému.',
    idData:
      'Souhlasím s použitím svých osobních údajů pro identifikaci pomocí služeb Signicat Open Banking Verification.',
    termsAndConditions: 'Souhlasím s podmínkami používání služby.',
    privacyPolicy: 'Souhlasím se zásadami ochrany osobních údajů.',
    proceed: 'Potvrďte',
    stepOne: 'Podepisování',
    stepTwo: 'Ověřování totožnosti',
    stepThree: 'Převod 1 centu',
    stepThreeDesc: 'Požadováno regulačním orgánem',
  },
  jitPayOnboardingPage: {
    heading:
      'Chcete-li proces dokončit, postupujte podle následujících tří kroků:',
    signingHeader: '1. Podepisování',
    signingContent: 'Zkontrolujte a podepište smlouvu.',
    idVerifyHeader: '2. Ověřování totožnosti',
    idVerifyContent:
      'Váš identifikační proces bude probíhat zcela digitálně a bez nutnosti mluvit se zprostředkovatelem.',
    micropaymentHeader: '3. Mikroplatby',
    micropaymentContent: '0,01 EUR převodem z vašeho osobního bankovního účtu.',
    proceed: 'Potvrďte',
  },
  jitPayVideoOnboardingPage: {
    header: 'Co budete potřebovat pro proces VideoID:',
    lightHeading: 'Dobré osvětlení',
    lightContent: 'Ujistěte se, že jste v dobře osvětleném prostředí.',
    netHeading: 'Stabilní připojení k internetu',
    netContent: 'Ujistěte se, že máte silné připojení k internetu.',
    deviceHeading: 'Přístup k zařízení s fotoaparátem',
    deviceContent: 'Autorizujte přístup k zařízení s fotoaparátem.',
    oneCameraHeading: 'Použití pouze jednoho fotoaparátu',
    oneCameraContent:
      'Dbejte na to, abyste měli ke stolnímu zařízení připojen pouze jeden fotoaparát. Pokud používáte notebook, je správné použít vestavěnou kameru.',
    proceed: 'Pokračovat',
  },
  micropaymentInfoJitpayPage: {
    header: 'Mikroplatba z vašeho osobního bankovního účtu',
    content:
      'Jako dodatečné bezpečnostní opatření jsme povinni podle regulátora iniciovat převod 0,01 EUR z vašeho osobního bankovního účtu a zkontrolovat, zda jméno na účtu odpovídá vaší totožnosti.',
    warning: 'Použijte prosím svůj osobní bankovní účet',
    proceed: 'Potvrďte',
    idData:
      'Souhlasím s použitím svých osobních údajů pro identifikaci pomocí služeb Signicat Open Banking Verification.',
  },
  micropaymentInfoIkanoPage: {
    content:
      'Jako dodatečné bezpečnostní opatření jsme povinni podle regulátora iniciovat převod 0,01 EUR z vašeho osobního bankovního účtu a zkontrolovat, zda jméno na účtu odpovídá vaší totožnosti.',
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    terms:
      'I accept the terms and conditions of the video-identification process and the issuance of short- term certificates',
  },
  ikanoWelcomePage: {
    heading: 'Vítejte v procesu ověřování totožnosti Signicat',
    descriptionPartOne:
      'Společnost Signicat a její dceřiná společnost ElectronicID je důvěryhodným certifikátem EU.',
    serviceProvider: 'poskytovatel služeb',
    descriptionPartTwo:
      ' a bude provádět vaši identifikaci a elektronické podepisování pro váš vstup do systému.',
    idData:
      'Souhlasím s použitím svých osobních údajů pro identifikaci pomocí služeb Signicat Open Banking Verification.',
    termsAndConditions: 'Souhlasím s podmínkami používání služby.',
    privacyPolicy: 'Souhlasím se zásadami ochrany osobních údajů.',
    proceed: 'Potvrďte',
    stepOne: 'Podepisování',
    stepTwo: 'Ověřování totožnosti',
    stepThree: 'Převod 1 centu',
    stepThreeDesc: 'Požadováno regulačním orgánem',
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign a document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign a document',
  },
  introKontomatikPage: {
    header: 'Ověření adresy',
    intro:
      'V dalším kroku ověříme vaši adresu. Požádáme vás, abyste ze seznamu vybrali svou banku a přihlásili se ke svému osobnímu účtu.',
    accountInformation: 'Získáme následující informace o účtu:',
    names: 'Jméno a příjmení - vlastníka (vlastníků) účtu',
    address: 'Adresa - vlastníka (vlastníků) účtu',
    ownership: 'Vlastnictví účtu - vlastník, spolumajitel atd.',
    verificationPurposes:
      'Z výše uvedených údajů použijeme pro účely ověření <b>pouze vaši adresu</b>.',
    proceed: 'Pokračovat',
  },
  addressUploadPageKontomatik: {
    verifying: 'Ověřování...',
    header: 'Nahrání adresy',
    description:
      'Z informací o vašem bankovním účtu se nám nepodařilo získat adresu.',
    confirmation:
      'Pro potvrzení vaší adresy vás požádáme o nahrání bankovních výpisů, které obsahují vaši adresu.',
    bankStatementContains:
      'MUjistěte se, že váš bankovní výpis obsahuje vaši adresu',
    yourAddress: ' vaši adresu ',
    proceed: 'Pokračovat',
    dragAndDrop: 'Přetáhněte a pusťte nebo ',
    chooseFile: 'vybrat soubor',
    pko: {
      header: 'PKO BP Bankovní výpis',
      confirmation:
        'Pro potvrzení vaší adresy vás požádáme o nahrání bankovních výpisů, které obsahují vaši adresu.',
      whereToFindStatements: 'Kde najít bankovní výpisy v PKO BP?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
