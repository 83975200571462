export const translations = {
  pageTitle: '',
  documentTypePage: {
    header:
      'Üdvözlöm, felvételt készítünk, hogy ellenőrizzük a személyazonosságát.',
    description:
      'Válassza ki azt a dokumentumot, amellyel azonosítani kívánja magát.',
    proceed: 'Folytatás',
    selectCountry: 'Válassza ki a dokumentumot kiállító országot',
    chooseDocument: 'Válassza ki a dokumentum típusát',
    ResidentPermit: 'Tartózkodási engedély',
    EUResidentPermit: 'EU tartózkodási engedély',
    TravelTitle: 'Utazás cím',
    DriverLicense: 'Vezetői engedély',
    Passport: 'Útlevél',
    IdentityCard: 'Személyi igazolvány',
    inputPlaceholder: 'Válasszon',
  },
  generalConditionsPage: {
    header: 'Általános feltételek',
    termsAndConditions: 'Elfogadom a szolgáltatás használatának feltételeit.*',
    privacyPolicy: 'Elfogadom az adatvédelmi szabályzatot.*',
    required: '* szükséges feltételek',
    proceed: 'Elfogadom',
    conditionTermsHeading: 'Használati feltételek és kikötések',
    conditionPrivacyHeading: 'Adatvédelmi irányelvek',
  },
  signDocumentPage: {
    header: 'Dokumentum aláírása',
    checkboxLabel:
      'Elismerem, hogy elolvastam és megértettem a jelen megállapodás fent részletezett feltételeit.',
    proceed: 'Dokumentum aláírása',
  },
  videoOnboardingPage: {
    header: 'Mire van szüksége?',
    wellLitPlace: 'Egy jól megvilágított hely.',
    originalDocument: 'Az Ön eredeti, érvényes és fedezet nélküli okmánya.',
    wifi: 'Wi-Fi kapcsolat vagy maximális 4G lefedettség.',
    start: 'Folytatás',
  },
  documentType: {
    selectCountry: 'Válassza ki a dokumentumot kiállító országot',
    ResidentPermit: 'Tartózkodási engedély',
    EUResidentPermit: 'EU tartózkodási engedély',
    TravelTitle: 'Utazás cím',
    IdentityCard: 'Személyi igazolvány',
  },
  otpPage: {
    verify: 'Ellenőrizze a  címet.',
    header: 'Írja be a telefonjára küldött ellenőrző kódot',
    headerVerifying: 'Kérjük, várjon az ellenőrzésre.',
  },
  userDataPage: {
    header: 'Kérjük, adja meg elérhetőségét',
    proceed: 'Megerősítés',
    email: 'E-mail:',
    phone: 'Telefon:',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: 'Személyazonosság ellenőrzése',
    firstParagraph:
      'Üdvözöljük a Signicat személyazonosság-ellenőrzési folyamatában. A Signicat, leányvállalatával, az ElectronicID-vel együtt, az EU által tanúsított megbízható szolgáltató [https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42], amely az Ön azonosítását és elektronikus aláírását végzi az Ön beilleszkedése során a következő cégeknél',
    firstParagraphPartOne:
      'Üdvözöljük a Signicat személyazonosság-ellenőrzési folyamatában. A Signicat, leányvállalatával, az ElectronicID-vel együtt, egy EU által tanúsított megbízható',
    serviceProvider: 'szolgáltató',
    firstParagraphPartTwo:
      ' és elvégzi az Ön azonosítását és elektronikus aláírását a beszálláshoz.',
    secondParagraph:
      'Az Ön azonosítási folyamata teljesen digitálisan történik, és nem kell ügynökkel beszélnie. A folyamat befejezéséhez a következőkre lesz szükségünk:',
    thirdParagraph:
      'Hozzáférés az online banki szolgáltatásokhoz? Igen, pontosan. További biztonsági intézkedésként a szabályozó hatóság kötelez minket arra, hogy 0,01 EUR átutalást kezdeményezzünk az Ön bankszámlájáról, és ellenőrizzük, hogy a számlán szereplő név megegyezik-e az Ön személyazonosságával.\\n',
    fourthParagraph:
      'Készen állsz? Remek, kérjük, fogadja el adatvédelmi szabályzatunkat, hogy elkezdhessük.',
    consent:
      'Hozzájárulok ahhoz, hogy személyes adataimat a Signicat Open Banking Verification szolgáltatásokkal történő azonosításomhoz felhasználják.*',
    required: '* szükséges feltétel',
    proceed: 'Megerősítés',
    whatYouNeed: 'Mire van szüksége?',
    wellLitPlace: 'Jó megvilágítás',
    originalDocument: 'Nemzeti személyazonosító igazolványa vagy útlevele',
    stableConnection: 'Stabil internetkapcsolatok',
    onlineBanking: 'Hozzáférés online banki szolgáltatásaihoz',
    cameraAccess: 'Access to your device camera',
  },
  progressPage: {
    heading: 'Személyazonosság ellenőrzése',
    wait: 'Most ellenőrizzük a személyazonosságát. Ez általában kevesebb mint 3 percet vesz igénybe.',
    waitMore:
      'Köszönöm a türelmüket. Már majdnem kész. Kérjük, ne zárja be a böngészőt.',
  },
  jitPayWelcomePage: {
    heading: 'Üdvözöljük a Signicat személyazonosság-ellenőrzési folyamatában',
    descriptionPartOne:
      'A Signicat, leányvállalatával, az ElectronicID-vel, egy EU által tanúsított megbízható ',
    serviceProvider: 'szolgáltató',
    descriptionPartTwo:
      ' és elvégzi az Ön azonosítását és elektronikus aláírását a beszálláshoz.',
    idData:
      'Hozzájárulok ahhoz, hogy személyes adataimat a Signicat Open Banking Verification szolgáltatásokkal történő azonosításomhoz felhasználják.',
    termsAndConditions: 'Elfogadom a szolgáltatás használatának feltételeit.',
    privacyPolicy: 'Elfogadom az adatvédelmi szabályzatot.',
    proceed: 'Megerősítés',
    stepOne: 'Aláírás',
    stepTwo: 'Személyazonosság ellenőrzése',
    stepThree: '1 cent átutalás',
    stepThreeDesc: 'A szabályozó által előírt',
  },
  jitPayOnboardingPage: {
    heading: 'A folyamat befejezéséhez kövesse az alábbi három lépést:',
    signingHeader: '1. Aláírás',
    signingContent: 'Tekintse át és írja alá a szerződést.',
    idVerifyHeader: '2. Személyazonosság ellenőrzése',
    idVerifyContent:
      'Az Ön azonosítási folyamata teljesen digitálisan történik, és nem kell ügynökkel beszélnie.',
    micropaymentHeader: '3. Mikrofizetés',
    micropaymentContent: '0,01 EUR átutalás az Ön személyes bankszámlájáról.',
    proceed: 'Megerősítés',
  },
  jitPayVideoOnboardingPage: {
    header: 'Amire szükséged lesz a VideoID folyamathoz:',
    lightHeading: 'Jó megvilágítás',
    lightContent:
      'Győződjön meg róla, hogy jól megvilágított környezetben van.',
    netHeading: 'Stabil internetkapcsolat',
    netContent:
      'Győződjön meg róla, hogy erős internetkapcsolattal rendelkezik.',
    deviceHeading: 'Hozzáférés a kamerás eszközhöz',
    deviceContent: 'Engedélyezze a hozzáférést a kamerás eszközhöz.',
    oneCameraHeading: 'Csak egy kamera használata',
    oneCameraContent:
      'Kérjük, ügyeljen arra, hogy csak egy kamerás eszköz legyen csatlakoztatva az asztali eszközéhez. Ha laptopot használ, akkor a beépített kamera a megfelelő kamera.',
    proceed: 'Folytatás',
  },
  micropaymentInfoJitpayPage: {
    header: 'Mikrofizetés az Ön személyes bankszámlájáról',
    content:
      'További biztonsági intézkedésként a szabályozó hatóság kötelez minket arra, hogy 0,01 EUR átutalást kezdeményezzünk az Ön személyes bankszámlájáról, és ellenőrizzük, hogy a számlán szereplő név megegyezik-e az Ön személyazonosságával.',
    warning: 'Kérjük, használja személyes bankszámláját',
    proceed: 'Megerősítés',
    idData:
      'Hozzájárulok ahhoz, hogy személyes adataimat a Signicat Open Banking Verification szolgáltatásokkal történő azonosításomhoz felhasználják.',
  },
  micropaymentInfoIkanoPage: {
    content:
      'További biztonsági intézkedésként a szabályozó hatóság kötelez minket arra, hogy 0,01 EUR átutalást kezdeményezzünk az Ön személyes bankszámlájáról, és ellenőrizzük, hogy a számlán szereplő név megegyezik-e az Ön személyazonosságával.',
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    terms:
      'I accept the terms and conditions of the video-identification process and the issuance of short- term certificates',
  },
  ikanoWelcomePage: {
    heading: 'Üdvözöljük a Signicat személyazonosság-ellenőrzési folyamatában',
    descriptionPartOne:
      'A Signicat, leányvállalatával, az ElectronicID-vel, egy EU által tanúsított megbízható ',
    serviceProvider: 'szolgáltató',
    descriptionPartTwo:
      ' és elvégzi az Ön azonosítását és elektronikus aláírását a beszálláshoz.',
    idData:
      'Hozzájárulok ahhoz, hogy személyes adataimat a Signicat Open Banking Verification szolgáltatásokkal történő azonosításomhoz felhasználják.',
    termsAndConditions: 'Elfogadom a szolgáltatás használatának feltételeit.',
    privacyPolicy: 'Elfogadom az adatvédelmi szabályzatot.',
    proceed: 'Megerősítés',
    stepOne: 'Aláírás',
    stepTwo: 'Személyazonosság ellenőrzése',
    stepThree: '1 cent átutalás',
    stepThreeDesc: 'A szabályozó által előírt',
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign a document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign a document',
  },
  introKontomatikPage: {
    header: 'Címellenőrzés',
    intro:
      'A következő lépésben ellenőrizzük a címét. Megkérjük, hogy válassza ki bankját a listából, és jelentkezzen be személyes számlájára.',
    accountInformation: 'A következő számlainformációkat fogjuk lekérni:',
    names: 'A számlatulajdonos(ok) vezeték- és keresztneve',
    address: 'A számlatulajdonos(ok) címe',
    ownership: 'Számla tulajdonjoga - tulajdonos, társtulajdonos stb.',
    verificationPurposes:
      'A fenti adatokból <b>csak az Ön címét</b> fogjuk felhasználni az ellenőrzéshez.',
    proceed: 'Folytatás',
  },
  addressUploadPageKontomatik: {
    verifying: 'Ellenőrzés...',
    header: 'Címfeltöltés',
    description: 'Nem tudtuk lekérdezni a címet a bankszámlaadatokból.',
    confirmation:
      'A cím megerősítéséhez megkérjük, hogy töltse fel a címét tartalmazó bankszámlakivonatokat.',
    bankStatementContains:
      'Győződjön meg róla, hogy a bankszámlakivonat tartalmazza a címét',
    yourAddress: ' az Ön címe',
    proceed: 'Folytatás',
    dragAndDrop: 'Drag & Drop vagy ',
    chooseFile: 'fájl kiválasztása',
    pko: {
      header: 'PKO BP Bankszámlakivonat',
      confirmation:
        'A cím megerősítéséhez megkérjük, hogy töltse fel a címét tartalmazó bankszámlakivonatokat.',
      whereToFindStatements: 'Hol találhatók a PKO BP bankszámlakivonatok?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
