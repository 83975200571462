export const translations = {
  pageTitle: '',
  documentTypePage: {
    header: 'Select your identity document',
    selectCountry: 'Select the country that issued the document.',
    proceed: 'Continue',
    chooseDocument: 'Choose your document type',
    ResidentPermit: 'Resident Permit',
    EUResidentPermit: 'EU Resident Permit',
    TravelTitle: 'Travel title',
    DriverLicense: 'Driving license',
    Passport: 'Passport',
    IdentityCard: 'Identity card',
    inputPlaceholder: 'Select',
  },
  generalConditionsPage: {
    header: 'General conditions',
    privacyPolicy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    termsAndConditions:
      'I accept the terms and conditions of the video-identification process and the issuance of short term certificates',
    required: '* required conditions',
    proceed: 'Continue',
    conditionTermsHeading: 'Terms and Conditions of use',
    conditionPrivacyHeading: 'Privacy Policy',
  },
  otpPage: {
    header: 'Enter Verification Code sent to your phone',
    headerVerifying: 'Please wait for your verification.',
    verify: 'Verify',
  },
  signDocumentPage: {
    header: 'Sign document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign document',
  },
  klarnaPrivacyPolicyPage: {
    firstParagraph: `Welcome to the Signicat Identity Verification Process. Signicat, with our subsidiary ElectronicID, is a EU certified trusted service provider [https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42] and will be performing your identification and electronic signing for your onboarding with `,
    firstParagraphPartOne: `Welcome to the Signicat Identity Verification Process. Signicat, with our subsidiary ElectronicID, is a EU certified trusted `,
    serviceProvider: 'service provider',
    firstParagraphPartTwo:
      ' and will be performing your identification and electronic signing for your onboarding',
    secondParagraph:
      'Your identification process will be performed completely digital and without the need to speak to an agent. To complete the process, we will need:',
    thirdParagraph:
      'Access to your online banking? Yes, correct. As an additional security measure, we are obliged by the regulator to initiate a 0,01 EUR transfer from your bank account and check if the name in the account matches your identity.\n',
    fourthParagraph:
      'Ready to go? Great, please accept our privacy policy so we can start.',
    required: '* required condition',
    privacyPolicyHeader: 'Privacy Policy',
    consent:
      'I consent to the use of my personal data for identifying myself with Signicat Open Banking Verification services.',
    proceed: 'Confirm',
    whatYouNeed: 'What do you need?',
    wellLitPlace: 'Good lighting',
    originalDocument: 'Your national ID or Passport',
    stableConnection: 'Stable internet connections',
    onlineBanking: 'Access to your online banking',
    cameraAccess: 'Access to your device camera',
  },
  userDataPage: {
    header: 'Please provide contact information',
    email: 'E-mail:',
    phone: 'Phone:',
    proceed: 'Confirm',
  },
  videoOnboardingPage: {
    header: 'What do you need?',
    wellLitPlace: 'A well-lit place.',
    originalDocument: 'Your original document, valid and without covers.',
    wifi: 'Wi-Fi connection or maximum 4G coverage.',
    start: 'Continue',
  },
  documentType: {
    selectCountry: 'Select the country that issued the document',
    ResidentPermit: 'Resident Permit',
    EUResidentPermit: 'EU Resident Permit',
    IdentityCard: 'Identity card',
  },
  progressPage: {
    heading: 'Identity verification',
    wait: 'Now we are verifying your identity. Usually, it takes less than 3 minutes.',
    waitMore:
      'Thanks for your patience. It’s almost done. Please do not close the browser. ',
  },
  jitPayWelcomePage: {
    heading: 'Welcome to the Signicat Identity Verification Process',
    descriptionPartOne:
      'Signicat, with our subsidiary ElectronicID, is a EU certified trusted ',
    serviceProvider: 'service provider',
    descriptionPartTwo:
      ' and will be performing your identification and electronic signing for your onboarding with',
    idData:
      'I consent to the use of my personal data for identifying myself with Signicat Open Banking Verification services.',
    privacyPolicy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    termsAndConditions:
      'I accept the terms and conditions of the video-identification process and the issuance of short term certificates',
    proceed: 'Confirm',
    stepOne: 'Signing',
    stepTwo: 'Identity Verification',
    stepThree: '1 cent transfer',
    stepThreeDesc: 'Required by regulator',
  },
  jitPayOnboardingPage: {
    heading: 'To complete the process, follow these three steps:',
    signingHeader: '1. Signing',
    signingContent: 'Review and sign the contract.',
    idVerifyHeader: '2. Identity Verification',
    idVerifyContent:
      'Your identification process will be performed completely digital and without the need to speak to an agent.',
    micropaymentHeader: '3. Micropayment',
    micropaymentContent: '0,01 EUR transfer from your personal bank account.',
    proceed: 'Confirm',
  },
  jitPayVideoOnboardingPage: {
    header: 'What you will need For the VideoID process:',
    lightHeading: 'Good lighting',
    lightContent: 'Make sure that you are in a well-lit environment.',
    netHeading: 'Stable internet connection',
    netContent: 'Make sure that you have a strong internet connection.',
    deviceHeading: 'Access to your camera device',
    deviceContent: 'Authorize access to your camera device.',
    oneCameraHeading: 'Use one camera only',
    oneCameraContent:
      'Please make sure to have only one camera device plugged into your desktop device. If you are using a laptop, the built-in camera is the correct camera to use.',
    proceed: 'Continue',
  },
  micropaymentInfoJitpayPage: {
    header: 'Micropayment from your personal bank account',
    content:
      'As an additional security measure, we are obliged by the regulator to initiate a 0,01 EUR transfer from your personal bank account and check if the name in the account matches your identity.',
    warning: 'Please use your personal bank account',
    proceed: 'Confirm',
    idData:
      'I consent to the use of my personal data for identifying myself with Signicat Open Banking Verification services.',
  },
  micropaymentInfoIkanoPage: {
    content:
      'As an additional security measure, we are obliged by the regulator to initiate a 0,01 EUR transfer from your personal bank account and check if the name in the account matches your identity.',
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    terms:
      'I accept the terms and conditions of the video-identification process and the issuance of short term certificates',
  },
  ikanoWelcomePage: {
    heading: 'Welcome to the Signicat Identity Verification Process',
    descriptionPartOne:
      'Signicat, with our subsidiary ElectronicID, is a EU certified trusted ',
    serviceProvider: 'service provider',
    descriptionPartTwo:
      ' and will be performing your identification and electronic signing for your onboarding.',
    idData:
      'I consent to the use of my personal data for identifying myself with Signicat Open Banking Verification services.',
    privacyPolicy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    termsAndConditions:
      'I accept the terms and conditions of the video-identification process and the issuance of short term certificates',
    proceed: 'Confirm',
    stepOne: 'Signing',
    stepTwo: 'Identity Verification',
    stepThree: '1 cent transfer',
    stepThreeDesc: 'Required by regulator',
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign document',
  },
  introKontomatikPage: {
    header: 'Address Verification',
    intro:
      'In the next step, we will verify your address. We will ask you to select your bank from the list and log in to your personal account.',
    accountInformation: 'We will fetch the following account information:',
    names: 'First and last name - of account owner(s)',
    address: 'Address - of account owner(s)',
    ownership: 'Account ownership - owner, co-owner, etc.',
    verificationPurposes:
      'From the data above we are going to use <b>only your address</b> for verification purposes.',
    proceed: 'Continue',
  },
  addressUploadPageKontomatik: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      "We couldn't fetch address from your bank account information.",
    confirmation:
      'To confirm your address we will ask you to upload the bank statements that contain your address.',
    bankStatementContains:
      'Make sure that your bank statement contains your address',
    yourAddress: ' your address',
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    chooseFile: 'choose file',
    pko: {
      header: 'PKO BP Bank statement',
      confirmation:
        'To confirm your address we will ask you to upload the bank statements that contain your address.',
      whereToFindStatements: 'Where to find bank statements in PKO BP?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
