export const translations = {
  pageTitle: '',
  documentTypePage: {
    header:
      'Hei, vi kommer til å gjøre et opptak for å bekrefte identiteten din.',
    description: 'Velg det dokumentet du vil identifisere deg selv med',
    proceed: 'Fortsett',
    selectCountry: 'Velg landet som har utstedt dokumentet',
    chooseDocument: 'Velg dokumenttype',
    ResidentPermit: 'Beboertillatelse',
    EUResidentPermit: 'EU-oppholdstillatelse',
    TravelTitle: 'Reisetittel',
    DriverLicense: 'Førerkort',
    Passport: 'Pass',
    IdentityCard: 'Identitetskort',
    inputPlaceholder: 'Velg',
  },
  generalConditionsPage: {
    header: 'Generelle betingelser',
    termsAndConditions:
      'Jeg godtar vilkårene og betingelsene for bruk av tjenesten.',
    privacyPolicy: 'Jeg godtar personvernerklæringen.',
    required: '* nødvendige betingelser',
    proceed: 'Godta',
    conditionTermsHeading: 'Vilkår og betingelser for bruk',
    conditionPrivacyHeading: 'Retningslinjer for personvern',
  },
  signDocumentPage: {
    header: 'Signer et dokument',
    checkboxLabel:
      'Jeg bekrefter at jeg har lest og forstått vilkårene i denne avtalen som beskrevet ovenfor.',
    proceed: 'Signer et dokument',
  },
  videoOnboardingPage: {
    header: 'Hva trenger du?',
    wellLitPlace: 'Et godt opplyst sted.',
    originalDocument: 'Originaldokumentet ditt, gyldig og uten omslag.',
    wifi: 'Wi-Fi-tilkobling eller maksimal 4G-dekning.',
    start: 'Fortsette',
  },
  documentType: {
    selectCountry: 'Velg landet som har utstedt dokumentet',
    ResidentPermit: 'Beboertillatelse',
    EUResidentPermit: 'EU-oppholdstillatelse',
    TravelTitle: 'Reisetittel',
    IdentityCard: 'Identitetskort',
  },
  otpPage: {
    verify: 'Bekreft',
    header: 'Skriv inn bekreftelseskoden som er sendt til telefonen din',
    headerVerifying: 'Vennligst vent på bekreftelse.',
  },
  userDataPage: {
    header: 'Vennligst oppgi kontaktinformasjon',
    proceed: 'Bekreft',
    email: 'E-post:',
    phone: 'Telefon:',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: 'Verifisering av identitet',
    firstParagraph:
      'Velkommen til Signicats identitetsverifiseringsprosess. Signicat, med vårt datterselskap ElectronicID, er en EU-sertifisert leverandør av betrodde tjenester [https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42] og vil utføre identifisering og elektronisk signering for din onboarding med',
    firstParagraphPartOne:
      'Velkommen til Signicats prosess for identitetsverifisering. Signicat, sammen med datterselskapet ElectronicID, er en EU-sertifisert, pålitelig ',
    serviceProvider: 'tjenesteleverandør',
    firstParagraphPartTwo:
      ' og vil utføre identifikasjon og elektronisk signering for din onboarding.',
    secondParagraph:
      'Identifikasjonsprosessen utføres helt digitalt og uten at du trenger å snakke med en agent. For å fullføre prosessen trenger vi',
    thirdParagraph:
      'Tilgang til nettbanken din? Ja, det stemmer. Som et ekstra sikkerhetstiltak er vi forpliktet av myndighetene til å initiere en overføring på 0,01 EUR fra bankkontoen din og sjekke om navnet på kontoen samsvarer med identiteten din.',
    fourthParagraph:
      'Er du klar til å begynne? Flott, vennligst godta personvernerklæringen vår slik at vi kan begynne.',
    consent:
      'Jeg samtykker til at personopplysningene mine brukes til å identifisere meg med Signicat Open Banking Verification-tjenester*.',
    required: '* påkrevd tilstand',
    proceed: 'Bekreft',
    whatYouNeed: 'Hva trenger du?',
    wellLitPlace: 'God belysning',
    originalDocument: 'Nasjonalt ID-kort eller pass',
    stableConnection: 'Stabile internettforbindelser',
    onlineBanking: 'Tilgang til nettbanken din',
    cameraAccess: 'Tilgang til enhetens kamera',
  },
  progressPage: {
    heading: 'Verifisering av identitet',
    wait: 'Nå verifiserer vi identiteten din. Det tar vanligvis mindre enn 3 minutter.',
    waitMore:
      'Takk for tålmodigheten. Den er nesten ferdig. Vennligst ikke lukk nettleseren.',
  },
  jitPayWelcomePage: {
    heading: 'Velkommen til Signicats identitetsbekreftelsesprosess',
    descriptionPartOne:
      'Signicat, med vårt datterselskap ElectronicID, er en EU-sertifisert pålitelig ',
    serviceProvider: 'tjenesteleverandør',
    descriptionPartTwo:
      ' og vil utføre identifikasjon og elektronisk signering for din onboarding.',
    idData:
      'Jeg samtykker til at personopplysningene mine brukes til å identifisere meg med Signicat Open Banking Verification-tjenester.',
    termsAndConditions:
      'Jeg godtar vilkårene og betingelsene for bruk av tjenesten.',
    privacyPolicy: 'Jeg godtar personvernerklæringen.',
    proceed: 'Bekreft',
    stepOne: 'Signering',
    stepTwo: 'Verifisering av identitet',
    stepThree: '1 cent overføring',
    stepThreeDesc: 'Påkrevd av tilsynsmyndigheten',
  },
  jitPayOnboardingPage: {
    heading: 'Følg disse tre trinnene for å fullføre prosessen:',
    signingHeader: '1. Signering',
    signingContent: 'Gå gjennom og signer kontrakten.',
    idVerifyHeader: '2. Verifisering av identitet',
    idVerifyContent:
      'Identifikasjonsprosessen utføres helt digitalt og uten at du trenger å snakke med en agent.',
    micropaymentHeader: '3. Mikrobetaling',
    micropaymentContent: '0,01 EUR overføring fra din personlige bankkonto.',
    proceed: 'Bekreft',
  },
  jitPayVideoOnboardingPage: {
    header: 'Hva du trenger til VideoID-prosessen:',
    lightHeading: 'God belysning',
    lightContent: 'Sørg for at du befinner deg i godt opplyste omgivelser.',
    netHeading: 'Stabil internettforbindelse',
    netContent: 'Sørg for at du har en sterk internettforbindelse.',
    deviceHeading: 'Tilgang til kameraenheten din',
    deviceContent: 'Godkjenn tilgang til kameraenheten.',
    oneCameraHeading: 'Bruk kun ett kamera',
    oneCameraContent:
      'Sørg for at bare én kameraenhet er koblet til den stasjonære enheten. Hvis du bruker en bærbar datamaskin, er det det innebygde kameraet som skal brukes.',
    proceed: 'Fortsett',
  },
  micropaymentInfoJitpayPage: {
    header: 'Mikrobetaling fra din personlige bankkonto',
    content:
      'Som et ekstra sikkerhetstiltak er vi forpliktet av myndighetene til å initiere en overføring på 0,01 EUR fra din personlige bankkonto og sjekke om navnet på kontoen stemmer overens med din identitet.',
    warning: 'Vennligst bruk din personlige bankkonto',
    proceed: 'Bekreft',
    idData:
      'Jeg samtykker til at personopplysningene mine brukes til å identifisere meg med Signicat Open Banking Verification-tjenester.',
  },
  micropaymentInfoIkanoPage: {
    content:
      'Som et ekstra sikkerhetstiltak er vi forpliktet av myndighetene til å initiere en overføring på 0,01 EUR fra din personlige bankkonto og sjekke om navnet på kontoen stemmer overens med din identitet.',
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    terms:
      'I accept the terms and conditions of the video-identification process and the issuance of short- term certificates',
  },
  ikanoWelcomePage: {
    heading: 'Velkommen til Signicats identitetsbekreftelsesprosess',
    descriptionPartOne:
      'Signicat, med vårt datterselskap ElectronicID, er en EU-sertifisert pålitelig ',
    serviceProvider: 'tjenesteleverandør',
    descriptionPartTwo:
      ' og vil utføre identifikasjon og elektronisk signering for din onboarding.',
    idData:
      'Jeg samtykker til at personopplysningene mine brukes til å identifisere meg med Signicat Open Banking Verification-tjenester.',
    termsAndConditions:
      'Jeg godtar vilkårene og betingelsene for bruk av tjenesten.',
    privacyPolicy: 'Jeg godtar personvernerklæringen.',
    proceed: 'Bekreft',
    stepOne: 'Signering',
    stepTwo: 'Verifisering av identitet',
    stepThree: '1 cent overføring',
    stepThreeDesc: 'Påkrevd av tilsynsmyndigheten',
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign a document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign a document',
  },
  introKontomatikPage: {
    header: 'Verifisering av adresse',
    intro:
      'I neste trinn verifiserer vi adressen din. Vi vil be deg om å velge bank fra listen og logge inn på din personlige konto.',
    accountInformation: 'Vi henter følgende kontoinformasjon:',
    names: 'For- og etternavn - på kontoinnehaver(e)',
    address: 'Adresse - til kontoeier(e)',
    ownership: 'Kontoeierskap - eier, medeier osv.',
    verificationPurposes:
      'Fra dataene ovenfor kommer vi til å bruke <b>kun adressen din</b> for verifiseringsformål.',
    proceed: 'Fortsett',
  },
  addressUploadPageKontomatik: {
    verifying: 'Bekrefter...',
    header: 'Adresse for opplasting',
    description: 'Vi kunne ikke hente adressen fra bankkontoinformasjonen din.',
    confirmation:
      'For å bekrefte adressen din ber vi deg laste opp kontoutskriftene som inneholder adressen din.',
    bankStatementContains:
      'Kontroller at kontoutskriften din inneholder adressen din',
    yourAddress: ' adressen din',
    proceed: 'Fortsett',
    dragAndDrop: 'Dra og slipp eller ',
    chooseFile: 'velg fil',
    pko: {
      header: 'PKO BP Bankutskrift',
      confirmation:
        'For å bekrefte adressen din ber vi deg laste opp kontoutskriftene som inneholder adressen din.',
      whereToFindStatements: 'Hvor finner jeg kontoutskrifter i PKO BP?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
