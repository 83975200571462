import { Link, Route, Switch } from 'wouter'
import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { ContentWrap } from '../styles/generic.styles'
import { StoreContext } from './App'
import { MobileNavMenu } from './MobileNavMenu/MobileNavMenu'
import { LandingPage } from '../pages/LandingPage/LandingPage'
import { RedirectPage } from '../pages/RedirectPage'
import { LoginPage } from '../pages/LoginPage/LoginPage'
import { CompletePage } from '../pages/CompletePage'

const MOBILE_NAV_MENU_ROUTES = ['']

export const ContentRouter: React.FC = observer(() => {
  const store = useContext(StoreContext)

  const path = window.location.pathname.substring(1)

  return (
    <div className="content-router">
      <ContentWrap>
        <Switch>
          <Route path="/" component={LandingPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/redirect" component={RedirectPage} />
          <Route path="/complete" component={CompletePage} />

          {/* Default route in a switch */}
          <Route>
            <Route path="/" component={LandingPage} />
          </Route>
        </Switch>
      </ContentWrap>

      {MOBILE_NAV_MENU_ROUTES.includes(path) && <MobileNavMenu items={[]} />}
    </div>
  )
})
