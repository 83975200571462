import axios from 'axios'
import { action, makeAutoObservable, runInAction } from 'mobx'
import {
  MerchantDataInterface,
  SphonicDataInterface,
  blankMerchantData,
  blankSphonicData,
} from '../types/onboardingTypes'

export class OnboardingStateStore {
  rootStore
  merchantId: string = ''
  merchantData: MerchantDataInterface = blankMerchantData
  sphonicData: SphonicDataInterface = blankSphonicData

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore
  }

  @action
  async getMerchant(merchantId: string) {
    try {
      const res = await axios(
        `https://merchant-poc-2577551f9ce2.herokuapp.com/merchant/${merchantId}`,
        {
          method: 'GET',
          withCredentials: true,
        }
      )

      runInAction(() => {
        const { sphonic, ...baseData } = res.data
        this.merchantData = baseData
        this.sphonicData = sphonic.response.SphonicResponse.data
      })
    } catch (e) {
      console.log(e)
    }
  }

  @action.bound setMerchantId(merchantId: string): void {
    this.merchantId = merchantId
  }
}
