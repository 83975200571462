import React, { useCallback, useRef } from 'react'
import useClickOutside from '../../hooks/useClickOutside'
import { Close, Container, Content, FullscreenWrap } from './Popup.styles'

interface PopupProps {
  handleVisibility: (boolean) => void
  visibility: boolean
  children: React.ReactNode
}

export const Popup = ({
  handleVisibility,
  visibility,
  children,
}: PopupProps) => {
  const popup = useRef()

  const close = useCallback(() => handleVisibility(false), [])
  useClickOutside(popup, close)

  return visibility ? (
    <FullscreenWrap>
      <Container ref={popup}>
        <Close onClick={() => handleVisibility(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M2 2L9 9L2 16"
              stroke="#FFF"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              d="M16 16L9 9L16 2"
              stroke="#FFF"
              strokeWidth="3"
              strokeLinecap="round"
            />
          </svg>
        </Close>

        <Content>{children}</Content>
      </Container>
    </FullscreenWrap>
  ) : (
    <></>
  )
}
