export const translations = {
  pageTitle: '',
  documentTypePage: {
    header: 'Hola, vamos a hacer una grabación para verificar tu identidad',
    selectCountry: 'Selecciona el país emisor del documento.',
    proceed: 'Continuar',
    chooseDocument: 'Elige el documento con el que te vas a identificar',
    ResidentPermit: 'Permiso de residencia',
    EUResidentPermit: 'Permiso de residencia en la UE',
    TravelTitle: 'Documento de viaje',
    DriverLicense: 'Licencia de conducir',
    Passport: 'Pasaporte',
    IdentityCard: 'Documento de identidad',
    inputPlaceholder: 'Selecciona',
  },
  generalConditionsPage: {
    header: 'Condiciones generales',
    privacyPolicy:
      'He leído la Política de Privacidad y consiento el tratamiento y almacenamiento de mis datos biométricos y la grabación completa del vídeo. En caso contrario, dispones de otras alternativas identificadas en los Términos y Condiciones',
    termsAndConditions:
      'Acepto los términos y condiciones del proceso de videoidentificación y la emisión de certificados de corta duración',
    required: 'condiciones requeridas',
    proceed: 'Aceptar',
    conditionTermsHeading: 'Términos y Condiciones de uso',
    conditionPrivacyHeading: 'Política de privacidad',
  },
  otpPage: {
    header: 'Introduce el código de verificación enviado a tu teléfono',
    headerVerifying: 'Por favor, espera tu verificación.',
    verify: 'Verificar',
  },
  signDocumentPage: {
    header: 'Firmar un documento',
    checkboxLabel:
      'Reconozco que he leído y entiendo las condiciones de este acuerdo tal y como se detallan más arriba.',
    proceed: 'Firmar un documento',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: 'Política de Privacidad',
    firstParagraph:
      'Bienvenido al proceso de verificación de identidad de Signicat. Signicat, con nuestra filial ElectronicID, es ',
    firstParagraphPartOne: `Bienvenido al proceso de verificación de identidad de Signicat. Signicat, con nuestra filial ElectronicID, es `,
    serviceProvider: 'un proveedor de servicios',
    firstParagraphPartTwo:
      ' de confianza certificado por la UE y llevará a cabo su identificación y firma electrónica para su incorporación.',
    secondParagraph:
      'Su proceso de identificación se realizará de forma completamente digital y sin necesidad de hablar con un agente. \n' +
      'Para completar el proceso, necesitaremos',
    thirdParagraph:
      '¿Acceso a su banca en línea? Sí, correcto. Como medida de seguridad adicional, estamos obligados por el regulador a iniciar una transferencia de 0,01 EUR desde tu cuenta bancaria y comprobar si el nombre de la cuenta coincide con tu identidad.',
    fourthParagraph:
      '¿Listo para empezar? Estupendo, acepte nuestra política de privacidad para que podamos empezar.',
    consent:
      'Autorizo el uso de mis datos personales para identificarme con los servicios de verificación de Signicat Open Banking.',
    proceed: 'Confirmar',
    required: 'condicione requeridas',
    whatYouNeed: '¿Qué es lo que necesitas?',
    wellLitPlace: 'Buena iluminación',
    originalDocument: 'Su documento nacional de identidad o pasaporte',
    stableConnection: 'Conexión estable a Internet',
    onlineBanking: 'Acceso a su banca en línea',
    cameraAccess: 'Acceso a la cámara de su dispositivo',
  },
  userDataPage: {
    header: 'Por favor, proporciona información de contacto',
    email: 'E-mail:',
    phone: 'Teléfono:',
    proceed: 'Confirmar',
  },
  videoOnboardingPage: {
    header: '¿Qué es lo que necesitas?',
    wellLitPlace: 'Un lugar con buena iluminación.',
    originalDocument: 'Tu documento original, válido y sin funda.',
    wifi: 'Conexión Wi-Fi o cobertura 4G máxima.',
    start: 'Continuar',
  },
  documentType: {
    selectCountry: 'Selecciona el país emisor del documento',
    ResidentPermit: 'Permiso de residencia',
    EUResidentPermit: 'Permiso de residencia en la UE',
    IdentityCard: 'Documento de identidad',
  },
  progressPage: {
    heading: 'Verificación de identidad',
    wait: 'Ahora estamos verificando tu identidad. Normalmente, tardamos menos de 3 minutos.',
    waitMore: 'Gracias por tu paciencia. Ya casi está.',
  },
  jitPayWelcomePage: {
    heading: 'Bienvenido al proceso de verificación de identidad de Signicat',
    descriptionPartOne:
      'Signicat, con nuestra filial ElectronicID, es un proveedor de servicios de confianza certificado por la UE: ',
    serviceProvider: 'proveedor de servicios',
    descriptionPartTwo:
      ' y realizará tu identificación y firma electrónica para tu onboarding.',
    idData:
      'Autorizo el uso de mis datos personales para identificarme en los servicios de verificación de Signicat Open Banking.',
    privacyPolicy:
      'He leído la Política de Privacidad y consiento el tratamiento y almacenamiento de mis datos biométricos y la grabación completa del vídeo. En caso contrario, dispones de otras alternativas identificadas en los Términos y Condiciones',
    termsAndConditions:
      'Acepto los términos y condiciones del proceso de videoidentificación y la emisión de certificados de corta duración',
    proceed: 'Confirmar',
    stepOne: 'Firma',
    stepTwo: 'Verificación de identidad',
    stepThree: 'Transferencia de 1 céntimo',
    stepThreeDesc: 'Exigido por el regulador',
  },
  jitPayOnboardingPage: {
    heading: 'Para completar el proceso, sigue estos tres pasos:',
    signingHeader: '1. Firma',
    signingContent: 'Revisar y firmar el contrato.',
    idVerifyHeader: '2. Verificación de identidad',
    idVerifyContent:
      'Tu proceso de identificación se realizará de forma totalmente digital y sin necesidad de hablar con un agente.',
    micropaymentHeader: '3. Micropago',
    micropaymentContent:
      'Transferencia de 0,01 EUR desde tu cuenta bancaria personal.',
    proceed: 'Confirmar',
  },
  jitPayVideoOnboardingPage: {
    header: 'Qué necesitarás Para el proceso VideoID:',
    lightHeading: 'Buena iluminación',
    lightContent: 'Asegúrate de que estás en un entorno bien iluminado.',
    netHeading: 'Conexión a Internet estable',
    netContent: 'Asegúrate de tener una buena conexión a Internet.',
    deviceHeading: 'Acceso a la cámara de tu dispositivo',
    deviceContent: 'Autoriza el acceso a la cámara de tu dispositivo.',
    oneCameraHeading: 'Utiliza sólo una cámara',
    oneCameraContent:
      'Asegúrate de tener sólo un dispositivo de cámara conectado a tu ordenador de sobremesa. Si utilizas un portátil, la cámara integrada es la que debes utilizar.',
    proceed: 'Continuar',
  },
  micropaymentInfoJitpayPage: {
    header: 'Micropago desde tu cuenta bancaria personal',
    content:
      'Como medida de seguridad adicional, estamos obligados por el regulador a iniciar una transferencia de 0,01 EUR desde tu cuenta bancaria personal y comprobar si el nombre en la cuenta coincide con tu identidad.',
    warning: 'Por favor, utiliza tu cuenta bancaria personal',
    proceed: 'Confirmar',
    idData:
      'Autorizo el uso de mis datos personales para identificarme en los servicios de verificación de Signicat Open Banking.',
  },
  micropaymentInfoIkanoPage: {
    content:
      'Como medida de seguridad adicional, estamos obligados por el regulador a iniciar una transferencia de 0,01 EUR desde tu cuenta bancaria personal y comprobar si el nombre en la cuenta coincide con tu identidad.',
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'He leído la Política de Privacidad y consiento el tratamiento y almacenamiento de mis datos biométricos y la grabación completa del vídeo. En caso contrario, dispones de otras alternativas identificadas en los Términos y Condiciones',
    terms:
      'Acepto los términos y condiciones del proceso de videoidentificación y la emisión de certificados de corta duración',
  },
  ikanoWelcomePage: {
    heading: 'Bienvenido al proceso de verificación de identidad de Signicat',
    descriptionPartOne:
      'Signicat, con nuestra filial ElectronicID, es un proveedor de servicios de confianza certificado por la UE: ',
    serviceProvider: 'proveedor de servicios',
    descriptionPartTwo:
      ' y realizará tu identificación y firma electrónica para tu onboarding.',
    idData:
      'Autorizo el uso de mis datos personales para identificarme en los servicios de verificación de Signicat Open Banking.',
    privacyPolicy:
      'He leído la Política de Privacidad y consiento el tratamiento y almacenamiento de mis datos biométricos y la grabación completa del vídeo. En caso contrario, dispones de otras alternativas identificadas en los Términos y Condiciones',
    termsAndConditions:
      'Acepto los términos y condiciones del proceso de videoidentificación y la emisión de certificados de corta duración',
    proceed: 'Confirmar',
    stepOne: 'Firma',
    stepTwo: 'Verificación de identidad',
    stepThree: 'Transferencia de 1 céntimo',
    stepThreeDesc: 'Exigido por el regulador',
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign a document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign a document',
  },
  introKontomatikPage: {
    header: 'Verificación de direcciones',
    intro:
      'En el siguiente paso, verificaremos su dirección. Le pediremos que seleccione su banco de la lista e inicie sesión en su cuenta personal.',
    accountInformation: 'Obtendremos la siguiente información de la cuenta:',
    names: 'Nombre y apellidos - del titular o titulares de la cuenta',
    address: 'Dirección - del titular o titulares de la cuenta',
    ownership: 'Titularidad de la cuenta: propietario, cotitular, etc.',
    verificationPurposes:
      'De los datos anteriores vamos a utilizar <b>sólo su dirección</b> para la verificación.',
    proceed: 'Continuar',
  },
  addressUploadPageKontomatik: {
    verifying: 'Verificando...',
    header: 'Subir dirección',
    description:
      'No pudimos obtener la dirección de la información de su cuenta bancaria.',
    confirmation:
      'Para confirmar su dirección le pediremos que cargue los extractos bancarios en los que figure su dirección.',
    bankStatementContains:
      'Asegúrese de que su extracto bancario contiene su dirección',
    yourAddress: ' su dirección',
    proceed: 'Continuar',
    dragAndDrop: 'Arrastrar y soltar o ',
    chooseFile: 'elegir archivo',
    pko: {
      header: 'PKO BP Extracto bancario',
      confirmation:
        'Para confirmar su dirección le pediremos que cargue los extractos bancarios en los que figure su dirección.',
      whereToFindStatements: '¿Dónde encontrar extractos bancarios en PKO BP?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
