export const translations = {
  pageTitle: '',
  documentTypePage: {
    header:
      'Pozdravljeni, za preverjanje vaše identitete bomo posneli posnetek.',
    description: 'Izberite dokument, s katerim se boste identificirali.',
    proceed: 'Nadaljuj',
    selectCountry: 'Izberite državo, ki je izdala dokument.',
    chooseDocument: 'Izberite vrsto dokumenta',
    ResidentPermit: 'Dovoljenje za prebivanje',
    EUResidentPermit: 'Dovoljenje za prebivanje v EU',
    TravelTitle: 'Naslov potovanja',
    DriverLicense: 'Vozniško dovoljenje',
    Passport: 'Potni list',
    IdentityCard: 'Osebna izkaznica',
    inputPlaceholder: 'Izberite',
  },
  generalConditionsPage: {
    header: 'Splošni pogoji',
    termsAndConditions: 'Sprejemam pogoje uporabe storitve.*',
    privacyPolicy: 'Sprejemam politiko zasebnosti.*',
    required: '* zahtevani pogoji',
    proceed: 'Sprejmite',
    conditionTermsHeading: 'Pogoji uporabe',
    conditionPrivacyHeading: 'Pravilnik o zasebnosti',
  },
  signDocumentPage: {
    header: 'Podpišite dokument',
    checkboxLabel:
      'Potrjujem, da sem prebral in razumem zgoraj navedene pogoje te pogodbe.',
    proceed: 'Podpišite dokument',
  },
  videoOnboardingPage: {
    header: 'Kaj potrebujete?',
    wellLitPlace: 'Dobro osvetljen prostor.',
    originalDocument: 'Vaš izvirni dokument, ki je veljaven in brez ovitkov.',
    wifi: 'Povezava Wi-Fi ali največja pokritost 4G.',
    start: 'Nadaljuj',
  },
  documentType: {
    selectCountry: 'Izberite državo, ki je izdala dokument.',
    ResidentPermit: 'Dovoljenje za prebivanje',
    EUResidentPermit: 'Dovoljenje za prebivanje v EU',
    TravelTitle: 'Naslov potovanja',
    IdentityCard: 'Osebna izkaznica',
  },
  otpPage: {
    verify: 'Preveri',
    header: 'Vnesite kodo za preverjanje, ki je bila poslana v vaš telefon.',
    headerVerifying: 'Počakajte na preverjanje.',
  },
  userDataPage: {
    header: 'Navedite kontaktne podatke',
    proceed: 'Potrdite',
    email: 'E-pošta:',
    phone: 'Telefon:',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: 'Preverjanje identitete',
    firstParagraph:
      'Dobrodošli v postopku preverjanja identitete Signicat. Signicat je s svojo hčerinsko družbo ElectronicID certificiran ponudnik zaupanja vrednih storitev EU [https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42] in bo izvajal vašo identifikacijo in elektronsko podpisovanje za vašo vključitev v sistem.',
    firstParagraphPartOne:
      'Dobrodošli v postopku preverjanja identitete Signicat. Signicat je s svojo hčerinsko družbo ElectronicID certificiran zaupanja vreden organ EU ',
    serviceProvider: 'ponudnik storitev',
    firstParagraphPartTwo:
      ' in bo opravil vašo identifikacijo in elektronski podpis za vašo vključitev v sistem.',
    secondParagraph:
      'Postopek identifikacije bo potekal popolnoma digitalno in brez pogovora z agentom. Za dokončanje postopka bomo potrebovali:',
    thirdParagraph:
      'Dostop do spletnega bančništva? Da, pravilno. Kot dodaten varnostni ukrep smo po zahtevi regulatorja dolžni sprožiti prenos 0,01 EUR z vašega bančnega računa in preveriti, ali se ime na računu ujema z vašo identiteto.\\n',
    fourthParagraph:
      'Ste pripravljeni na odhod? Odlično, sprejmite naš pravilnik o zasebnosti, da lahko začnemo.',
    consent:
      'Soglašam z uporabo svojih osebnih podatkov za identifikacijo s storitvami Signicat Open Banking Verification.*',
    required: '* zahtevani pogoj',
    proceed: 'Potrdite',
    whatYouNeed: 'Kaj potrebujete?',
    wellLitPlace: 'Dobra osvetlitev',
    originalDocument: 'vaša osebna izkaznica ali potni list',
    stableConnection: 'Stabilne internetne povezave',
    onlineBanking: 'Dostop do spletnega bančništva',
    cameraAccess: 'Dostop do kamere naprave',
  },
  progressPage: {
    heading: 'Preverjanje identitete',
    wait: 'Zdaj preverjamo vašo identiteto. Običajno traja manj kot 3 minute.',
    waitMore:
      'Zahvaljujemo se vam za potrpežljivost. Skoraj končano. Prosimo, ne zapirajte brskalnika.',
  },
  jitPayWelcomePage: {
    heading: 'Dobrodošli v procesu preverjanja identitete Signicat',
    descriptionPartOne:
      'Signicat je s svojo hčerinsko družbo ElectronicID certificiran zaupanja vreden ',
    serviceProvider: 'ponudnik storitev',
    descriptionPartTwo:
      ' in bo opravil vašo identifikacijo in elektronski podpis za vašo vključitev v sistem.',
    idData:
      'Soglašam z uporabo svojih osebnih podatkov za identifikacijo s storitvami Signicat Open Banking Verification.',
    termsAndConditions: 'Sprejemam pogoje uporabe storitve.',
    privacyPolicy: 'Sprejemam politiko zasebnosti.',
    proceed: 'Potrdite',
    stepOne: 'Podpisovanje',
    stepTwo: 'Preverjanje identitete',
    stepThree: 'Prenos 1 centa',
    stepThreeDesc: 'Zahteva regulatorja',
  },
  jitPayOnboardingPage: {
    heading: 'Za dokončanje postopka sledite naslednjim trem korakom:',
    signingHeader: '1. Podpisovanje',
    signingContent: 'Preglejte in podpišite pogodbo.',
    idVerifyHeader: '2. Preverjanje identitete',
    idVerifyContent:
      'Postopek identifikacije bo potekal popolnoma digitalno in brez pogovora z agentom.',
    micropaymentHeader: '3. Mikroplačila',
    micropaymentContent: '0,01 EUR z vašega osebnega bančnega računa.',
    proceed: 'Potrdite',
  },
  jitPayVideoOnboardingPage: {
    header: 'Kaj potrebujete za postopek VideoID:',
    lightHeading: 'Dobra osvetlitev',
    lightContent: 'Prepričajte se, da ste v dobro osvetljenem okolju.',
    netHeading: 'Stabilna internetna povezava',
    netContent: 'Prepričajte se, da imate močno internetno povezavo.',
    deviceHeading: 'Dostop do naprave s kamero',
    deviceContent: 'Odobrite dostop do naprave s kamero.',
    oneCameraHeading: 'Uporabite samo en fotoaparat',
    oneCameraContent:
      'Prepričajte se, da je v namizno napravo priključena samo ena naprava s fotoaparatom. Če uporabljate prenosni računalnik, morate uporabiti vgrajeno kamero.',
    proceed: 'Nadaljuj',
  },
  micropaymentInfoJitpayPage: {
    header: 'Mikroplačilo z vašega osebnega bančnega računa',
    content:
      'Kot dodaten varnostni ukrep nam regulator nalaga, da sprožimo prenos 0,01 EUR z vašega osebnega bančnega računa in preverimo, ali se ime na računu ujema z vašo identiteto.',
    warning: 'Uporabite svoj osebni bančni račun',
    proceed: 'Potrdite',
    idData:
      'Soglašam z uporabo svojih osebnih podatkov za identifikacijo s storitvami Signicat Open Banking Verification.',
  },
  micropaymentInfoIkanoPage: {
    content:
      'Kot dodaten varnostni ukrep nam regulator nalaga, da sprožimo prenos 0,01 EUR z vašega osebnega bančnega računa in preverimo, ali se ime na računu ujema z vašo identiteto.',
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    terms:
      'I accept the terms and conditions of the video-identification process and the issuance of short- term certificates',
  },
  ikanoWelcomePage: {
    heading: 'Dobrodošli v procesu preverjanja identitete Signicat',
    descriptionPartOne:
      'Signicat je s svojo hčerinsko družbo ElectronicID certificiran zaupanja vreden ',
    serviceProvider: 'ponudnik storitev',
    descriptionPartTwo:
      ' in bo opravil vašo identifikacijo in elektronski podpis za vašo vključitev v sistem.',
    idData:
      'Soglašam z uporabo svojih osebnih podatkov za identifikacijo s storitvami Signicat Open Banking Verification.',
    termsAndConditions: 'Sprejemam pogoje uporabe storitve.',
    privacyPolicy: 'Sprejemam politiko zasebnosti.',
    proceed: 'Potrdite',
    stepOne: 'Podpisovanje',
    stepTwo: 'Preverjanje identitete',
    stepThree: 'Prenos 1 centa',
    stepThreeDesc: 'Zahteva regulatorja',
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign a document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign a document',
  },
  introKontomatikPage: {
    header: 'Preverjanje naslova',
    intro:
      'V naslednjem koraku bomo preverili vaš naslov. Prosili vas bomo, da s seznama izberete svojo banko in se prijavite v svoj osebni račun.',
    accountInformation: 'Pridobili bomo naslednje podatke o računu:',
    names: 'Ime in priimek - lastnika(-ov) računa',
    address: 'Naslov - lastnika(-ov) računa',
    ownership: 'lastništvo računa - lastnik, solastnik itd.',
    verificationPurposes:
      'Iz zgornjih podatkov bomo za preverjanje uporabili <b>samo vaš naslov</b>.',
    proceed: 'Nadaljuj',
  },
  addressUploadPageKontomatik: {
    verifying: 'Preverjanje...',
    header: 'Prenos naslova',
    description:
      'Iz podatkov o vašem bančnem računu nismo mogli pridobiti naslova.',
    confirmation:
      'Za potrditev naslova vas bomo prosili, da naložite bančne izpiske, ki vsebujejo vaš naslov.',
    bankStatementContains:
      'Prepričajte se, da je na bančnem izpisku naveden vaš naslov.',
    yourAddress: ' vaš naslov',
    proceed: 'Nadaljuj',
    dragAndDrop: 'Povlecite in spustite ali ',
    chooseFile: 'izberite datoteko',
    pko: {
      header: 'PKO BP Bančni izpisek',
      confirmation:
        'Za potrditev naslova vas bomo prosili, da naložite bančne izpiske, ki vsebujejo vaš naslov.',
      whereToFindStatements: 'Kje najti bančne izpiske v PKO BP?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
