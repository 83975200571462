export interface SphonicDataInterface {
  RequestDetails?: {
    CompanyName?: string
    Address?: string
    Website?: string
    Phone?: string
  }
  Company?: {
    CompanyName?: string
    CompanyNumber?: string
    CompanyFound?: string
    CompanyStatus?: string
    AddressFound?: string
  }
  RegisteredAddress?: {
    RegisteredAddressLine1?: string
    RegisteredAddressLine2?: string
    RegisteredAddressCity?: string
    RegisteredAddressPostcode?: string
    RegisteredAddressCountry?: string
  }
  TradingAddress?: {
    TradingAddressLine1?: string
    TradingAddressLine2?: string
    TradingAddressCity?: string
    TradingAddressPostcode?: string
    TradingAddressCountry?: string
  }
  IncorporationDate?: {
    IncorporationDate?: string
  }
  Turnover?: {
    Turnover?: string
  }
  WebsiteProfile?: {
    WebsiteProfileFound?: string
    WebsiteProfileLegalName?: string
    WebsiteProfileIndustry?: string
    WebsiteProfileIndustryGroup?: string
    WebsiteProfilegicsCode?: string
    WebsiteProfilesicCode?: string
    WebsiteProfilenaicsCode?: string
    WebsiteProfileSector?: string
    WebsiteProfileDescription?: string
    WebsiteProfileLocation?: string
    WebsiteProfileFoundedYear?: string
    WebsiteProfileEmployeeRange?: string
    WebsiteProfileEstimatedRevenue?: string
    WebsiteProfileWebPopularity?: string
  }
  Phone?: {
    PhoneRecommendation?: string
    PhoneRisk?: string
    PhoneScore?: string
    PhoneType?: string
    PhoneCountry?: string
    PhoneCarrier?: string
  }
  Sanction?: {
    SanctionMatched?: string
    SanctionScore?: string
    SanctionList?: string
    SanctionUrlEvidence?: string
  }
  Pep?: {
    PepMatched?: string
    PepScore?: string
  }
  AdverseMedia?: {
    AdverseMediaMatched?: string
    AdverseMediaScore?: string
    AdverseMediaUrlEvidence?: string
  }
  Credit?: {
    CreditResult?: string
    CreditScore?: string
  }
  ActivityClass?: {
    ActivityClassCode?: string
    ActivityClassDescription?: string
  }
  UBO?: {
    UBO1Name?: string
    UBO2Name?: string
    UBO3Name?: string
    UBO4Name?: string
  }
  FinancialStatement?: {
    depreciation?: string
    amortisation?: string
    otherAppropriations?: string
    totalTangibleAssets?: string
    totalIntangibleAssets?: string
    totalOtherFixedAssets?: string
    totalFixedAssets?: string
    totalInventories?: string
    tradeReceivables?: string
    miscellaneousReceivables?: string
    totalReceivables?: string
    cash?: string
    otherCurrentAssets?: string
    totalCurrentAssets?: string
    totalAssets?: string
    tradePayables?: string
    bankLiabilities?: string
    otherLoansOrFinance?: string
    miscellaneousLiabilities?: string
    totalCurrentLiabilities?: string
    bankLiabilitiesDueAfter1Year?: string
    otherLoansOrFinanceDueAfter1Year?: string
    miscellaneousLiabilitiesDueAfter1Year?: string
    totalLongTermLiabilities?: string
    totalLiabilities?: string
    calledUpShareCapital?: string
    revenueReserves?: string
    otherReserves?: string
    totalShareholdersEquity?: string
  }
  Directors?: {
    Director1?: string
    Director1Address?: string
    Director1Dob?: string
    Director2?: string
    Director2Address?: string
    Director2Dob?: string
    Director3?: string
    Director3Address?: string
    Director3Dob?: string
    Director4?: string
    Director4Address?: string
    Director4Dob?: string
    Director5?: string
    Director5Address?: string
    Director5Dob?: string
  }
}

export interface MerchantDataInterface {
  _id?: string
  id?: string
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
  companyName?: string
  companyNumber?: string
  companyCountry?: string
  companyWebsite?: string
  status?: string
  sphonic?: {
    requestDetails?: any
    response?: {
      SphonicReponse: {
        data: SphonicDataInterface
      }
    }
  }
}

export const blankSphonicData = {
  RequestDetails: {
    CompanyName: '',
    Address: '',
    Website: '',
    Phone: '',
  },
  Company: {
    CompanyName: '',
    CompanyNumber: '',
    CompanyFound: '',
    CompanyStatus: '',
    AddressFound: '',
  },
  RegisteredAddress: {
    RegisteredAddressLine1: '',
    RegisteredAddressLine2: '',
    RegisteredAddressCity: '',
    RegisteredAddressPostcode: '',
    RegisteredAddressCountry: '',
  },
  TradingAddress: {
    TradingAddressLine1: '',
    TradingAddressLine2: '',
    TradingAddressCity: '',
    TradingAddressPostcode: '',
    TradingAddressCountry: '',
  },
  IncorporationDate: {
    IncorporationDate: '',
  },
  Turnover: {
    Turnover: '',
  },
  WebsiteProfile: {
    WebsiteProfileFound: '',
    WebsiteProfileLegalName: '',
    WebsiteProfileIndustry: '',
    WebsiteProfileIndustryGroup: '',
    WebsiteProfilegicsCode: '',
    WebsiteProfilesicCode: '',
    WebsiteProfilenaicsCode: '',
    WebsiteProfileSector: '',
    WebsiteProfileDescription: '',
    WebsiteProfileLocation: '',
    WebsiteProfileFoundedYear: '',
    WebsiteProfileEmployeeRange: '',
    WebsiteProfileEstimatedRevenue: '',
    WebsiteProfileWebPopularity: '',
  },
  Phone: {
    PhoneRecommendation: '',
    PhoneRisk: '',
    PhoneScore: '',
    PhoneType: '',
    PhoneCountry: '',
    PhoneCarrier: '',
  },
  Sanction: {
    SanctionMatched: '',
    SanctionScore: '',
    SanctionList: '',
    SanctionUrlEvidence: '',
  },
  Pep: {
    PepMatched: '',
    PepScore: '',
  },
  AdverseMedia: {
    AdverseMediaMatched: '',
    AdverseMediaScore: '',
    AdverseMediaUrlEvidence: '',
  },
  Credit: {
    CreditResult: '',
    CreditScore: '',
  },
  ActivityClass: {
    ActivityClassCode: '',
    ActivityClassDescription: '',
  },
  UBO: {
    UBO1Name: '',
    UBO2Name: '',
    UBO3Name: '',
    UBO4Name: '',
  },
  FinancialStatement: {
    depreciation: '',
    amortisation: '',
    otherAppropriations: '',
    totalTangibleAssets: '',
    totalIntangibleAssets: '',
    totalOtherFixedAssets: '',
    totalFixedAssets: '',
    totalInventories: '',
    tradeReceivables: '',
    miscellaneousReceivables: '',
    totalReceivables: '',
    cash: '',
    otherCurrentAssets: '',
    totalCurrentAssets: '',
    totalAssets: '',
    tradePayables: '',
    bankLiabilities: '',
    otherLoansOrFinance: '',
    miscellaneousLiabilities: '',
    totalCurrentLiabilities: '',
    bankLiabilitiesDueAfter1Year: '',
    otherLoansOrFinanceDueAfter1Year: '',
    miscellaneousLiabilitiesDueAfter1Year: '',
    totalLongTermLiabilities: '',
    totalLiabilities: '',
    calledUpShareCapital: '',
    revenueReserves: '',
    otherReserves: '',
    totalShareholdersEquity: '',
  },
  Directors: {
    Director1: '',
    Director1Address: '',
    Director1Dob: '',
    Director2: '',
    Director2Address: '',
    Director2Dob: '',
    Director3: '',
    Director3Address: '',
    Director3Dob: '',
    Director4: '',
    Director4Address: '',
    Director4Dob: '',
    Director5: '',
    Director5Address: '',
    Director5Dob: '',
  },
}

export const blankMerchantData = {
  _id: '',
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  companyName: '',
  companyNumber: '',
  companyCountry: '',
  companyWebsite: '',
  status: '',
}
