export const translations = {
  pageTitle: '',
  documentTypePage: {
    header:
      'Bună ziua, vom face o înregistrare pentru a verifica identitatea dvs.',
    description: 'Alegeți documentul cu care vă veți identifica',
    proceed: 'Continuați',
    selectCountry: 'Selectați țara care a emis documentul',
    chooseDocument: 'Alegeți tipul de document',
    ResidentPermit: 'Permis de rezident',
    EUResidentPermit: 'Permis de rezidență UE',
    TravelTitle: 'Titlu de călătorie',
    DriverLicense: 'Permis de conducere',
    Passport: 'Pașaport',
    IdentityCard: 'Carte de identitate',
    inputPlaceholder: 'Selectați',
  },
  generalConditionsPage: {
    header: 'Condiții generale',
    termsAndConditions:
      'Accept termenii și condițiile de utilizare a serviciului.*',
    privacyPolicy: 'Accept politica de confidențialitate.*',
    required: '* condiții necesare',
    proceed: 'Acceptă',
    conditionTermsHeading: 'Termeni și condiții de utilizare',
    conditionPrivacyHeading: 'Politica de confidențialitate',
  },
  signDocumentPage: {
    header: 'Semnați un document',
    checkboxLabel:
      'Recunosc că am citit și am înțeles termenii acestui acord, așa cum sunt detaliați mai sus.',
    proceed: 'Semnați un document',
  },
  videoOnboardingPage: {
    header: 'De ce aveți nevoie?',
    wellLitPlace: 'Un loc bine luminat.',
    originalDocument: 'Documentul dvs. original, valabil și fără acoperire.',
    wifi: 'Conexiune Wi-Fi sau acoperire maximă 4G.',
    start: 'Continuați',
  },
  documentType: {
    selectCountry: 'Selectați țara care a emis documentul',
    ResidentPermit: 'Permis de rezident',
    EUResidentPermit: 'Permis de rezidență UE',
    TravelTitle: 'Titlu de călătorie',
    IdentityCard: 'Carte de identitate',
  },
  otpPage: {
    verify: 'Verifică',
    header: 'Introduceți codul de verificare trimis pe telefon',
    headerVerifying: 'Vă rugăm să așteptați verificarea dumneavoastră.',
  },
  userDataPage: {
    header: 'Vă rugăm să furnizați informațiile de contact',
    proceed: 'Confirmați',
    email: 'E-mail:',
    phone: 'Telefon:',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: 'Verificarea identității',
    firstParagraph:
      'Bine ați venit la procesul de verificare a identității Signicat. Signicat, împreună cu filiala noastră ElectronicID, este un furnizor de servicii de încredere certificat de UE [https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42] și va efectua identificarea și semnarea electronică pentru aderarea dumneavoastră la',
    firstParagraphPartOne:
      'Bine ați venit la procesul de verificare a identității Signicat. Signicat, împreună cu filiala noastră ElectronicID, este o companie de încredere certificată de UE ',
    serviceProvider: 'furnizor de servicii',
    firstParagraphPartTwo:
      ' și va efectua identificarea și semnarea electronică pentru a vă îmbarca',
    secondParagraph:
      'Procesul de identificare va fi realizat complet digital și fără a fi nevoie să vorbiți cu un agent. Pentru a finaliza procesul, vom avea nevoie de:',
    thirdParagraph:
      'Accesul la serviciile bancare online? Da, corect. Ca măsură de securitate suplimentară, suntem obligați de autoritatea de reglementare să inițiem un transfer de 0,01 EUR din contul dvs. bancar și să verificăm dacă numele din cont corespunde identității dvs.\\n',
    fourthParagraph:
      'Sunteți gata de plecare? Minunat, vă rugăm să acceptați politica noastră de confidențialitate pentru a putea începe.',
    consent:
      'Sunt de acord cu utilizarea datelor mele personale pentru a mă identifica cu serviciile de verificare Signicat Open Banking.*',
    required: '* condiție necesară',
    proceed: 'Confirmați',
    whatYouNeed: 'De ce aveți nevoie?',
    wellLitPlace: 'Iluminat bun',
    originalDocument:
      'Actul național de identitate sau pașaportul dumneavoastră',
    stableConnection: 'Conexiuni stabile la internet',
    onlineBanking: 'Acces la serviciile bancare online',
    cameraAccess: 'Accesul la camera dispozitivului dvs.',
  },
  progressPage: {
    heading: 'Verificarea identității',
    wait: 'Acum vă verificăm identitatea. De obicei, acest lucru durează mai puțin de 3 minute.',
    waitMore:
      'Vă mulțumim pentru răbdare. Este aproape gata. Vă rugăm să nu închideți browserul.',
  },
  jitPayWelcomePage: {
    heading: 'Bine ați venit la procesul de verificare a identității Signicat',
    descriptionPartOne:
      'Signicat, împreună cu filiala noastră ElectronicID, este o companie de încredere certificată de UE ',
    serviceProvider: 'furnizor de servicii',
    descriptionPartTwo:
      ' și va efectua identificarea și semnătura electronică pentru a vă integra.',
    idData:
      'Sunt de acord cu utilizarea datelor mele personale pentru a mă identifica cu serviciile de verificare Signicat Open Banking.',
    termsAndConditions:
      'Accept termenii și condițiile de utilizare a serviciului.',
    privacyPolicy: 'Accept politica de confidențialitate.',
    proceed: 'Confirmați',
    stepOne: 'Semnarea',
    stepTwo: 'Verificarea identității',
    stepThree: 'Transfer de 1 cent',
    stepThreeDesc: 'Cerută de autoritatea de reglementare',
  },
  jitPayOnboardingPage: {
    heading: 'Pentru a finaliza procesul, urmați acești trei pași:',
    signingHeader: '1. Semnarea',
    signingContent: 'Examinați și semnați contractul.',
    idVerifyHeader: '2. Verificarea identității',
    idVerifyContent:
      'Procesul de identificare va fi realizat complet digital și fără a fi nevoie să vorbiți cu un agent.',
    micropaymentHeader: '3. Microplăți',
    micropaymentContent:
      'Transfer de 0,01 EUR din contul dvs. bancar personal.',
    proceed: 'Confirmați',
  },
  jitPayVideoOnboardingPage: {
    header: 'De ce veți avea nevoie Pentru procesul VideoID:',
    lightHeading: 'Iluminat bun',
    lightContent: 'Asigurați-vă că vă aflați într-un mediu bine luminat.',
    netHeading: 'Conexiune stabilă la internet',
    netContent: 'Asigurați-vă că aveți o conexiune puternică la internet.',
    deviceHeading: 'Accesul la dispozitivul dumneavoastră cu cameră foto',
    deviceContent: 'Autorizați accesul la aparatul foto.',
    oneCameraHeading: 'Utilizați o singură cameră',
    oneCameraContent:
      'Vă rugăm să vă asigurați că aveți un singur dispozitiv de cameră conectat la dispozitivul desktop. Dacă folosiți un laptop, camera încorporată este cea mai potrivită pentru a fi utilizată.',
    proceed: 'Continuați',
  },
  micropaymentInfoJitpayPage: {
    header: 'Microplata din contul dvs. bancar personal',
    content:
      'Ca măsură de securitate suplimentară, suntem obligați de autoritatea de reglementare să inițiem un transfer de 0,01 EUR din contul dvs. bancar personal și să verificăm dacă numele din cont corespunde identității dvs.',
    warning: 'Vă rugăm să folosiți contul dvs. bancar personal',
    proceed: 'Confirmați',
    idData:
      'Sunt de acord cu utilizarea datelor mele personale pentru a mă identifica cu serviciile de verificare Signicat Open Banking.',
  },
  micropaymentInfoIkanoPage: {
    content:
      'Ca măsură de securitate suplimentară, suntem obligați de autoritatea de reglementare să inițiem un transfer de 0,01 EUR din contul dvs. bancar personal și să verificăm dacă numele din cont corespunde identității dvs.',
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    terms:
      'I accept the terms and conditions of the video-identification process and the issuance of short- term certificates',
  },
  ikanoWelcomePage: {
    heading: 'Bine ați venit la procesul de verificare a identității Signicat',
    descriptionPartOne:
      'Signicat, împreună cu filiala noastră ElectronicID, este o companie de încredere certificată de UE ',
    serviceProvider: 'furnizor de servicii',
    descriptionPartTwo:
      ' și va efectua identificarea și semnătura electronică pentru a vă integra.',
    idData:
      'Sunt de acord cu utilizarea datelor mele personale pentru a mă identifica cu serviciile de verificare Signicat Open Banking.',
    termsAndConditions:
      'Accept termenii și condițiile de utilizare a serviciului.',
    privacyPolicy: 'Accept politica de confidențialitate.',
    proceed: 'Confirmați',
    stepOne: 'Semnarea',
    stepTwo: 'Verificarea identității',
    stepThree: 'Transfer de 1 cent',
    stepThreeDesc: 'Cerută de autoritatea de reglementare',
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign a document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign a document',
  },
  introKontomatikPage: {
    header: 'Verificarea adresei',
    intro:
      'În etapa următoare, vom verifica adresa dumneavoastră. Vă vom cere să vă selectați banca din listă și să vă conectați la contul personal.',
    accountInformation: 'Vom prelua următoarele informații despre cont:',
    names: 'Numele și prenumele - titularului (titularilor) contului',
    address: 'Adresa - a titularului (titularilor) contului',
    ownership: 'Proprietatea contului - proprietar, coproprietar etc.',
    verificationPurposes:
      'Din datele de mai sus vom folosi <b>numai adresa dvs.</b> în scopul verificării.',
    proceed: 'Continuați',
  },
  addressUploadPageKontomatik: {
    verifying: 'Verificarea...',
    header: 'Încărcare adresă',
    description:
      'Nu am putut prelua adresa din informațiile contului dvs. bancar.',
    confirmation:
      'Pentru a vă confirma adresa, vă vom cere să încărcați extrasele bancare care conțin adresa dumneavoastră.',
    bankStatementContains:
      'Asigurați-vă că extrasul de cont bancar conține adresa dvs.',
    yourAddress: ' adresa dvs.',
    proceed: 'Continuați',
    dragAndDrop: 'Drag & Drop sau ',
    chooseFile: 'alege fișierul',
    pko: {
      header: 'PKO BP Extras de cont bancar',
      confirmation:
        'Pentru a vă confirma adresa, vă vom cere să încărcați extrasele bancare care conțin adresa dumneavoastră.',
      whereToFindStatements: 'Unde pot găsi extrasele bancare în PKO BP?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
