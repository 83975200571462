export const translations = {
  pageTitle: 'pageTitle',
  documentTypePage: {
    header: 'Dobrý deň, uskutočníme nahrávku na overenie vašej totožnosti',
    description: 'Vyberte si dokument, s ktorým sa budete identifikovať',
    proceed: 'Pokračovať',
    selectCountry: 'Vyberte krajinu, ktorá dokument vydala',
    chooseDocument: 'Vyberte si typ dokumentu',
    ResidentPermit: 'Povolenie na pobyt',
    EUResidentPermit: 'Povolenie na pobyt v EÚ',
    TravelTitle: 'Cestovný titul',
    DriverLicense: 'Vodičský preukaz',
    Passport: 'Pas',
    IdentityCard: 'Preukaz totožnosti',
    inputPlaceholder: 'Vyberte',
  },
  generalConditionsPage: {
    header: 'Všeobecné podmienky',
    termsAndConditions: 'Súhlasím s podmienkami používania služby.*',
    privacyPolicy: 'Súhlasím so zásadami ochrany osobných údajov.*',
    required: '* požadované podmienky',
    proceed: 'Prijať',
    conditionTermsHeading: 'Podmienky používania',
    conditionPrivacyHeading: 'Zásady ochrany osobných údajov',
  },
  signDocumentPage: {
    header: 'Podpísať dokument',
    checkboxLabel:
      'Potvrdzujem, že som si prečítal(a) a porozumel(a) podmienkam tejto zmluvy, ako je uvedené vyššie.',
    proceed: 'Podpísať dokument',
  },
  videoOnboardingPage: {
    header: 'Čo potrebujete?',
    wellLitPlace: 'Dobre osvetlené miesto.',
    originalDocument: 'Váš originálny doklad, platný a bez obalov.',
    wifi: 'Pripojenie Wi-Fi alebo maximálne pokrytie 4G.',
    start: 'Pokračovať',
  },
  documentType: {
    selectCountry: 'Vyberte krajinu, ktorá dokument vydala',
    ResidentPermit: 'Povolenie na pobyt',
    EUResidentPermit: 'Povolenie na pobyt v EÚ',
    TravelTitle: 'Cestovný titul',
    IdentityCard: 'Preukaz totožnosti',
  },
  otpPage: {
    verify: 'Overiť',
    header: 'Zadajte overovací kód odoslaný na váš telefón',
    headerVerifying: 'Počkajte na overenie.',
  },
  userDataPage: {
    header: 'Uveďte kontaktné údaje',
    proceed: 'Potvrďte',
    email: 'E-mail:',
    phone: 'Telefón:',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: 'Overovanie totožnosti',
    firstParagraph:
      'Vitajte v procese overovania totožnosti Signicat. Spoločnosť Signicat spolu s našou dcérskou spoločnosťou ElectronicID je certifikovaným poskytovateľom dôveryhodných služieb EÚ [https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42] a bude vykonávať vašu identifikáciu a elektronické podpisovanie pre váš vstup do systému s',
    firstParagraphPartOne:
      'Vitajte v procese overovania totožnosti Signicat. Spoločnosť Signicat a jej dcérska spoločnosť ElectronicID sú dôveryhodné inštitúcie s certifikátom EÚ ',
    serviceProvider: 'poskytovateľ služieb',
    firstParagraphPartTwo:
      ' a bude vykonávať vašu identifikáciu a elektronické podpisovanie pre váš onboarding',
    secondParagraph:
      'Váš proces identifikácie bude prebiehať úplne digitálne a bez potreby rozhovoru s agentom. Na dokončenie procesu budeme potrebovať:',
    thirdParagraph:
      'Prístup k vášmu online bankovníctvu? Áno, správne. Ako dodatočné bezpečnostné opatrenie sme povinní na základe rozhodnutia regulačného orgánu iniciovať prevod 0,01 EUR z vášho bankového účtu a skontrolovať, či sa meno na účte zhoduje s vašou identitou.\\n',
    fourthParagraph:
      'Ste pripravení vyraziť? Skvelé, prijmite prosím naše zásady ochrany osobných údajov, aby sme mohli začať.',
    consent:
      'Súhlasím s použitím mojich osobných údajov na identifikáciu v rámci služieb Signicat Open Banking Verification.*',
    required: '* požadovaná podmienka',
    proceed: 'Potvrďte',
    whatYouNeed: 'Čo potrebujete?',
    wellLitPlace: 'Dobré osvetlenie',
    originalDocument: 'Váš občiansky preukaz alebo cestovný pas',
    stableConnection: 'Stabilné internetové pripojenie',
    onlineBanking: 'Prístup k vášmu online bankovníctvu',
    cameraAccess: 'Prístup k fotoaparátu zariadenia',
  },
  progressPage: {
    heading: 'Overenie totožnosti',
    wait: 'Teraz overujeme vašu totožnosť. Zvyčajne to trvá menej ako 3 minúty.',
    waitMore:
      'Ďakujeme za vašu trpezlivosť. Je to takmer hotové. Prosím, nezatvárajte prehliadač.',
  },
  jitPayWelcomePage: {
    heading: 'Vitajte v procese overovania totožnosti Signicat',
    descriptionPartOne:
      'Signicat, spolu s našou dcérskou spoločnosťou ElectronicID, je dôveryhodný certifikát EÚ ',
    serviceProvider: 'poskytovateľ služieb',
    descriptionPartTwo:
      ' a bude vykonávať vašu identifikáciu a elektronické podpisovanie pri vašom vstupe do systému.',
    idData:
      'Súhlasím s použitím mojich osobných údajov na identifikáciu prostredníctvom služieb Signicat Open Banking Verification.',
    termsAndConditions: 'Súhlasím s podmienkami používania služby.',
    privacyPolicy: 'Súhlasím so zásadami ochrany osobných údajov.',
    proceed: 'Potvrďte',
    stepOne: 'Podpisovanie',
    stepTwo: 'Overenie totožnosti',
    stepThree: 'Prevod 1 centa',
    stepThreeDesc: 'Vyžaduje to regulačný orgán',
  },
  jitPayOnboardingPage: {
    heading: 'Ak chcete proces dokončiť, postupujte podľa týchto troch krokov:',
    signingHeader: '1. Podpisovanie',
    signingContent: 'Skontrolujte a podpíšte zmluvu.',
    idVerifyHeader: '2. Overenie totožnosti',
    idVerifyContent:
      'Váš proces identifikácie bude prebiehať úplne digitálne a bez potreby rozhovoru s agentom.',
    micropaymentHeader: '3. Mikroplatby',
    micropaymentContent: '0,01 EUR prevodom z vášho osobného bankového účtu.',
    proceed: 'Potvrďte',
  },
  jitPayVideoOnboardingPage: {
    header: 'Čo budete potrebovať Na proces VideoID:',
    lightHeading: 'Dobré osvetlenie',
    lightContent: 'Uistite sa, že ste v dobre osvetlenom prostredí.',
    netHeading: 'Stabilné pripojenie na internet',
    netContent: 'Uistite sa, že máte silné internetové pripojenie.',
    deviceHeading: 'Prístup k zariadeniu s fotoaparátom',
    deviceContent: 'Autorizácia prístupu k zariadeniu s fotoaparátom.',
    oneCameraHeading: 'Používajte iba jeden fotoaparát',
    oneCameraContent:
      'Dbajte na to, aby bolo k stolovému zariadeniu pripojené len jedno zariadenie s kamerou. Ak používate prenosný počítač, správnym fotoaparátom je zabudovaný fotoaparát.',
    proceed: 'Pokračovať',
  },
  micropaymentInfoJitpayPage: {
    header: 'Mikroplatba z vášho osobného bankového účtu',
    content:
      'Ako dodatočné bezpečnostné opatrenie sme povinní iniciovať prevod 0,01 EUR z vášho osobného bankového účtu a skontrolovať, či meno na účte zodpovedá vašej totožnosti.',
    warning: 'Použite svoj osobný bankový účet',
    proceed: 'Potvrďte',
    idData:
      'Súhlasím s použitím mojich osobných údajov na identifikáciu prostredníctvom služieb Signicat Open Banking Verification.',
  },
  micropaymentInfoIkanoPage: {
    content:
      'Ako dodatočné bezpečnostné opatrenie sme povinní iniciovať prevod 0,01 EUR z vášho osobného bankového účtu a skontrolovať, či meno na účte zodpovedá vašej totožnosti.',
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    terms:
      'I accept the terms and conditions of the video-identification process and the issuance of short- term certificates',
  },
  ikanoWelcomePage: {
    heading: 'Vitajte v procese overovania totožnosti Signicat',
    descriptionPartOne:
      'Signicat, spolu s našou dcérskou spoločnosťou ElectronicID, je dôveryhodný certifikát EÚ ',
    serviceProvider: 'poskytovateľ služieb',
    descriptionPartTwo:
      ' a bude vykonávať vašu identifikáciu a elektronické podpisovanie pri vašom vstupe do systému.',
    idData:
      'Súhlasím s použitím mojich osobných údajov na identifikáciu prostredníctvom služieb Signicat Open Banking Verification.',
    termsAndConditions: 'Súhlasím s podmienkami používania služby.',
    privacyPolicy: 'Súhlasím so zásadami ochrany osobných údajov.',
    proceed: 'Potvrďte',
    stepOne: 'Podpisovanie',
    stepTwo: 'Overenie totožnosti',
    stepThree: 'Prevod 1 centa',
    stepThreeDesc: 'Vyžaduje to regulačný orgán',
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign a document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign a document',
  },
  introKontomatikPage: {
    header: 'Overenie adresy',
    intro:
      'V ďalšom kroku overíme vašu adresu. Požiadame vás, aby ste si zo zoznamu vybrali svoju banku a prihlásili sa do svojho osobného účtu.',
    accountInformation: 'Získame nasledujúce informácie o účte:',
    names: 'Meno a priezvisko - vlastníka(ov) účtu',
    address: 'Adresa - vlastníka(ov) účtu',
    ownership: 'Vlastníctvo účtu - vlastník, spolumajiteľ atď.',
    verificationPurposes:
      'Z uvedených údajov použijeme <b>na účely overenia len vašu adresu</b>.',
    proceed: 'Pokračovať',
  },
  addressUploadPageKontomatik: {
    verifying: 'Overovanie...',
    header: 'Nahrávanie adresy',
    description:
      'Nepodarilo sa nám získať adresu z informácií o vašom bankovom účte.',
    confirmation:
      'Na potvrdenie vašej adresy vás požiadame o nahratie bankových výpisov, ktoré obsahujú vašu adresu.',
    bankStatementContains:
      'Uistite sa, že váš bankový výpis obsahuje vašu adresu',
    yourAddress: ' vaša adresa',
    proceed: 'Pokračovať',
    dragAndDrop: 'Drag & Drop alebo ',
    chooseFile: 'vybrať súbor',
    pko: {
      header: 'Výpis z banky PKO BP',
      confirmation:
        'Na potvrdenie vašej adresy vás požiadame o nahratie bankových výpisov, ktoré obsahujú vašu adresu.',
      whereToFindStatements: 'Kde nájsť bankové výpisy v PKO BP?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
