export const translations = {
  pageTitle: '',
  documentTypePage: {
    header:
      'Hej, vi kommer att göra en inspelning för att verifiera din identitet',
    description: 'Välj det dokument som du ska identifiera dig med',
    proceed: 'Fortsätta',
    selectCountry: 'Välj det land som utfärdat dokumentet',
    chooseDocument: 'Välj dokumenttyp',
    ResidentPermit: 'Uppehållstillstånd',
    EUResidentPermit: 'EU-uppehållstillstånd',
    TravelTitle: 'Resans titel',
    DriverLicense: 'Körkort',
    Passport: 'Pass',
    IdentityCard: 'Identitetskort',
    inputPlaceholder: 'Välj',
  },
  generalConditionsPage: {
    header: 'Allmänna villkor',
    termsAndConditions: 'Jag accepterar villkoren för användning av tjänsten.*',
    privacyPolicy: 'Jag accepterar integritetspolicyn.*',
    required: '* nödvändiga villkor',
    proceed: 'Acceptera',
    conditionTermsHeading: 'Villkor och bestämmelser för användning',
    conditionPrivacyHeading: 'Integritetspolicy',
  },
  signDocumentPage: {
    header: 'Underteckna ett dokument',
    checkboxLabel:
      'Jag bekräftar att jag har läst och förstått villkoren i detta avtal enligt ovan.',
    proceed: 'Underteckna ett dokument',
  },
  videoOnboardingPage: {
    header: 'Vad behöver du?',
    wellLitPlace: 'En väl upplyst plats.',
    originalDocument: 'Din originalhandling, giltig och utan omslag.',
    wifi: 'Wi-Fi-anslutning eller maximal 4G-täckning.',
    start: 'Fortsätta',
  },
  videoIdPage: {
    proceed: 'Fortsätt',
  },
  mobileCodePage: {
    heading: 'Ange verifieringskod som skickas till din telefon',
    headingVerifying: 'Vänligen vänta på din bekräftelse',
  },
  documentType: {
    selectCountry: 'Välj det land som utfärdat dokumentet',
    ResidentPermit: 'Uppehållstillstånd',
    EUResidentPermit: 'EU-uppehållstillstånd',
    TravelTitle: 'Resans titel',
    IdentityCard: 'Identitetskort',
  },
  otpPage: {
    verify: 'Verifiera',
    header: 'Ange verifieringskod som skickas till din telefon',
    headerVerifying: 'Vänligen vänta på din bekräftelse.',
  },
  userDataPage: {
    header: 'Vänligen ange kontaktuppgifter',
    proceed: 'Bekräfta',
    email: 'E-post:',
    phone: 'Telefon:',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: 'Verifiering av identitet',
    firstParagraph:
      'Välkommen till Signicats process för identitetsverifiering. Signicat, med vårt dotterbolag ElectronicID, är en EU-certifierad leverantör av betrodda tjänster [https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42] och kommer att utföra din identifiering och elektroniska signering för din onboarding med',
    firstParagraphPartOne:
      'Välkommen till Signicats process för identitetsverifiering. Signicat, med vårt dotterbolag ElectronicID, är en EU-certifierad betrodd ',
    serviceProvider: 'tjänsteleverantör',
    firstParagraphPartTwo:
      ' och kommer att utföra din identifiering och elektroniska signering för din onboarding',
    secondParagraph:
      'Din identifieringsprocess kommer att utföras helt digitalt och utan att du behöver tala med en agent. För att slutföra processen behöver vi:',
    thirdParagraph:
      'Tillgång till din internetbank? Ja, det stämmer. Som en extra säkerhetsåtgärd är vi skyldiga av tillsynsmyndigheten att initiera en överföring på 0,01 EUR från ditt bankkonto och kontrollera om namnet på kontot matchar din identitet.\\n',
    fourthParagraph:
      'Är du redo att köra? Bra, vänligen acceptera vår integritetspolicy så att vi kan börja.',
    consent:
      'Jag samtycker till att mina personuppgifter används för att identifiera mig med Signicat Open Banking Verification-tjänster.*',
    required: '* erforderligt villkor',
    proceed: 'Bekräfta',
    whatYouNeed: 'Vad behöver du?',
    wellLitPlace: 'Bra belysning',
    originalDocument: 'Nationellt ID-kort eller pass',
    stableConnection: 'Stabila internetanslutningar',
    onlineBanking: 'Tillgång till din internetbank',
    cameraAccess: 'Åtkomst till din enhets kamera',
  },
  progressPage: {
    heading: 'Kontroll av identitet',
    wait: 'Nu verifierar vi din identitet. Vanligtvis tar det mindre än 3 minuter.',
    waitMore:
      'Tack för ert tålamod. Det är nästan klart. Vänligen stäng inte webbläsaren.',
  },
  jitPayWelcomePage: {
    heading: 'Välkommen till Signicats process för identitetsverifiering',
    descriptionPartOne:
      'Signicat, med vårt dotterbolag ElectronicID, är en EU-certifierad betrodd ',
    serviceProvider: 'tjänsteleverantör',
    descriptionPartTwo:
      ' och kommer att utföra din identifiering och elektroniska signering för din onboarding.',
    idData:
      'Jag samtycker till att mina personuppgifter används för att identifiera mig med Signicat Open Banking Verification-tjänster.',
    termsAndConditions: 'Jag accepterar villkoren för användning av tjänsten.',
    privacyPolicy: 'Jag accepterar integritetspolicyn.*',
    proceed: 'Bekräfta',
    stepOne: 'Signering',
    stepTwo: 'Verifiering av identitet',
    stepThree: '1 cent överföring',
    stepThreeDesc: 'Krävs av tillsynsmyndigheten',
  },
  jitPayOnboardingPage: {
    heading: 'Följ dessa tre steg för att slutföra processen:',
    signingHeader: '1. Signering',
    signingContent: 'Granska och underteckna avtalet.',
    idVerifyHeader: '2. Kontroll av identitet',
    idVerifyContent:
      'Din identifieringsprocess kommer att ske helt digitalt och utan att du behöver tala med en handläggare.',
    micropaymentHeader: '3. Mikrobetalning',
    micropaymentContent: '0,01 EUR överföring från ditt personliga bankkonto.',
    proceed: 'Bekräfta',
  },
  jitPayVideoOnboardingPage: {
    header: 'Vad du behöver för VideoID-processen:',
    lightHeading: 'Bra belysning',
    lightContent: 'Se till att du befinner dig i en väl upplyst miljö.',
    netHeading: 'Stabil internetanslutning',
    netContent: 'Se till att du har en stark internetanslutning.',
    deviceHeading: 'Tillgång till din kamerautrustning',
    deviceContent: 'Tillåt åtkomst till din kameraenhet.',
    oneCameraHeading: 'Använd endast en kamera',
    oneCameraContent:
      'Se till att endast en kameraenhet är ansluten till din stationära enhet. Om du använder en bärbar dator är den inbyggda kameran rätt kamera att använda.',
    proceed: 'Fortsätta',
  },
  micropaymentInfoJitpayPage: {
    header: 'Micropayment från ditt personliga bankkonto',
    content:
      'Som en ytterligare säkerhetsåtgärd är vi skyldiga av tillsynsmyndigheten att initiera en överföring på 0,01 EUR från ditt personliga bankkonto och kontrollera om namnet på kontot överensstämmer med din identitet.',
    warning: 'Vänligen använd ditt personliga bankkonto',
    proceed: 'Bekräfta',
    idData:
      'Jag samtycker till att mina personuppgifter används för att identifiera mig med Signicat Open Banking Verification-tjänster.',
  },
  micropaymentInfoIkanoPage: {
    content:
      'Som en ytterligare säkerhetsåtgärd är vi skyldiga av tillsynsmyndigheten att initiera en överföring på 0,01 EUR från ditt personliga bankkonto och kontrollera om namnet på kontot överensstämmer med din identitet.',
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    terms:
      'I accept the terms and conditions of the video-identification process and the issuance of short- term certificates',
  },
  ikanoWelcomePage: {
    heading: 'Välkommen till Signicats process för identitetsverifiering',
    descriptionPartOne:
      'Signicat, med vårt dotterbolag ElectronicID, är en EU-certifierad betrodd ',
    serviceProvider: 'tjänsteleverantör',
    descriptionPartTwo:
      ' och kommer att utföra din identifiering och elektroniska signering för din onboarding.',
    idData:
      'Jag samtycker till att mina personuppgifter används för att identifiera mig med Signicat Open Banking Verification-tjänster.',
    termsAndConditions: 'Jag accepterar villkoren för användning av tjänsten.',
    privacyPolicy: 'Jag accepterar integritetspolicyn.*',
    proceed: 'Bekräfta',
    stepOne: 'Signering',
    stepTwo: 'Verifiering av identitet',
    stepThree: '1 cent överföring',
    stepThreeDesc: 'Krävs av tillsynsmyndigheten',
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign a document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign a document',
  },
  introKontomatikPage: {
    header: 'Verifiering av adress',
    intro:
      'I nästa steg kommer vi att verifiera din adress. Vi kommer att be dig att välja bank från listan och logga in på ditt personliga konto.',
    accountInformation: 'Vi kommer att hämta följande kontouppgifter:',
    names: 'För- och efternamn - på kontoinnehavare',
    address: 'Adress - till kontoinnehavare',
    ownership: 'Ägande av konto - ägare, delägare etc.',
    verificationPurposes:
      'Från uppgifterna ovan kommer vi att använda <b>endast din adress</b> för verifieringsändamål.',
    proceed: 'Fortsätta',
  },
  addressUploadPageKontomatik: {
    verifying: 'Verifiering...',
    header: 'Uppladdning av adress',
    description: 'Vi kunde inte hämta adressen från din bankkontoinformation.',
    confirmation:
      'För att bekräfta din adress ber vi dig att ladda upp de kontoutdrag som innehåller din adress.',
    bankStatementContains: 'Se till att ditt kontoutdrag innehåller din adress',
    yourAddress: ' din adress',
    proceed: 'Fortsätta',
    dragAndDrop: 'Dra och släpp eller ',
    chooseFile: 'Välj fil',
    pko: {
      header: 'PKO BP Bankutdrag',
      confirmation:
        'För att bekräfta din adress ber vi dig att ladda upp de kontoutdrag som innehåller din adress.',
      whereToFindStatements: 'Var hittar jag kontoutdrag i PKO BP?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
