import { translations as de } from './de'
import { translations as da } from './da'
import { translations as en } from './en'
import { translations as es } from './es'
import { translations as bg } from './bg'
import { translations as cs } from './cs'
import { translations as el } from './el'
import { translations as fi } from './fi'
import { translations as fr } from './fr'
import { translations as hu } from './hu'
import { translations as it } from './it'
import { translations as nl } from './nl'
import { translations as no } from './no'
import { translations as pl } from './pl'
import { translations as pt } from './pt'
import { translations as ro } from './ro'
import { translations as sk } from './sk'
import { translations as sl } from './sl'
import { translations as sv } from './sv'

export {
  de,
  da,
  en,
  es,
  bg,
  cs,
  el,
  fi,
  fr,
  hu,
  it,
  nl,
  no,
  pl,
  pt,
  ro,
  sk,
  sl,
  sv,
}
