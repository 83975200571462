import styled from 'styled-components'
import { mobile, tablet } from '../../constants/breakpoints'

export const FullscreenWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);

  @media (max-width: ${mobile}) {
    top: 72px;
  }
`

export const Container = styled.div`
  width: 573px;
  margin: calc(50vh - 275px) auto;
  background: #131332;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 60px 70px;
  position: relative;

  @media (max-width: ${mobile}) {
    margin: 30px auto;
    width: 100%;
    margin: 0;
    border-radius: 0;
    padding: 50px 36px 38px 36px;
    /* height: 100vh; */
  }
`

export const Content = styled.div`
  @media (min-width: calc(${mobile} + 1px)) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`

export const Close = styled.div.attrs({ className: 'popup-close' })`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`
