export const mobile = '768px'
export const tablet = '1024px'
export const desktop = '1440px'
export const desktopL = '2560px'

export const breakpoints = {
  mobile,
  tablet,
  desktop,
  desktopL,
}
