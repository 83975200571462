export const translations = {
  pageTitle: '',
  completedPage: {
    heading: 'Завършен',
    description: 'Процесът на идентификация е извършен',
  },
  documentTypePage: {
    header: 'Здравейте, ще направим запис, за да потвърдим самоличността ви.',
    description: 'Изберете документа, с който ще се идентифицирате',
    proceed: 'Продължи',
    selectCountry: 'Изберете държавата, която е издала документа',
    chooseDocument: 'Изберете типа на документа',
    ResidentPermit: 'Разрешение за пребиваване',
    EUResidentPermit: 'Разрешение за пребиваване в ЕС',
    TravelTitle: 'Заглавие на пътуването',
    DriverLicense: 'Шофьорска книжка',
    Passport: 'Паспорт',
    IdentityCard: 'Карта за самоличност',
    inputPlaceholder: 'Изберете',
  },
  generalConditionsPage: {
    header: 'Общи условия',
    termsAndConditions:
      'Приемам правилата и условията за ползване на услугата.*',
    privacyPolicy: 'Приемам политиката за поверителност.*',
    required: '* необходими условия',
    proceed: 'Приемане на',
    conditionTermsHeading: 'Правила и условия за използване',
    conditionPrivacyHeading: 'Политика за поверителност',
  },
  signDocumentPage: {
    header: 'Подписване на документ',
    checkboxLabel:
      'Потвърждавам, че съм прочел/а и разбирам условията на това споразумение, както е описано по-горе.',
    proceed: 'Подписване на документ',
  },
  videoOnboardingPage: {
    header: 'От какво се нуждаете?',
    wellLitPlace: 'Добре осветено място.',
    originalDocument: 'Вашият оригинален документ, валиден и без корици.',
    wifi: 'Wi-Fi връзка или максимално 4G покритие.',
    start: 'Продължи',
  },
  documentType: {
    selectCountry: 'Изберете държавата, която е издала документа',
    ResidentPermit: 'Разрешение за пребиваване',
    EUResidentPermit: 'Разрешение за пребиваване в ЕС',
    TravelTitle: 'Заглавие на пътуването',
    IdentityCard: 'Карта за самоличност',
  },
  otpPage: {
    verify: 'Проверка на',
    header: 'Въведете кода за потвърждение, изпратен на телефона ви',
    headerVerifying: 'Моля, изчакайте вашата проверка.',
  },
  userDataPage: {
    header: 'Моля, посочете информация за контакт',
    proceed: 'Потвърдете',
    email: 'Електронна поща:',
    phone: 'Телефон:',
  },
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: 'Проверка на самоличността',
    firstParagraph:
      'Добре дошли в процеса на проверка на самоличността на Signicat. Signicat, заедно с дъщерното ни дружество ElectronicID, е сертифициран от ЕС доставчик на доверени услуги [https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42] и ще извършва вашата идентификация и електронно подписване за вашето включване в системата с',
    firstParagraphPartOne:
      'Добре дошли в процеса на проверка на самоличността на Signicat. Signicat, заедно с нашето дъщерно дружество ElectronicID, е сертифицирана от ЕС надеждна ',
    serviceProvider: 'доставчик на услуги',
    firstParagraphPartTwo:
      ' и ще извърши вашата идентификация и електронно подписване за вашето присъединяване.',
    secondParagraph:
      'Процесът на идентификация ще се извърши изцяло цифрово и без да е необходимо да разговаряте с агент. За да завършим процеса, ще ни трябват:',
    thirdParagraph:
      'Достъп до онлайн банкирането ви? Да, правилно. Като допълнителна мярка за сигурност, ние сме задължени от регулаторния орган да инициираме превод на 0,01 EUR от вашата банкова сметка и да проверим дали името в сметката отговаря на вашата самоличност.\\n',
    fourthParagraph:
      'Готови ли сте да тръгнете? Чудесно, моля, приемете нашата политика за поверителност, за да можем да започнем.',
    consent:
      'Съгласен съм личните ми данни да бъдат използвани за идентифициране на самоличността ми чрез услугите за проверка на Signicat Open Banking.*',
    required: '* необходимо условие',
    proceed: 'Потвърдете',
    whatYouNeed: 'От какво се нуждаете?',
    wellLitPlace: 'Добро осветление',
    originalDocument: 'Националната ви лична карта или паспорт',
    stableConnection: 'Стабилни интернет връзки',
    onlineBanking: 'Достъп до вашето онлайн банкиране',
    cameraAccess: 'Достъп до камерата на вашето устройство',
  },
  progressPage: {
    heading: 'Проверка на самоличността',
    wait: 'Сега проверяваме самоличността ви. Обикновено това отнема по-малко от 3 минути.',
    waitMore:
      'Благодарим ви за търпението. Почти е готово. Моля, не затваряйте браузъра.',
  },
  jitPayWelcomePage: {
    heading: 'Добре дошли в процеса на проверка на самоличността на Signicat',
    descriptionPartOne:
      'Signicat, заедно с нашето дъщерно дружество ElectronicID, е сертифициран от ЕС доверен ',
    serviceProvider: 'доставчик на услуги',
    descriptionPartTwo:
      ' и ще извърши вашата идентификация и електронно подписване за вашето присъединяване.',
    idData:
      'Съгласен съм личните ми данни да бъдат използвани за идентифициране с услугите на Signicat Open Banking Verification.',
    termsAndConditions:
      'Приемам правилата и условията за ползване на услугата.',
    privacyPolicy: 'Приемам политиката за поверителност.',
    proceed: 'Потвърдете',
    stepOne: 'Подписване',
    stepTwo: 'Проверка на самоличността',
    stepThree: 'Прехвърляне на 1 цент',
    stepThreeDesc: 'Изисква се от регулатора',
  },
  jitPayOnboardingPage: {
    heading: 'За да завършите процеса, следвайте следните три стъпки:',
    signingHeader: '1. Подписване на',
    signingContent: 'Прегледайте и подпишете договора.',
    idVerifyHeader: '2. Проверка на самоличността',
    idVerifyContent:
      'Процесът на идентификация ще се извърши изцяло цифрово и без да е необходимо да разговаряте с агент.',
    micropaymentHeader: '3. Микроплащания',
    micropaymentContent: 'Превод на 0,01 EUR от личната ви банкова сметка.',
    proceed: 'Потвърдете',
  },
  jitPayVideoOnboardingPage: {
    header: 'Какво ви е необходимо за процеса VideoID:',
    lightHeading: 'Добро осветление',
    lightContent: 'Уверете се, че се намирате в добре осветена среда.',
    netHeading: 'Стабилна интернет връзка',
    netContent: 'Уверете се, че имате силна интернет връзка.',
    deviceHeading: 'Достъп до вашето устройство с камера',
    deviceContent: 'Разрешаване на достъпа до устройството с камера.',
    oneCameraHeading: 'Използване само на една камера',
    oneCameraContent:
      'Уверете се, че само едно устройство с камера е включено към настолното ви устройство. Ако използвате лаптоп, вградената камера е правилната за използване.',
    proceed: 'Продължи',
  },
  micropaymentInfoJitpayPage: {
    header: 'Микроплащане от личната ви банкова сметка',
    content:
      'Като допълнителна мярка за сигурност регулаторният орган ни задължава да инициираме превод на 0,01 EUR от личната ви банкова сметка и да проверим дали името в сметката отговаря на самоличността ви.',
    warning: 'Моля, използвайте личната си банкова сметка',
    proceed: 'Потвърдете',
    idData:
      'Съгласен съм личните ми данни да бъдат използвани за идентифициране с услугите на Signicat Open Banking Verification.',
  },
  micropaymentInfoIkanoPage: {
    content:
      'Като допълнителна мярка за сигурност регулаторният орган ни задължава да инициираме превод на 0,01 EUR от личната ви банкова сметка и да проверим дали името в сметката отговаря на самоличността ви.',
    tink: 'Signicat will perform the micropayment with their licensed partner - Tink GmbH.',
    proceed: 'Confirm',
  },
  ikanoPolicies: {
    privacy:
      'I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions',
    terms:
      'I accept the terms and conditions of the video-identification process and the issuance of short- term certificates',
  },
  ikanoWelcomePage: {
    heading: 'Добре дошли в процеса на проверка на самоличността на Signicat',
    descriptionPartOne:
      'Signicat, заедно с нашето дъщерно дружество ElectronicID, е сертифициран от ЕС доверен ',
    serviceProvider: 'доставчик на услуги',
    descriptionPartTwo:
      ' и ще извърши вашата идентификация и електронно подписване за вашето присъединяване.',
    idData:
      'Съгласен съм личните ми данни да бъдат използвани за идентифициране с услугите на Signicat Open Banking Verification.',
    termsAndConditions:
      'Приемам правилата и условията за ползване на услугата.',
    privacyPolicy: 'Приемам политиката за поверителност.',
    proceed: 'Потвърдете',
    stepOne: 'Подписване',
    stepTwo: 'Проверка на самоличността',
    stepThree: 'Прехвърляне на 1 цент',
    stepThreeDesc: 'Изисква се от регулатора',
  },
  ikanoCompletePage: {
    submit: 'Continue',
    message: 'The micropayment was successful',
    message1_1: 'Click on "Continue" and do',
    message1_2: 'not',
    message1_3: 'close the next window.',
    message2: 'We will carry out a final check of your details.',
    message3: 'This may take several minutes.',
  },
  signDocumentIkanoPage: {
    header: 'Sign a document',
    checkboxLabel:
      'I acknowledge that i have read and understand the terms of this agreement as detailed above.',
    proceed: 'Sign a document',
  },
  introKontomatikPage: {
    header: 'Проверка на адреса',
    intro:
      'В следващата стъпка ще проверим адреса ви. Ще ви помолим да изберете банката си от списъка и да влезете в личния си акаунт.',
    accountInformation: 'Ще получим следната информация за акаунта:',
    names: 'Име и фамилия - на собственика(ците) на сметката',
    address: 'Адрес - на собственика(ците) на партидата',
    ownership: 'Собственост на акаунта - собственик, съсобственик и т.н.',
    verificationPurposes:
      'От горните данни ще използваме <b>само вашия адрес</b> за целите на проверката.',
    proceed: 'Продължи',
  },
  addressUploadPageKontomatik: {
    verifying: 'Проверка на...',
    header: 'Качване на адрес',
    description:
      'Не успяхме да извлечем адреса от информацията за банковата ви сметка.',
    confirmation:
      'За да потвърдите адреса си, ще ви помолим да качите банковите извлечения, които съдържат адреса ви.',
    bankStatementContains:
      'Уверете се, че банковото ви извлечение съдържа адреса ви.',
    yourAddress: ' вашия адрес',
    proceed: 'Продължи',
    dragAndDrop: 'Влачете и пуснете или ',
    chooseFile: 'изберете файл',
    pko: {
      header: 'PKO BP Банково извлечение',
      confirmation:
        'За да потвърдите адреса си, ще ви помолим да качите банковите извлечения, които съдържат адреса ви.',
      whereToFindStatements: 'Къде мога да намеря банкови извлечения в PKO BP?',
      selectPDF: 'Please select a Bank Statement in PDF Format',
      tutorial: {
        desktop: {
          version: 'Desktop',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl',
          step2: '2. At the top menu select “My products”',
          step3:
            '3. Click the “Details” button, right below the personal account balance',
          step4:
            '4. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step5:
            '5. In the opened statements window click “download” at the latest bank statement',
        },
        mobile: {
          version: 'Mobile',
          step1:
            '1. Log in to your personal PKO BP bank account at www.pkobp.pl. Click on the padlock icon at the top menu',
          step2:
            '2. Select the personal iPKO service by clicking on “log in” button',
          step3:
            '3. Provide your customer number or login and click on “Continue” button',
          step4: '4. At the bottom menu select “My products”',
          step5:
            '5. Click the “Details” button, right below the personal account balance',
          step6:
            '6. In the account details scroll down and search for the Bank correspondence section and click the “Download statements”',
          step7:
            '7. In the opened statements window click “download” at the latest bank statement',
        },
      },
    },
  },
  pixelSpeak: {
    verifying: 'Verifying...',
    header: 'Address upload',
    description:
      'Please, upload one of the following documents to confirm your address.',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
    dragAndDrop: 'Drag & Drop or ',
    dropHere: 'Drop here',
    chooseFile: 'choose file',
    filetypes: ' [PDF, JPG or PNG]',
    maxFilesize: 'Max. file size: ',
    maxFilesizeValue: '5MB',
    fileErrorSize: 'The file is too big. Please only upload files up to 5 MB.',
    fileErrorType:
      'The file is in the wrong format. Please only upload PDF, PNG or JPG.',
    complete: 'Address verification completed',
  },
  addressVerificationIkano: {
    header: 'Address verification',
    description:
      'Please upload one of the following documents to confirm your address',
    documentsList: [
      // 'Offizielle Meldebescheinigung (max. 1 Jahr alt)',
      // 'Gehaltsabrechnung (max. 3 Monate alt)',
      'Gas- / Strom- / Wärme- oder Wasserabrechnung (max. 12 Monate alt)',
      'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung (max. 3 Monate alt)',
    ],
    documentTypes: [
      // {
      //   value: 'registration_certificate',
      //   label: 'Offizielle Meldebescheinigung',
      // },
      // {
      //   value: 'proof_of_salary',
      //   label: 'Gehaltsabrechnung',
      // },
      {
        value: 'energy_bill',
        label: 'Gas- / Strom- / Wärme- oder Wasserabrechnung',
      },
      {
        value: 'telecom_bill',
        label: 'Telefon- / Internet- / Kabel-TV- oder Mobilfunkrechnung',
      },
    ],
    proceed: 'Continue',
  },
  addressVerificationTwoSidedIkano: {
    header: 'Address verification',
    descriptionPartOne:
      'To verify your address we will ask you to upload a photo of one of the following documents: ',
    documentsList: [
      'Niederlassungserlaubnis',
      'Daueraufenthalt-EU',
      'Daueraufenthaltskarte (EU Familienangehöriger)',
      'Artikel 50 EUV, if on the back of the card the expression “Daueraufenthalt” is given',
      'All other residence permits, which contains one of the expressions “Daueraufenthalt” oder “unbefristet”',
    ],
    descriptionPartTwo:
      'Firstly, you will upload the front side, and next, you will upload the back side.',
    proceed: 'Continue',
  },
  AddressUploadFrontSideIkano: {
    verifying: 'Verifying...',
    header: 'Front side of the document',
    description:
      'Upload the photo of the front side of your document, please. ',
    descriptionConfirm: 'Is the front easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
  AddressUploadBackSideIkano: {
    verifying: 'Verifying...',
    header: 'Back side of the document',
    description: 'Upload the photo of the back side of your document, please. ',
    descriptionConfirm: 'Is the back easy to read?',
    takePhoto: 'Take photo',
    retakePhoto: 'Retake',
    proceed: 'Continue',
    complete: 'Address verification completed',
    errorMessage: 'Something went wrong. Please try again.',
    errorRetry: 'Retry',
  },
}
